import { Skeleton } from "@nextui-org/react";
import {
  GlobalVisitsIcon,
  GlobalFriendsIcon,
  GlobalReactionsIcon,
} from "../../assets/icons/GlobalDataIcons";
import { Global } from "../../interfaces/SingleUser";
export default function ProfileBigGlobal({
  globalData,
  isLoadingGlobal,
}: {
  globalData: Global;
  isLoadingGlobal: boolean;
}) {
  return (
    <ul className="p-4 p py-6 gap-y-4 flex flex-col gap-2 border-2 border-color1/20 rounded-lg">
      <li className="flex items-center gap-3">
        <div>
          <GlobalVisitsIcon className="size-8" />
        </div>
        <div className="flex gap-2">
          {isLoadingGlobal ? (
            <Skeleton className="w-24 h-4 rounded-full" />
          ) : (
            <>
              <label className="font-medium text-[16px] text-color5">
                {globalData.visits}
              </label>
              <label className="font-semibold text-[16px] text-color7">
                Visitas al Perfil
              </label>
            </>
          )}
        </div>
      </li>
      <li className="flex items-center gap-3">
        <div>
          <GlobalFriendsIcon className="size-8" />
        </div>
        <div className="flex gap-2 ">
          {isLoadingGlobal ? (
            <Skeleton className="w-24 h-4 rounded-full" />
          ) : (
            <>
              <label className="font-medium text-[16px] text-color5">
                {globalData.friends}
              </label>
              <label className="font-semibold text-[16px] text-color7">
                Amigos
              </label>
            </>
          )}
        </div>
      </li>
      <li className="flex items-center gap-3">
        <div>
          <GlobalReactionsIcon className="size-8" />
        </div>
        <div className="flex gap-2">
          {isLoadingGlobal ? (
            <Skeleton className="w-24 h-4 rounded-full" />
          ) : (
            <>
              <label className="font-medium text-[16px] text-color5">
                {globalData.reactions}
              </label>
              <label className="font-semibold text-[16px] text-color7">
                Me gusta
              </label>
            </>
          )}
        </div>
      </li>
    </ul>
  );
}
