import { Card, Skeleton } from "@nextui-org/react";
import { Post } from "../../interfaces/Post";

export default function ProfileFeaturedContent({
  isLoadingPosts,
  posts,
}: {
  isLoadingPosts: boolean;
  posts: Post[];
}) {
  return (
    <div className="mt-2 flex flex-col items-center">
      <div className="flex scroll-smooth py-3 justify-start ">
        <div className="mt-4 px-2 md:px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8">
          {posts.map((item, i) => (
            <div
              key={i}
              className="w-[230px] md:w-[276px] h-[273px] object-cover rounded-xl bg-slate-100"
            >
              <img
                className="w-[230px] md:w-[276px] h-[273px] object-cover rounded-xl"
                src={
                  item.content.startsWith("http")
                    ? item.content
                    : import.meta.env.VITE_BASE_URL + item.content
                }
              />
            </div>
          ))}
          {isLoadingPosts &&
            [0, 0, 0, 0].map((_, i) => (
              <Card
                key={i}
                className="w-[230px] md:w-[276px] h-[273px] space-y-5 p-4 rounded-xl shadow-none border-1 border-gray-200"
              >
                <Skeleton className="rounded-lg">
                  <div className="h-32 rounded-lg bg-default-300"></div>
                </Skeleton>
                <div className="space-y-3">
                  <Skeleton className="w-4/5 rounded-lg">
                    <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                  </Skeleton>
                  <Skeleton className="w-2/5 rounded-lg">
                    <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                  </Skeleton>
                </div>
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
}
