import { useLocation } from 'react-router-dom';
import { Message } from '../../interfaces/Chat';
import { useAuthStore } from '../../stores/authStore';
import { useChatStore } from '../../stores/chatStore';
import { socket } from '../../utils/socket';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

export default function Events() {
	const setIsConnected = useState(socket.connected)[1];
	const authData = useAuthStore((state) => state.user);
	const setNewMessages = useChatStore((state) => state.setNewMessages);
	const setHasUnreadMessages = useChatStore((state) => state.setHasUnreadMessages);
	const location = useLocation();
	const setPendingMessages = useState<number>(0)[1];

	const handleUnreadMessages = useCallback(
		(message: Message[]) => {
			setPendingMessages((prevPendingMessages) => {
				const newPendingMessages = prevPendingMessages + message.length;
				toast.dismiss();
				toast.info(`Tienes ${newPendingMessages} mensajes sin leer!`, { autoClose: 5000 });
				setHasUnreadMessages(newPendingMessages);
				return newPendingMessages;
			});
		},
		[setHasUnreadMessages, setPendingMessages]
	);

	useEffect(() => {
		function onConnect() {
			setIsConnected(true);
		}

		function onDisconnect() {
			setIsConnected(false);
		}

		function onMessage(message: Message[]) {
			if (location.pathname.includes('/chat')) {
				setNewMessages(message);
			} else {
				handleUnreadMessages(message);
			}
		}

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('message', onMessage);

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('message', onMessage);
		};
	}, [location.pathname, handleUnreadMessages, setNewMessages, setIsConnected]);

	useEffect(() => {
		if (authData?.id) {
			socket.emit('register', authData.id);
		} else {
			console.warn('No se pudo registrar el usuario en el socket: ID no disponible');
		}
	}, [authData]);

	return null;
}
