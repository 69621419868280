import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Modal from "react-modal";
import { Card, Pagination, Skeleton, Button, Input, Slider, SliderValue, Checkbox } from "@nextui-org/react";
import {
	races,
	complexions,
	eyesColor,
	hairColors,
	noseTypes,
	skincolors,
	piercingCount,
	tattoosCount,
	drink,
	smoke,
	hairCuts,
	lipsOpt,
} from "../utils/selectOptions";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { getUsers } from "../api/user";
import { User } from "../interfaces/Login";
import { Pagination as PaginationData } from "../interfaces/pagination";
import { FilterIcon } from "../assets/icons/FilterIcon";
import { Province } from "../interfaces/Provice";
import { Fantasy } from "../interfaces/Fantasy";
import {
	getFantasies,
	getHoroscopes,
	getLangueages,
	getProfesions,
	getProvinces,
	getSexualOrientations,
} from "../api/formSelectsData";
import { Country } from "../interfaces/Country";
import { getCountries } from "../api/nationality";
import { useAuthStore } from "../stores/authStore";
import { useQuery } from "../hooks/useQuery";
import { Lang } from "../interfaces/Lang";
import { Profesion } from "../interfaces/Profesion";
import { Horoscope } from "../interfaces/Horoscope";
import { withErrorHandler } from "../utils/withErrorFallback";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import ProfileSmallCard from "../components/profile/ProfileSmallCard";
import Select from "react-select";
import { SingleUser } from "../interfaces/SingleUser";
import customStyles from "../hooks/customStyles";

const initialFilters = {
	fantasias_id: new Set<string>([]),
	provincias_id: new Set<string>([]),
	complexions_id: new Set<string>([]),
	races_id: new Set<string>([]),
	eye_colors_id: new Set<string>([]),
	skin_colors_id: new Set<string>([]),
	hair_colors_id: new Set<string>([]),
	nacionalidades_id: new Set<string>([]),
	hair_length: new Set<string>([]),
	lips: new Set<string>([]),
	piercings: new Set<string>([]),
	tattoos: new Set<string>([]),
	you_drink: new Set<string>([]),
	you_smoke: new Set<string>([]),
	children: new Set<string>([]),
	type_of_nose: new Set<string>([]),
	profesion_id: new Set<string>([]),
	idiomas_id: new Set<string>([]),
	horoscopo_id: new Set<string>([]),
	sex: new Set<string>([]),
	sexualOrientation: new Set<string>([]),
};
const childs = [
	{ id: 1, name: "Se lo reserva" },
	{
		id: 2,
		name: "Si",
	},
	{
		id: 3,
		name: "No",
	},
];
const sexTypes = [
	{ id: "f", name: "Mujer" },
	{
		id: "m",
		name: "Hombre",
	},
	{
		id: "c",
		name: "Pareja",
	},
];
export default function Profiles() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
		document.body.style.overflow = "hidden";
	};

	const closeModal = () => {
		setIsModalOpen(false);
		document.body.style.overflow = "";
	};

	const [search, setSearch] = useState("");
	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const data = e.target as HTMLInputElement;
		setSearch(data.value);
	};
	const authData = useAuthStore((state) => state.user);
	const [orientations, setOrientations] = useState<SexualOrientation[]>([]);

	const [isLoading, setIsLoading] = useState(true);
	const [users, setUsers] = useState<User[]>([]);
	const query = useQuery();

	const [filters, setFilters] = useState(initialFilters);
	// Estado temporal para los “filtros avanzados” dentro del modal
	const [tempAdvancedFilters, setTempAdvancedFilters] = useState(initialFilters);
	const [tempByPremium, setTempByPremium] = useState(false);

	const [pagination, setPagination] = useState<PaginationData>({
		current_page: 0,
		last_page: 0,
		total: 0,
		per_page: 1,
	});
	const [byPremium, setByPremium] = useState(false);
	const [onlyOnline, setOnlyOnline] = useState(false);
	const [onlyLocal, setOnlyLocal] = useState(false);
	const [selectsData, setSelectsData] = useState({
		provinces: [] as Province[],
		fantasies: [] as Fantasy[],
		nationalities: [] as Country[],
		languages: [] as Lang[],
		profesions: [] as Profesion[],
		horoscope: [] as Horoscope[],
	});
	const [ageRange, setAgeRange] = useState<SliderValue>([18, 95]);
	const isArray = (value: SliderValue): value is number[] => Array.isArray(value);

	const joinSet = (set: Set<string>) => {
		return Array.from(set).length > 0 ? Array.from(set).join(",") : undefined;
	};

	const getData = withErrorHandler(async (page: number) => {
		setIsLoading(true);

		try {
			const newSex = filters.sex;
			const couple = filters.sex.has("c");
			newSex.delete("c");

			// Calculamos valores finales de edad:
			let finalAgeFrom = undefined;
			let finalAgeTo = undefined;

			if (isArray(ageRange)) {
				if (ageRange[0] !== 18) {
					finalAgeFrom = ageRange[0];
				}
				if (ageRange[1] !== 95) {
					finalAgeTo = ageRange[1];
				}
			}
			const filtersData = {
				provincias_id: joinSet(filters.provincias_id),
				fantasias_id: joinSet(filters.fantasias_id),
				complexions_id: joinSet(filters.complexions_id),
				races_id: joinSet(filters.races_id),
				eye_colors_id: joinSet(filters.eye_colors_id),
				skin_colors_id: joinSet(filters.skin_colors_id),
				hair_colors_id: joinSet(filters.hair_colors_id),
				nacionalidades_id: joinSet(filters.nacionalidades_id),
				hair_length: joinSet(filters.hair_length),
				lips: joinSet(filters.lips),
				piercings: joinSet(filters.piercings),
				tattoos: joinSet(filters.tattoos),
				you_drink: joinSet(filters.you_drink),
				you_smoke: joinSet(filters.you_smoke),
				children: joinSet(filters.children),
				type_of_nose: joinSet(filters.type_of_nose),
				profesion_id: joinSet(filters.profesion_id),
				idiomas_id: joinSet(filters.idiomas_id),
				horoscopo_id: joinSet(filters.horoscopo_id),
				sex: joinSet(newSex),
				couple: couple ? 1 : undefined,
				sexualOrientation: joinSet(filters.sexualOrientation),
				name: search ?? "",
				email: search ?? "",
				subscription_payed: byPremium ? 1 : undefined,
				edad_to: finalAgeTo,
				edad_from: finalAgeFrom,
				status: onlyOnline ? "connected" : undefined,
				role_id: onlyLocal ? 3 : undefined,
				limit: 10,
			};
			const res = await getUsers(page, filtersData);
			console.log("FILTERS DATA", filtersData);

			const filteredUsers = res.info.data[0].data.filter((user: SingleUser) => {
				const isLocal = user.role_id === 3; // Filtra por role_id en lugar de perfil_type
				if (onlyLocal && !isLocal) return false;
				return true;
			});

			setUsers(filteredUsers);

			setPagination({
				current_page: res?.info?.data[0].current_page,
				last_page: res?.info?.data[0].last_page,
				total: res?.info?.data[0].total,
				per_page: res?.info?.data[0].per_page,
			});
		} catch (error) {
			console.error(error); // Maneja errores aquí
		} finally {
			setIsLoading(false);
		}
	});

	useEffect(() => {
		if (query.get("online")) {
			setOnlyOnline(true);
		}
		//if (authData) getData(1);
		const getSelectsData = withErrorHandler(async () => {
			const sexualOrientations = await getSexualOrientations();
			setOrientations(sexualOrientations?.info?.data[0]);
			const fantasies = await getFantasies();
			const provinces = await getProvinces();
			const countries = await getCountries();
			const languages = await getLangueages();
			const profesions = await getProfesions();
			const horoscope = await getHoroscopes();

			setSelectsData({
				...selectsData,
				fantasies: fantasies.info.data[0],
				provinces: provinces.info.data[0],
				nationalities: countries,
				languages: languages.info.data[0],
				profesions: profesions.info.data[0],
				horoscope: horoscope.info.data[0],
			});
		});
		getSelectsData();
	}, []);

	const onPageChange = async (page: number) => {
		getData(page);
	};

	const handleChangeSelect = (val: unknown, name: string) => {
		const value = val as { label: string; value: string }[];
		setFilters({
			...filters,
			[name]: new Set(value.map((e) => e.value.toString())),
		});
	};

	const handleChangeSelectModal = (val: unknown, name: string) => {
		const value = val as { label: string; value: string }[];
		const newSet = new Set(value.map((item) => item.value.toString()));

		// Actualiza SOLO el estado temporal
		setTempAdvancedFilters((prev) => ({
			...prev,
			[name]: newSet,
		}));
	};

	// Hook para llamar a getData con debounce
	useEffect(() => {
		const handler = setTimeout(() => {
			getData(1);
		}, 600);

		return () => clearTimeout(handler);
	}, [filters, byPremium, onlyOnline, onlyLocal]);

	useEffect(() => {
		setFilters((prevFilters) => ({
			...prevFilters,
			edad_from: isArray(ageRange) ? ageRange[0] : 18,
			edad_to: isArray(ageRange) ? ageRange[1] : 95,
		}));
	}, [ageRange]);

	const handleReset = () => {
		setFilters(initialFilters);
		setByPremium(false);
		setAgeRange([18, 95]);
		setSearch("");
		setOnlyOnline(false);
	};
	// 2 - hetero, 3 - bi, 4 - homo
	const sexs = useMemo(() => {
		if (authData?.role_id == "3") return sexTypes;
		const isCouple = authData?.info?.is_couple;
		const sex = authData?.info?.detalles.sex.value;
		const orientation = authData?.info?.detalles.sexualOrentation.value;
		if (isCouple) {
			const partnerSex = authData?.info?.detalles.partner_sex?.value;
			const partnerOrientation = authData?.info?.detalles?.partner_sexualOrentation?.value;

			const noBi = orientation != "3" || partnerOrientation != "3";
			const noBiSex = orientation != "3" ? sex : partnerSex;

			if (sex == partnerSex) {
				// they are homo or bi
				if (orientation != "3" && partnerOrientation != "3")
					//they are homo
					return sexTypes.filter((s) => s.id == sex || s.id == "c");
				else if (noBi) {
					// one of them is homo and the other is bi
					return sexTypes.filter((s) => s.id == noBiSex || s.id == "c");
				}
				// in other case both are bi
			} else {
				if (orientation != "3" && partnerOrientation != "3")
					//they are hetero
					return sexTypes.filter((s) => s.id == "c");
				else if (noBi) {
					// one of them is hetero and the other is bi
					return sexTypes.filter((s) => s.id != noBiSex);
				}
				// in other case both are bi
			}
		} else {
			if (orientation == "2" && sex) return sexTypes.filter((s) => s.id != sex); //hetero
			else if (orientation == "4" && sex) return sexTypes.filter((s) => s.id == sex || s.id == "c"); //homo
		}
		return sexTypes; // bi
	}, [authData]);

	// const orientationOpt = useMemo(() => {
	// 	if (orientations.length == 0) return [];
	// 	console.log(orientations);
	// 	if (authData?.role_id == "3") return orientations;

	// 	const isCouple = authData?.info?.is_couple;
	// 	const sex = authData?.info?.detalles.sex.value;
	// 	const orientation = authData?.info?.detalles.sexualOrentation.value;
	// 	if (isCouple) {
	// 		const partnerSex = authData?.info?.detalles.partner_sex?.value;
	// 		const partnerOrientation = authData?.info?.detalles?.partner_sexualOrentation?.value;

	// 		const noBi = orientation != "3" || partnerOrientation != "3";
	// 		// const noBiSex = orientation != "3" ? sex : partnerSex;

	// 		if (sex == partnerSex) {
	// 			// they are homo or bi
	// 			if (orientation != "3" && partnerOrientation != "3")
	// 				//they are homo
	// 				return orientations.filter((o) => o.id != 2);
	// 			else if (noBi) {
	// 				// one of them is homo and the other is bi
	// 				return orientations.filter((o) => o.id != 2);
	// 			}
	// 			// in other case both are bi
	// 		} else {
	// 			if (orientation != "3" && partnerOrientation != "3")
	// 				//they are hetero
	// 				return orientations.filter((o) => o.id != 4);
	// 			else if (noBi) {
	// 				// one of them is hetero and the other is bi
	// 				return orientations.filter((o) => o.id != 4);
	// 			}
	// 			// in other case both are bi
	// 		}
	// 	} else {
	// 		if (orientation == "2")
	// 			//hetero
	// 			return orientations.filter((o) => o.id != 4);
	// 		else if (orientation == "4")
	// 			// homo
	// 			return orientations.filter((o) => o.id != 2);
	// 	}
	// 	return orientations; // bi
	// }, [authData, orientations]);

	return (
		<>
			<div className="h-screen">
				<NavBar />
				<main className="flex justify-center">
					<div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col md:px-6">
						<div className="px-4 text-2xl w-full max-w-8xl mb-4">
							<section className="w-full mb-4">
								<div className="mt-2 flex flex-col items-start">
									<h1 className="font-semibold">Buscar perfiles</h1>
								</div>
							</section>
						</div>
						<div className="flex w-full h-full">
							<div className="flex-grow flex flex-col justify-between w-full">
								<div className="w-full  pl-4">
									<section className="w-full flex">
										<div className="w-full flex flex-col">
											<div className="w-full mb-2 flex justify-between">
												<form
													onSubmit={(e: FormEvent) => {
														e.preventDefault();
														getData(1);
													}}
													className="flex w-full gap-4 max-w-4xl flex-wrap md:flex-nowrap"
												>
													<Input
														className="min-w-sm text-input w-full "
														id="search"
														type="text"
														variant="bordered"
														label=""
														startContent={<SearchIcon />}
														placeholder="Buscar..."
														required
														value={search}
														onChange={handleChange}
														classNames={{ inputWrapper: " border-1 rounded-lg" }}
													/>
													<Button
														color="primary"
														variant="solid"
														className="rounded-lg max-w-sm"
														type="submit"
													>
														Buscar
													</Button>
													<Button
														color="default"
														variant="bordered"
														onClick={handleReset}
														className="rounded-lg border-1 p-4"
													>
														Limpiar Filtros
													</Button>
												</form>
												{/* Modal por Filtro*/}

												<div>
													{/* Botón para abrir el modal */}
													<button
														onClick={openModal}
														className="ml-2 bg-color8 border border-primary/60 rounded-lg px-4 py-2 flex items-center gap-2"
													>
														<FilterIcon width={22} stroke={"#A24BFD"} />
														Filtros avanzados
													</button>

													{/* Modal */}
													<Modal
														isOpen={isModalOpen}
														onRequestClose={closeModal}
														className="fixed inset-0 flex items-center justify-center p-4 z-[9999]"
														overlayClassName="fixed inset-0 bg-black/50 z-[9998]"
														ariaHideApp={false}
													>
														<div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-[90vh] overflow-y-auto">
															<div className="flex justify-between items-center mb-4">
																<h2 className="text-lg font-bold">Filtros avanzados</h2>
																<button
																	onClick={closeModal}
																	className="text-gray-500 hover:text-gray-800"
																>
																	✖️
																</button>
															</div>

															{/* Contenido de los filtros */}
															<div className="mt-2 flex flex-col gap-4 max-h-[60vh] overflow-auto pr-3">
																<label>Nacionalidad</label>
																<Select
																	isMulti
																	name="nacionalidades_id"
																	options={selectsData.nationalities?.map((x) => ({
																		label: x.name.common,
																		value: x.cca2,
																	}))}
																	defaultValue={selectsData.nationalities
																		.filter((x) =>
																			tempAdvancedFilters.nacionalidades_id.has(
																				x.cca2
																			)
																		)
																		.map((x) => ({
																			label: x.name.common,
																			value: x.cca2,
																		}))}
																	placeholder={
																		selectsData.nationalities?.length
																			? "Seleccione"
																			: "Cargando..."
																	}
																	className="mt-1"
																	classNamePrefix="select"
																	onChange={(val) =>
																		handleChangeSelectModal(
																			val,
																			"nacionalidades_id"
																		)
																	}
																	styles={customStyles}
																/>

																<label>Sexo</label>
																<Select
																	isMulti
																	name="sex"
																	options={sexs?.map((x) => ({
																		label: x.name,
																		value: x.id,
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={sexs
																		.filter((s) =>
																			Array.from(
																				tempAdvancedFilters.sex
																			).includes(s.id)
																		)
																		.map((x) => ({
																			label: x.name,
																			value: x.id,
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "sex")
																	}
																	styles={customStyles}
																/>

																<label>Idiomas</label>
																<Select
																	isMulti
																	name="idiomas_id"
																	options={selectsData.languages?.map((x) => ({
																		label: x.name, // Mantén el label como está
																		value: String(x.id), // Convertir el id a string
																	}))}
																	placeholder={
																		selectsData.languages?.length
																			? "Seleccione"
																			: "Cargando..."
																	}
																	className="mt-1"
																	classNamePrefix="select"
																	defaultValue={selectsData.languages
																		?.filter((lang) =>
																			tempAdvancedFilters.idiomas_id.has(
																				String(lang.id)
																			)
																		)
																		.map((lang) => ({
																			label: lang.name,
																			value: String(lang.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "idiomas_id")
																	}
																	styles={customStyles}
																/>

																<label>Horóscopo</label>
																<Select
																	isMulti
																	name="horoscopo_id"
																	options={selectsData.horoscope?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={
																		selectsData.horoscope?.length
																			? "Seleccione"
																			: "Cargando..."
																	}
																	className="mt-1"
																	classNamePrefix="select"
																	defaultValue={selectsData.horoscope
																		?.filter((it) =>
																			tempAdvancedFilters.horoscopo_id.has(
																				String(it.id)
																			)
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "horoscopo_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Profesión</label>
																<Select
																	isMulti
																	name="profesion_id"
																	options={selectsData.profesions?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={
																		selectsData.profesions?.length
																			? "Seleccione"
																			: "Cargando..."
																	}
																	defaultValue={selectsData.profesions
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.profesion_id
																			).includes(String(it.id))
																		)
																		.map((prof) => ({
																			label: prof.name,
																			value: String(prof.id),
																		}))}
																	className="mt-1"
																	classNamePrefix="select"
																	onChange={(val) =>
																		handleChangeSelectModal(val, "profesion_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Raza</label>
																<Select
																	isMulti
																	name="races_id"
																	options={races?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={races
																		?.filter((it) =>
																			Array.from(filters.races_id).includes(
																				it.id.toString()
																			)
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "races_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Color de piel</label>
																<Select
																	isMulti
																	name="skin_colors_id"
																	options={skincolors?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={skincolors
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.skin_colors_id
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "skin_colors_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Complexión</label>
																<Select
																	isMulti
																	name="complexions_id"
																	options={complexions?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={complexions
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.complexions_id
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "complexions_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Color de ojos</label>
																<Select
																	isMulti
																	name="eye_colors_id"
																	options={eyesColor?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={eyesColor
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.eye_colors_id
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "eye_colors_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Color de cabello</label>
																<Select
																	isMulti
																	name="hair_colors_id"
																	options={hairColors?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={hairColors
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.hair_colors_id
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "hair_colors_id")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Corte de cabello</label>
																<Select
																	isMulti
																	name="hair_length"
																	options={hairCuts?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={hairCuts
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.hair_length
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "hair_length")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Labios</label>
																<Select
																	isMulti
																	name="lips"
																	options={lipsOpt?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={lipsOpt
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.lips
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "lips")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<label>Tipo de nariz</label>
																<Select
																	isMulti
																	name="type_of_nose"
																	options={noseTypes?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={noseTypes
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.type_of_nose
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "type_of_nose")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Piercings</label>
																<Select
																	isMulti
																	name="piercings"
																	options={piercingCount?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={piercingCount
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.piercings
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "piercings")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Tatuajes</label>
																<Select
																	isMulti
																	name="tattoos"
																	options={tattoosCount?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={tattoosCount
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.tattoos
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "tattoos")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Bebe</label>
																<Select
																	isMulti
																	name="you_drink"
																	options={drink?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={drink
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.you_drink
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "you_drink")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Fuma</label>
																<Select
																	isMulti
																	name="you_smoke"
																	options={smoke?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={smoke
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.you_smoke
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "you_smoke")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>
																<label>Hijos</label>
																<Select
																	isMulti
																	name="children"
																	options={childs?.map((x) => ({
																		label: x.name,
																		value: String(x.id),
																	}))}
																	placeholder={"Seleccione"}
																	className=" mt-1"
																	classNamePrefix="select"
																	defaultValue={childs
																		?.filter((it) =>
																			Array.from(
																				tempAdvancedFilters.children
																			).includes(it.id.toString())
																		)
																		.map((x) => ({
																			label: x.name,
																			value: String(x.id),
																		}))}
																	onChange={(val) =>
																		handleChangeSelectModal(val, "children")
																	}
																	styles={{
																		...customStyles,
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}), // Asegura que esté encima del modal
																		menu: (base) => ({
																			...base,
																			maxHeight: "200px", // Restringe la altura máxima del menú desplegable
																			overflowY: "auto", // Agrega scroll dentro del menú si es necesario
																		}),
																	}}
																/>

																<Checkbox
																	isSelected={tempByPremium}
																	onValueChange={() =>
																		setTempByPremium(!tempByPremium)
																	}
																	classNames={{
																		wrapper:
																			"border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
																		base: "font-medium text-[14px] text-color2",
																	}}
																>
																	Premium
																</Checkbox>
															</div>

															{/* Botón de guardar o cerrar */}
															<div className="mt-6 flex justify-end gap-4">
																<button
																	onClick={closeModal}
																	className="px-4 py-2 rounded-lg bg-white hover:bg-gray-400"
																>
																	Cancelar
																</button>
																<Button
																	color="default"
																	variant="bordered"
																	onClick={handleReset}
																	className="rounded-lg border-1 p-4"
																>
																	Limpiar Filtros
																</Button>
																<button
																	onClick={() => {
																		// Copiamos los filtros TEMPORALES --> “filters”
																		setFilters(tempAdvancedFilters);

																		// Si “byPremium” también está en advanced:
																		setByPremium(tempByPremium);

																		// Cierra el modal
																		closeModal();
																	}}
																	className="px-4 py-2 rounded-lg bg-primary text-white hover:bg-primary-dark"
																>
																	Aplicar filtros
																</button>
															</div>
														</div>
													</Modal>
												</div>
											</div>

											{/* Nueva fila de botones */}
											<div className="flex flex-nowrap items-center gap-4 mt-4 relative">
												<Checkbox
													isSelected={onlyOnline}
													onValueChange={() => setOnlyOnline(!onlyOnline)}
													classNames={{
														wrapper:
															"border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
														base: "font-medium text-[14px] text-color2",
													}}
												>
													En línea
												</Checkbox>

												<Checkbox
													isSelected={onlyLocal}
													onValueChange={() => {
														setOnlyLocal(!onlyLocal);
													}}
													classNames={{
														wrapper:
															"border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
														base: "font-medium text-[14px] text-color2",
													}}
												>
													Locales
												</Checkbox>

												{/* Select "Orientación", con ancho mayor */}
												<Select
													isMulti
													name="sexualOrientation"
													options={orientations.map((x) => ({
														label: x.name,
														value: x.id,
													}))}
													placeholder="Orientación"
													className="w-56"
													classNamePrefix="select"
													defaultValue={orientations
														.filter((o) =>
															Array.from(filters.sexualOrientation).includes(
																o.id.toString()
															)
														)
														.map((x) => ({
															label: x.name,
															value: x.id,
														}))}
													onChange={(val) => handleChangeSelect(val, "sexualOrientation")}
													menuPortalTarget={document.body}
													styles={{
														menuPortal: (base) => ({ ...base, zIndex: 9999 }),
													}}
												/>

												{/* Select "Ciudad" */}
												<Select
													isMulti
													name="provincias_id"
													options={selectsData.provinces.map((x) => ({
														label: x.name,
														value: x.id,
													}))}
													placeholder="Ciudad"
													className="w-56"
													classNamePrefix="select"
													defaultValue={selectsData.provinces
														.filter((p) =>
															Array.from(filters.provincias_id).includes(p.id.toString())
														)
														.map((x) => ({
															label: x.name,
															value: x.id,
														}))}
													onChange={(val) => handleChangeSelect(val, "provincias_id")}
													menuPortalTarget={document.body}
													styles={{
														menuPortal: (base) => ({ ...base, zIndex: 9999 }),
													}}
												/>

												{/* Select "Fantasías" */}
												<Select
													isMulti
													name="fantasias_id"
													options={selectsData.fantasies.map((x) => ({
														label: x.name,
														value: x.id,
													}))}
													placeholder="Fantasías"
													className="w-60"
													classNamePrefix="select"
													defaultValue={selectsData.fantasies
														.filter((f) =>
															Array.from(filters.fantasias_id).includes(f.id.toString())
														)
														.map((x) => ({
															label: x.name,
															value: x.id,
														}))}
													onChange={(val) => handleChangeSelect(val, "fantasias_id")}
													menuPortalTarget={document.body}
													styles={{
														menuPortal: (base) => ({ ...base, zIndex: 9999 }),
													}}
												/>

												{/* Slider en la misma línea */}
												<div className="relative -space-y-3 ml-12">
													{/* Fila superior: Label a la izquierda, Min–Max a la derecha */}
													<div className="flex items-center justify-between mb-1 relative -top-2">
														<span className="font-semibold text-sm ">Edad</span>
														{/* Mostramos el rango (si es array: "min - max", si no: valor único) */}
														<span className="text-sm">
															{Array.isArray(ageRange)
																? `${ageRange[0]} - ${ageRange[1]}`
																: ageRange}
														</span>
													</div>
													<Slider
														step={1}
														maxValue={95}
														minValue={18}
														value={ageRange}
														onChange={(newValue) => setAgeRange(newValue)}
														className="w-64 h-6"
													/>
													{/* Tarjetas púrpura de los rangos */}
													{Array.isArray(ageRange) ? (
														<div className="absolute w-full flex justify-between items-center top-[45%] transform -translate-y-1/2 pointer-events-none">
															<span className="absolute bg-purple-600 text-white px-2 py-1 rounded shadow-md text-sm left-[-50px] top-[4px]">
																{ageRange[0]}
															</span>
															<span className="absolute bg-purple-600 text-white px-2 py-1 rounded shadow-md text-sm right-[-50px] top-[4px]">
																{ageRange[1]}
															</span>
														</div>
													) : (
														<div className="absolute w-full flex justify-between items-center top-[45%] transform -translate-y-1/2 pointer-events-none">
															<span className="absolute text-sm bg-purple-600 text-white rounded px-2 py-1 shadow-md left-[-50px] top-[-10px]">
																{ageRange}
															</span>
														</div>
													)}
												</div>
											</div>

											{/* Tarjetas y paginación */}
											<div className="w-full xl:overflow-auto xl:max-h-[calc(100vh-220px)] xl:pb-2 pt-4 pr-2">
												<div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
													{isLoading ? (
														// Mostrar el skeleton durante la carga
														[0, 0, 0, 0].map((_, i) => (
															<Card
																className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
																radius="lg"
																key={i}
															>
																<Skeleton className="rounded-lg">
																	<div className="h-24 rounded-lg bg-default-300"></div>
																</Skeleton>
																<div className="space-y-3">
																	<Skeleton className="w-3/5 rounded-lg">
																		<div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
																	</Skeleton>
																	<Skeleton className="w-4/5 rounded-lg">
																		<div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
																	</Skeleton>
																	<Skeleton className="w-2/5 rounded-lg">
																		<div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
																	</Skeleton>
																</div>
															</Card>
														))
													) : users.length > 0 ? (
														// Mostrar los usuarios cuando haya datos disponibles
														users
															.filter((u) => u.id !== authData?.id)
															.map((it) => (
																<ProfileSmallCard
																	key={it.id}
																	user={it}
																	orientations={orientations}
																	provinces={selectsData.provinces}
																/>
															))
													) : (
														// Mostrar el mensaje de que no hay usuarios solo si no está cargando
														<p>No hay usuarios para mostrar 😕</p>
													)}
												</div>
											</div>
										</div>
									</section>
								</div>
								{pagination && pagination?.current_page > 0 && (
									<div className="px-4 mt-3 mb-2 w-full">
										<div className="flex justify-center">
											<Pagination
												showControls
												total={pagination.last_page}
												page={pagination.current_page}
												initialPage={1}
												onChange={onPageChange}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		</>
	);
}
