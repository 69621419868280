import { Image } from "@nextui-org/react";
import { Fantasy } from "../../interfaces/Fantasy";
import { Province } from "../../interfaces/Provice";
import { infoDetals, useUserStore } from "../../stores/userStore";
import SecondaryButton from "../common/SecondaryButton";
import { useEffect, useMemo, useState } from "react";
import { Country } from "../../interfaces/Country";
import { getCountries } from "../../api/nationality";
import { Link } from "react-router-dom";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { LocationIcon } from "../../assets/icons/LockationIcon";
import { getLookingFor } from "../../api/formSelectsData";
import ProfileSection from "./ProfileSection";

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };

export default function AboutUser({
	provinces,
	fantasies,
	isLoading = false,
}: {
	provinces: Province[];
	fantasies: Fantasy[];
	isLoading?: boolean;
}) {
	const userData = useUserStore((state) => state.user);
	const [countries, setCountries] = useState<Country[]>([]);
	const [loading, setLoading] = useState(false);
	const [center, setCenter] = useState<{ lat: number; lng: number } | undefined>();
	const [lookingForOptions, setLookingForOptions] = useState<{ id: number; name: string }[]>([]);
	const [showMoreF, setShowMoreF] = useState(false);
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			if (roleId !== "3") {
				const countriesRes = await getCountries();
				setCountries(countriesRes);
			} else {
				try {
					const aux = userData?.info?.ubicacion?.split("|");
					if (aux && aux.length === 2) {
						const lat = parseFloat(aux[0]?.toString());
						const lng = parseFloat(aux[1]?.toString());
						setCenter({ lat, lng });
					} else setCenter(INITIAL_CENTER);
				} catch (error) {
					console.error(error);
				}
			}
			setLoading(false);
		};

		getData();
	}, []);

	useEffect(() => {
		const fetchLookingFor = async () => {
			const data = await getLookingFor();
			if (data && data.info?.data) {
				setLookingForOptions(data.info.data[0]);
			}
		};
		fetchLookingFor();
	}, []);

	const looking = (data: string | undefined) => {
		if (!data) return [];

		const ids = data.split(",").map((id) => parseInt(id.trim())); // Convierte los IDs a números
		const combinedOptions = [
			...(Array.isArray(userData?.info?.looking_id) ? userData.info.looking_id : []),
			...lookingForOptions,
		];

		// Encuentra los nombres correspondientes a los IDs y devuelve un arreglo
		return ids.map((id) => combinedOptions.find((option) => option.id === id)?.name).filter(Boolean); // Filtra valores undefined o null
	};

	const nationality = useMemo(() => {
		if (countries?.length && userData?.info?.detalles?.nacionalidad_id) {
			const aux = countries.find((e) => e.cca2 === userData?.info?.detalles?.nacionalidad_id);
			if (aux) return aux.name.common;
		}
		return "España";
	}, [countries]);

	const theyarelooking = looking(userData?.info?.looking_id?.toString());
	const roleId = userData?.role_id?.toString();

	return (
		<div className="mt-10 flex justify-between lg:px-4 w-full min-w-8xl flex-wrap">
			<div className="w-full px-2 lg:px-0">
				{roleId !== "3" ? (
					<div className="grid grid-cols-2 gap-3 lg:gap-0 lg:flex justify-between p-5 border-2 border-color1/20 px-10 rounded-lg ">
						<div className="flex flex-col gap-2">
							<span className="font-medium lg:text-[14px] text-color1 mr-2">Vive en</span>
							<label className="inline-flex items-center gap-2">
								<LocationIcon className="h-4" />
								{provinces.length && userData?.info?.provincia_id
									? provinces.find((e) => e.id === userData?.info?.provincia_id)?.name
									: "..."}
							</label>
						</div>
						<div className="flex flex-col gap-2">
							<span className="font-medium lg:text-[14px] text-color1 mr-2">Qué Busco</span>
							<div
								className="relative inline-block"
								onMouseEnter={() => setShowMore(true)}
								onMouseLeave={() => setShowMore(false)}
							>
								{theyarelooking.length > 0 ? (
									<>
										<span className="text-[14px] font-medium py-2 px-4 border border-gray-300 rounded-lg">
											{theyarelooking[0]}
										</span>
										{theyarelooking.length > 1 && (
											<span className="ml-2 py-1 px-2 border border-gray-300 bg-white text-black rounded-lg inline-block">
												+{theyarelooking.length - 1}
											</span>
										)}
										{showMore && theyarelooking.length > 1 && (
											<div className="absolute top-full mt-2 left-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10 p-2">
												{theyarelooking.slice(1).map((item, index) => (
													<div
														key={index}
														className="text-[14px] font-medium py-1 px-2 hover:bg-gray-100 rounded"
													>
														{item}
													</div>
												))}
											</div>
										)}
									</>
								) : (
									<span className="text-[14px] font-medium py-2 px-4 border border-gray-300 rounded-lg bg-white">
										Sin información
									</span>
								)}
							</div>
						</div>

						<div className="flex flex-col gap-2">
							<span className="font-medium lg:text-[14px] text-color1 mr-2">Nacionalidad</span>
							<div className="flex items-center gap-2">
								{loading ? (
									"Cargando..."
								) : (
									<>
										<Image
											radius="full"
											src={`https://flagcdn.com/${infoDetals()?.nacionalidad_id?.toLocaleLowerCase()}.svg`}
											fallbackSrc="https://flagcdn.com/es.svg"
											className="size-6 "
											loading="lazy"
											classNames={{
												wrapper: "bg-center bg-no-repeat bg-contain ",
											}}
										/>
										<label>{nationality}</label>
									</>
								)}
							</div>
						</div>
						<ProfileSection title="Fantasías Destacadas">
							{isLoading ? (
								"Cargando..."
							) : fantasies?.length ? (
								<div className="flex flex-col gap-4">
									{/* Botón individual para la primera fantasía */}
									<Link to={`/fantasy/${fantasies[0].id}`}>
										<SecondaryButton className="text-[14px] p-2 max-h-[36px]">
											{fantasies[0].name}
										</SecondaryButton>
									</Link>

									{/* Botón "+ cantidad" */}
									{fantasies.length > 1 && (
										<div className="relative">
											<button
												onClick={() => setShowMoreF(!showMoreF)}
												className="text-[14px] p-2 max-h-[36px] border border-gray-300 bg-white text-black rounded-lg"
											>
												+{fantasies.length - 1}
											</button>

											{/* Desplegable o lista debajo */}
											{showMoreF && (
												<div className="absolute bg-white border border-gray-300 rounded shadow-lg z-10 p-4">
													{/* Mostrar las primeras 3 fantasías */}
													{fantasies.slice(1, 4).map((x) => (
														<Link key={x.id} to={`/fantasy/${x.id}`}>
															<div className="py-2 px-4 hover:bg-gray-100 text-[14px] rounded">
																{x.name}
															</div>
														</Link>
													))}
													{/* Botón "Ver todas" */}
													{fantasies.length > 4 && (
														<Link to="/fantasies">
															<div className="py-2 px-4 mt-2 bg-gray-100 hover:bg-gray-200 text-[14px] text-center rounded">
																Ver todas
															</div>
														</Link>
													)}
												</div>
											)}
										</div>
									)}
								</div>
							) : (
								<p>Sin Fantasías</p>
							)}
						</ProfileSection>
					</div>
				) : (
					<>
						<div className="flex justify-between p-5 border-2 border-color1/20 px-10">
							<div className="flex flex-col gap-2">
								<span className="font-medium lg:text-[14px] text-color1 mr-2">Nombre del local</span>
								<label className="inline-flex items-center gap-2">{userData?.info?.locals_name}</label>
							</div>
							<div className="flex flex-col gap-2">
								<span className="font-medium lg:text-[14px] text-color1 mr-2">Teléfono</span>
								<label className="">{userData?.info?.movil}</label>
							</div>
							<div className="flex flex-col gap-2">
								<span className="font-medium lg:text-[14px] text-color1 mr-2">Dirección</span>
								<div className="flex items-center gap-2">{userData?.info?.direccion ?? "..."}</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className="w-full flex gap-4 mt-5 ">
				{roleId === "3" ? (
					<div className="w-full  min-h-[260px] bg-zinc-300 border-gray-300  flex items-center justify-center rounded-xl border-2  overflow-hidden">
						{!center ? (
							"Cargando..."
						) : (
							<Map
								defaultCenter={center}
								defaultZoom={10}
								gestureHandling={"greedy"}
								disableDefaultUI={true}
								scrollwheel={false}
								zoomControl={true}
							>
								<Marker position={center} />
							</Map>
						)}
					</div>
				) : (
					<>
						<div className="flex flex-col items-start  gap-2"></div>
					</>
				)}
			</div>
		</div>
	);
}
