import { Avatar, Badge, Card, CardBody, CardFooter, Image, Skeleton } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { Link } from "react-router-dom";
import SecondaryButton from "../common/SecondaryButton";
import { useMemo, useState } from "react";
import { RequestData } from "../../interfaces/Login";
import { deleteFriendRequest, respondFriendRequest } from "../../api/friends";
import { toast } from "react-toastify";
import { useHomeStore } from "../../stores/homeData";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";
import BuildingIcon from "../../assets/icons/BuildingIcon";
import { GenderFemale, GenderMale } from "../../assets/icons/UserIcon";

export default function FriendCard({
	data,
	type,
	orientations,
	provinces,
	setFriendToDelete,
	setFriendRequestsSent,
	onSuccessAccept,
	onSuccessReject,
}: {
	data: RequestData;
	userId?: number;
	type: "friend" | "request" | "requestSent";
	setFriendToDelete: () => void;
	setFriendRequestsSent: () => void;
	onSuccessAccept: () => void;
	onSuccessReject: () => void;
	orientations: SexualOrientation[];
	provinces: Province[];
}) {
	const [isSendingrequest, setIsSendingrequest] = useState(false);
	const [isCancelingrequest, setIsCancelingrequest] = useState(false);
	const [isSendingReject, setIsSendingReject] = useState(false);

	const setHome = useHomeStore((state) => state.setHome);
	const home = useHomeStore((state) => state.home);

	const onCancelRequest = async () => {
		setIsCancelingrequest(true);
		const response = await deleteFriendRequest(data.id);
		if (response.error == "true" || !response.info) toast.error(response?.info?.message || "Ha ocurrido un error");
		else {
			toast.success("Solicitud cancelada con éxito");
			setFriendRequestsSent();
		}

		setIsCancelingrequest(false);
	};

	const sendRequest = async (status: string) => {
		if (status == "accept") {
			setIsSendingrequest(true);
		} else setIsSendingReject(true);
		const response = await respondFriendRequest(data.id, { status });
		if (response.error == "true" || !response.info) toast.error(response?.info?.message || "Ha ocurrido un error");
		else {
			toast.success(status == "accept" ? "Solicitud aceptada con éxito" : "Solicitud rechazada con éxito");

			if (status == "accept") {
				onSuccessAccept();
				setHome({
					...home,
					amigos: [...home.amigos, response?.info.data[0]],
				});
			} else onSuccessReject();
		}

		status == "accept" ? setIsSendingrequest(false) : setIsSendingReject(false);
	};
	const orientation = useMemo(() => {
		const userSO = data?.info?.detalles?.sexualOrentation;
		const userPartnerSO = data?.info?.detalles?.partner_sexualOrentation;
		if (orientations.length) {
			const myO = userSO?.value && orientations.find((e) => e.id.toString() == userSO?.value)?.name;

			if (!data?.info?.is_couple) return myO ?? "?";

			const partnerO =
				userPartnerSO?.value && orientations.find((e) => e.id.toString() == userPartnerSO?.value)?.name;

			if (myO && partnerO) {
				if (myO == partnerO) return myO;
				return myO == "bisexual" ? partnerO : myO;
			}
			return "?";
		} else
			return (
				<Skeleton className="ml-2 rounded-lg">
					<p className="w-14 h-3"></p>
				</Skeleton>
			);
	}, [orientations]);

	const age = useMemo(() => {
		if (!data?.info?.detalles?.birthDate) return "?";
		const birthday = new Date(data?.info?.detalles?.birthDate);
		const ageDifMs = Date.now() - birthday.getTime();
		const ageDate = new Date(ageDifMs); // miliseconds from epoch
		if (data.info.is_couple && data?.info?.detalles?.partner_birthDate) {
			const partnerBirthday = new Date(data?.info?.detalles?.partner_birthDate);
			const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
			const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
			const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
			const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
			if (uAge == pAge) return `ambos ${pAge}`;
			return `${uAge} y ${pAge}`;
		}
		//partner_birthDate
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}, [data]);

	const province = useMemo(() => {
		if (provinces.length && data?.info?.provincia_id) {
			const pr = provinces.find((e) => e.id == data?.info?.provincia_id)?.name;
			return pr ?? "";
		}
		return (
			<Skeleton className="ml-2 rounded-lg">
				<p className="w-14 h-3"></p>
			</Skeleton>
		);
	}, [provinces]);

	return (
		<Card shadow="sm" radius="md" className="w-full max-w-lg flex flex-col items-start">
			<CardBody className="overflow-visible p-0">
				<div>
					<Image
						shadow="none"
						radius="md"
						width="100%"
						alt="alt"
						className="w-full object-cover h-[160px]"
						fallbackSrc="/assets/fantasyImage.jpeg"
						src={
							data?.portada_path
								? data?.portada_path.startsWith("http")
									? data?.portada_path
									: import.meta.env.VITE_BASE_URL + data?.portada_path
								: undefined
						}
					/>
					<div className="w-full flex justify-center">
						<Link to={`/profile/${data.id}`} className="-mt-[40px]">
							<Badge
								isOneChar
								content={
									data.role_id == "3" ? (
										<BuildingIcon stroke="white" />
									) : data.info?.detalles?.sex?.value == "f" ? (
										<GenderFemale />
									) : (
										<GenderMale />
									)
								}
								color="primary"
								size="lg"
								shape="circle"
								placement="bottom-right"
								className={`z-20 bottom-4 ${
									data.role_id == "3"
										? "bg-green-500"
										: data.info?.detalles?.sex?.value == "f"
										? "bg-pink-400"
										: "bg-blue-400"
								}`}
							>
								<Avatar
									src={
										data?.profile_path
											? data?.profile_path.startsWith("http")
												? data?.profile_path
												: import.meta.env.VITE_BASE_URL + data?.profile_path
											: undefined
									}
									showFallback
									className="z-20 w-20 h-20 border-3 border-white"
								/>
							</Badge>
						</Link>
					</div>
				</div>

				<div className="text-small flex flex-col items-start px-4 mt-2">
					<div>
						<b className="hover:text-primary text-xl">
							<Link to={`/profile/${data.id}`} key={data.id}>
								{data.name}
							</Link>
						</b>
						<span className="text-[16px] ml-3 font-bold text-primary">{age} años</span>
					</div>

					<div className="font-semibold text-color1">
						{data.role_id == "3" ? "Local" : orientation} -{" "}
						<span className="text-blue-500 font-bold ml-2">{province}</span>
					</div>

					<div className="text-sm text-gray-600 mt-2">
						{data.info?.detalles?.description.value
							? data.info.detalles.description.value.slice(0, 150)
							: "Sin descripción disponible."}
						{data.info?.detalles?.description.value &&
							data.info.detalles.description.value.length > 150 &&
							"..."}
					</div>
				</div>
			</CardBody>

			<CardFooter className="flex flex-col items-start px-4">
				{type == "friend" && (
					<PrimaryButton onClick={setFriendToDelete} className="w-full">
						Eliminar
					</PrimaryButton>
				)}
				{type == "request" && (
					<>
						<PrimaryButton
							loading={isSendingrequest}
							onClick={() => sendRequest("accept")}
							className="w-full mb-2"
						>
							Aceptar
						</PrimaryButton>
						<SecondaryButton
							loading={isSendingReject}
							onClick={() => sendRequest("reject")}
							className="w-full"
						>
							Rechazar
						</SecondaryButton>
					</>
				)}
				{type == "requestSent" && (
					<PrimaryButton loading={isCancelingrequest} onClick={() => onCancelRequest()} className="w-full">
						Cancelar
					</PrimaryButton>
				)}
			</CardFooter>
		</Card>
	);
}
