import { Card, CardBody, CardFooter, Image, Skeleton } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";
import PrimaryButton from "../common/PrimaryButton";
import { Party } from "../../interfaces/Party";
import { useMemo, useState } from "react";
import { requestJoinParty } from "../../api/party";
import { toast } from "react-toastify";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";

export default function PartyUserCard({
  user,
  party,
  onSendRequest,
  orientations,
  provinces,
}: {
  user: User;
  party: Party;
  onSendRequest: (id: number, user: User) => void;
  orientations: SexualOrientation[];
  provinces: Province[];
}) {
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const handleRequestJoin = async () => {
    setIsSendingRequest(true);
    const payload = {
      fiesta_id: party.id,
      user_id: user.id,
    };
    const res = await requestJoinParty(payload);
    if (res.info && res.error == false && party && user) {
      toast.success("Usuario invitado con éxito");
      setIsSendingRequest(false);
      onSendRequest(user.id, user);
    } else {
      if (res.error == true && res.info.message) {
        toast.error(res.info.message);
      } else toast.error("Ha ocurrido un error");
    }
    setIsSendingRequest(false);
  };
  const isRequest = () => {
    const request = party.solicitudes.find((req) => req.user.id == user.id);
    if (request?.estado.name == "pendiente") return true;
    return false;
  };

  const isMember = () => {
    const exist = party.integrantes.find((u) => u.id == user.id);
    if (exist) return true;
    return false;
  };
  const isMaybe = () => {
    const exist = party.talvez.find((r) => r.user.id == user.id);

    if (exist) return true;
    return false;
  };
  const isDeclined = () => {
    const exist = party.denegado.find((r) => r.user.id == user.id);
    if (exist) return true;
    return false;
  };

  const orientation = useMemo(() => {
    const userSO = user?.info?.detalles?.sexualOrentation;
    const userPartnerSO = user?.info?.detalles?.partner_sexualOrentation;
    if (orientations.length) {
      const myO =
        userSO?.value &&
        orientations.find((e) => e.id.toString() == userSO?.value)?.name;

      if (!user?.info?.is_couple) return myO ?? "?";

      const partnerO =
        userPartnerSO?.value &&
        orientations.find((e) => e.id.toString() == userPartnerSO?.value)?.name;

      if (myO && partnerO) {
        if (myO == partnerO) return myO;
        return myO == "bisexual" ? partnerO : myO;
      }
      return "?";
    } else
      return (
        <Skeleton className="ml-2 rounded-lg">
          <p className="w-14 h-3"></p>
        </Skeleton>
      );
  }, [orientations]);

  const age = useMemo(() => {
    if (!user?.info?.detalles?.birthDate) return "?";
    const birthday = new Date(user?.info?.detalles?.birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    if (user.info.is_couple && user?.info?.detalles?.partner_birthDate) {
      const partnerBirthday = new Date(user?.info?.detalles?.partner_birthDate);
      const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
      const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
      const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
      if (uAge == pAge) return `ambos ${pAge}`;
      return `${uAge} y ${pAge}`;
    }
    //partner_birthDate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [user]);
  const province = useMemo(() => {
    if (provinces.length && user?.info?.provincia_id) {
      const pr = provinces.find((e) => e.id == user?.info?.provincia_id)?.name;
      return pr ?? "";
    }
    return (
      <Skeleton className="ml-2 rounded-lg">
        <p className="w-14 h-3"></p>
      </Skeleton>
    );
  }, [provinces]);
  return (
    <Card shadow="sm" radius="md" className="w-full h-[300px] max-h-[300px]">
      <CardBody className="overflow-hidden bg-slate-200 p-1 ">
        <div className="relative object-fit min-h-44">
          <Link to={`/profile/${user?.id}`}>
            <Image
              radius="md"
              width="100%"
              alt="post title here"
              isZoomed
              className="w-full object-cover h-[200px] md:h-[140px]"
              fallbackSrc="/assets/fantasyImage.jpeg"
              src={
                user?.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
            />
          </Link>
        </div>
      </CardBody>

      <CardFooter className="flex flex-col text-start justify-between h-full">
        <div className="flex flex-col ">
          <p>
            <b className="hover:text-primary text-lg">
              <Link to={`/profile/${user.id}`} key={user.id}>
                {user.name}
              </Link>
            </b>
            <span className="text-[15px] ml-3 font-bold text-primary">
              {age} años
            </span>
          </p>
          <p className="font-semibold text-[15px] text-color1 mb-4">
            {" "}
            <span className="font-semibold text-color1">
              {user.info?.is_couple
                ? "Pareja"
                : user.info?.detalles?.sex?.value == "f"
                ? "Mujer"
                : "Hombre"}{" "}
              {orientation} -
              <span className="text-blue-500 font-bold ml-2">{province}</span>
            </span>
          </p>
        </div>
        {isRequest() && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Pendiente
          </PrimaryButton>
        )}
        {isMember() && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Integrante
          </PrimaryButton>
        )}
        {isMaybe() && (
          <PrimaryButton
            className="mt-2 w-full bg-yellow-400 text-black"
            disabled
          >
            Tal vez
          </PrimaryButton>
        )}
        {isDeclined() && (
          <PrimaryButton className="mt-2 w-full bg-red-600 " disabled>
            No asistirá
          </PrimaryButton>
        )}
        {party.user_id == user.id && (
          <PrimaryButton className="mt-2 w-full" disabled>
            Autor
          </PrimaryButton>
        )}
        {!isRequest() &&
          !isMember() &&
          !isMaybe() &&
          !isDeclined() &&
          party.user_id != user.id && (
            <PrimaryButton
              className="mt-2 w-full"
              onClick={handleRequestJoin}
              loading={isSendingRequest}
            >
              Invitar
            </PrimaryButton>
          )}
      </CardFooter>
    </Card>
  );
}
