import { ReactNode } from "react";

interface ProfileSectionProps {
	title: string;
	icon?: ReactNode; // Cambiar a opcional con "?"
	children: ReactNode;
}

const ProfileSection = ({ title, icon, children }: ProfileSectionProps) => (
	<div className="flex flex-wrap lg:flex-nowrap gap-4">
		<div className="flex-1">
			<span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
				{icon && <>{icon}</>} {/* Renderiza solo si "icon" existe */}
				{title}
			</span>
			{children}
		</div>
	</div>
);

export default ProfileSection;
