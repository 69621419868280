export const races = [
	{
		id: 1,
		name: "Asiática",
		sex: "f",
	},
	{
		id: 2,
		name: "Caucásica",
		sex: "f",
	},
	{
		id: 3,
		name: "Europea",
		sex: "f",
	},
	{
		id: 4,
		name: "Latina",
		sex: "f",
	},
	{
		id: 5,
		name: "Mediterránea",
		sex: "f",
	},
	{
		id: 6,
		name: "Negra",
		sex: "f",
	},
	{
		id: 7,
		name: "Árabe",
		sex: "f",
	},
	{
		id: 8,
		name: "Asiático",
		sex: "m",
	},
	{
		id: 9,
		name: "Caucásico",
		sex: "m",
	},
	{
		id: 10,
		name: "Europeo",
		sex: "m",
	},
	{
		id: 11,
		name: "Latino",
		sex: "m",
	},
	{
		id: 12,
		name: "Mediterráneo",
		sex: "m",
	},
	{
		id: 13,
		name: "Negro",
		sex: "m",
	},
	{
		id: 14,
		name: "Árabe",
		sex: "m",
	},
];

export const complexions = [
	{
		id: 1,
		name: "Algunos kilos de más",
		sex: "f",
	},
	{
		id: 2,
		name: "Atlética",
		sex: "f",
	},
	{
		id: 3,
		name: "Delgada",
		sex: "f",
	},
	{
		id: 4,
		name: "Musculosa",
		sex: "f",
	},
	{
		id: 5,
		name: "Normal",
		sex: "f",
	},

	{
		id: 8,
		name: "Algunos kilos de más",
		sex: "m",
	},
	{
		id: 9,
		name: "Atlético",
		sex: "m",
	},
	{
		id: 10,
		name: "Delgado",
		sex: "m",
	},
	{
		id: 11,
		name: "Musculoso",
		sex: "m",
	},
	{
		id: 12,
		name: "Normal",
		sex: "m",
	},
];

export const eyesColor = [
	{
		id: 1,
		name: "Avellana",
	},
	{
		id: 2,
		name: "Azules",
	},
	{
		id: 3,
		name: "Grises",
	},
	{
		id: 4,
		name: "Marrones",
	},
	{
		id: 5,
		name: "Negro",
	},
	{
		id: 6,
		name: "Verde",
	},
];

export const hairColors = [
	{
		id: 7,
		name: "Blanco",
	},
	{
		id: 8,
		name: "Canoso",
	},
	{
		id: 9,
		name: "Castaño",
	},
	{
		id: 10,
		name: "Moreno",
	},
	{
		id: 11,
		name: "Rojo",
	},
	{
		id: 12,
		name: "Rubio",
	},
];

export const noseTypes = [
	{
		id: 1,
		name: "Aguileña",
	},
	{
		id: 2,
		name: "Chata",
	},
	{
		id: 3,
		name: "Grande",
	},
	{
		id: 4,
		name: "Huesuda",
	},
	{
		id: 5,
		name: "Larga",
	},
	{
		id: 6,
		name: "Normal",
	},
	{
		id: 7,
		name: "Pequeña",
	},
];

export const skincolors = [
	{
		id: 1,
		name: "Café con leche",
	},
	{
		id: 2,
		name: "Clara",
	},
	{
		id: 3,
		name: "Morena",
	},
	{
		id: 4,
		name: "Negra",
	},
	{
		id: 5,
		name: "Oscura",
	},
];

export const pubicHairCut = [
	{
		id: 1,
		name: "Mucho",
	},
	{
		id: 2,
		name: "Normal",
	},
	{
		id: 3,
		name: "Poco",
	},
	{
		id: 4,
		name: "Afeitado",
	},
];

export const piercingCount = [
	{
		id: 1,
		name: "Ninguno",
	},
	{
		id: 2,
		name: "Alguno",
	},
	{
		id: 3,
		name: "Muchos",
	},
	{
		id: 4,
		name: "Varios",
	},
];
export const tattoosCount = [
	{
		id: 1,
		name: "Ninguno",
	},
	{
		id: 2,
		name: "Alguno",
	},
	{
		id: 3,
		name: "Muchos",
	},
	{
		id: 4,
		name: "Varios",
	},
];

export const drink = [
	{
		id: 1,
		name: "No",
	},
	{
		id: 2,
		name: "Regularmente",
	},
	{
		id: 3,
		name: "Socialmente",
	},
	{
		id: 4,
		name: "Si",
	},
];
export const smoke = [
	{
		id: 1,
		name: "No",
	},
	{
		id: 2,
		name: "Regularmente",
	},
	{
		id: 3,
		name: "Socialmente",
	},
	{
		id: 4,
		name: "Si",
	},
];

export const childs = [
	{
		id: 1,
		name: "Me lo reservo",
	},
	{
		id: 2,
		name: "Si",
	},
	{
		id: 3,
		name: "No",
	},
];

export const possibilitiesOfHosting = [
	{
		id: 1,
		name: "No hay sitio",
	},
	{
		id: 2,
		name: "Sí, hay sitio",
	},
];

export const possibilitiesOfMoving = [
	{
		id: 1,
		name: "Misma ciudad",
	},
	{
		id: 2,
		name: "Misma provincia",
	},
	{
		id: 3,
		name: "Toda España",
	},
	{
		id: 4,
		name: "Todo el mundo",
	},
];

export const lipsOpt = [
	{
		id: 1,
		name: "Finos",
	},
	{
		id: 2,
		name: "Carnosos",
	},
];

export const hairCuts = [
	{
		id: 1,
		name: "Largo",
	},
	{
		id: 2,
		name: "Hasta los hombros",
	},
	{
		id: 3,
		name: "Corto",
	},
	{
		id: 4,
		name: "Muy corto",
	},
];

export const sexs = [
	{ id: "f", name: "Mujer" },
	{ id: "m", name: "Hombre" },
];

export const nippleSizes = [
	{ label: "Pequeños", value: "1" },
	{ label: "Medianos", value: "2" },
	{ label: "Grandes", value: "3" },
];
