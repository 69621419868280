export const getPlaceByLatLng = async (lat: number, lng: number) => {
  const geocoder = new google.maps.Geocoder();
  const results = await geocoder.geocode({
    location: { lat, lng },
    language: "es",
  });
  if (results) return results;
};
export const getPlaceFormated = async (lat: number, lng: number) => {
  const place = await getPlaceByLatLng(lat, lng);
  return place?.results[0].formatted_address;
};

export const getCity = async (lat: number, lng: number) => {
  const place = await getPlaceByLatLng(lat, lng);
  return (
    place?.results[
      place?.results.length - 4 >= 0 ? place?.results.length - 4 : 0
    ].formatted_address ?? "Madrid, España"
  );
};
