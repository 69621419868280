import Logo from "../components/common/Logo";
import CustomInput from "../components/form/CustomInput";
import PrimaryButton from "../components/common/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { Switch } from "@nextui-org/react";
// API
import { register } from "../api/auth";

import { toast } from "react-toastify";
import VerifyEmail from "../components/auth/VerifyEmail";
import { useAuthStore } from "../stores/authStore";
import { useStepperStore } from "../stores/stepperStore";

import ProfileThirdStepForm from "../components/auth/ProfileInfoThirdStepForm";
import ProfileFourthStepForm from "../components/auth/ProfileInfoFourthStepForm";
import { useLoginStore } from "../stores/loginDataStore";
import { User } from "../interfaces/Login";
import ProfileFiveStepForm from "../components/auth/ProfileInfoFiveStepForm";
import LoginImage from "../components/auth/LoginImage";
import { saveUserInfo } from "../api/info";
import { useQuery } from "../hooks/useQuery";
import LocalInfoForm from "../components/auth/LocalEditInfo";

export default function Register() {
	const user = useLoginStore((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [isLocal, setIsLocal] = useState(user?.role_id == "3" ? true : false);
	// const [token, setToken] = useState("");
	const [verifyModal, setVerifyModal] = useState(false);

	const setUser = useAuthStore((state) => state.saveUser);
	const saveUser = useLoginStore((state) => state.saveUser);

	// const authToken = useAuthStore((state) => state.token);
	const saveToken = useAuthStore((state) => state.saveToken);

	const step = useStepperStore((state) => state.step);
	const setStep = useStepperStore((state) => state.setStep);

	const navigate = useNavigate();

	const [email, setEmail] = useState(user?.email ?? "");
	const [participants, setParticipants] = useState(1);

	const query = useQuery();

	const backStep = () => {
		setStep(step - 1);
	};

	const [loginData, setLoginData] = useState({
		username: "",
		email: "",
		password: "",
	});
	const [localData, setLocalData] = useState({
		owners_name: "",
		locals_name: "",
		movil: "",
		CIF: "",
		direccion: "",
		ubicacion: "",
	});

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setLoginData({
			...loginData,
			[target.name]: target.value,
		});
	};
	// useEffect(() => {
	//   saveUser(null);
	//   setUser(null);
	//   saveToken("");
	// }, [loginData]);

	// const handleLocalChange = (e: FormEvent<HTMLInputElement>) => {
	//   const target = e.target as HTMLInputElement;
	//   setLocalData({
	//     ...localData,
	//     [target.name]: target.value,
	//   });
	// };
	async function submitFirstStep(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		//const formData = new FormData(e.target as HTMLFormElement);
		setLoading(true);
		//Data for request
		let data: any = {
			...loginData,
			role_id: isLocal ? "3" : "2",
		};
		if (query.get("code")) {
			data = {
				...loginData,
				role_id: isLocal ? "3" : "2",
				referente: query.get("code"),
			};
		}
		const responseData = await register(data);
		setLoading(false);

		if (responseData.error == "true") {
			toast.error(responseData.info.message + " ");
		} else {
			setUser(responseData.info.data.user as User);
			setEmail(responseData.info.data.user.email);

			if (responseData.info.data.user.email_verified_at == null) {
				// setToken(responseData.info.data.token);
				saveToken(responseData.info.data.token);
				setVerifyModal(true);
			} else {
				saveToken(responseData.info.data.token);
				toast.success(responseData.info.message);
			}
		}
	}

	useEffect(() => {
		if (user) {
			//ref.current.play();
			// console.log("hola", user);
			if (user.email_verified_at == null) {
				//setToken(authToken);
				setVerifyModal(true);
			} else if (!user.perfil_id) {
				// TODO: Verify this
				if (user.role_id == "2") setStep(1);
				else setStep(2);

				navigate("/register"); // go to fill your data
			} else if (!user.verified && !user.info?.verification_path && user.role_id == "2") {
				// this is just for guide
				saveUser(user);
				navigate("/login"); // go to upload your img
			} else if (!user.verified) {
				saveUser(user);
				navigate("/"); // go to verification pendig //TODO: wait tofix this
			} else if (user.verified && !user.subscription_payed) {
				saveUser(user);
				navigate("/"); // go to home and pay your subscription
			} else {
				saveUser(user);
				navigate("/");
			} // just go home
		} else {
			setVerifyModal(false);
		}
	}, [user]);

	async function submitSecondStep(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		e.preventDefault();
		//const formData = new FormData(e.target as HTMLFormElement);
		setLoading(true);

		//Data for request
		const data = {
			...loginData,
			role_id: "2",
		};
		const responseData = await register(data);
		setLoading(false);

		// console.log(responseData);

		if (responseData.error == "true") {
			toast.error(responseData.info.message + " ");
		} else {
			saveToken(responseData.info.data.token); //TODO: verify this
			setVerifyModal(true);
		}
	}

	async function submitForLocal(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		//const formData = new FormData(e.target as HTMLFormElement);
		if (!user) {
			toast.error("Tu sesión se ha perdido debes loguearte");
			navigate("/login");
		}

		const data = {
			...localData,
			user_id: user?.id,
		};

		setLoading(true);
		const response = await saveUserInfo(data);
		if (response.error == "true") {
			toast.error(response.info.message);
		} else {
			toast.success(response.info.message);
			saveUser(response.info.data[0]);
			setUser(response.info.data[0]);
			navigate("/login");
		}
		setLoading(false);
	}

	return (
		<div className="flex justify-center">
			<VerifyEmail
				// token={token} TODO: check this
				isOpen={verifyModal}
				email={email}
				closeModal={() => setVerifyModal(false)}
				next={() => setStep(step + (isLocal ? 2 : 1))}
			/>
			<div className="w-full flex max-w-[1554px] h-screen justify-center  overflow-hidden">
				<div className="flex row p-3 w-full items-center  overflow-hidden">
					<section className="col-12 xl:col-7 flex justify-center  items-center h-full ">
						<div className="w-full">
							{/*First step for register user*/}
							<div className="flex justify-center max-h-[100vh]  xl:justify-end xl:mr-36 pb-3 overflow-hidden overflow-y-auto hide-scroll">
								<form
									hidden={step == 0 ? false : true}
									onSubmit={submitFirstStep}
									className="max-w-[360px] h-max m-6 text-center"
								>
									<div className="flex justify-center">
										<Logo />
									</div>
									<h1 className="mt-6 font-semibold text-[30px] ">Regístrate</h1>
									<p className="mt-2 font-normal text-color1 text-[16px]">
										Únete a Loouth y empieza a conectar con personas de tus mismos gustos
									</p>
									<CustomInput
										required
										type="text"
										name="username"
										label="Nombre"
										placeholder="Introduce tu nombre"
										className="pt-[28px]"
										errorMessage="Por favor introduzca su nombre"
										value={loginData.username}
										onChange={handleChange}
									/>
									<CustomInput
										required
										type="email"
										name="email"
										label="Email"
										placeholder="Introduce tu correo electrónico"
										className="pt-[20px]"
										errorMessage="Por favor introduzca un email correcto"
										value={loginData.email}
										onChange={handleChange}
									/>
									<CustomInput
										required
										type="password"
										name="password"
										label="Contraseña"
										placeholder="Crea una contraseña"
										className="pt-[20px]"
										minLength={8}
										value={loginData.password}
										onChange={handleChange}
									/>
									<div className="-mt-1 flex justify-start">
										<p className="font-normal text-[14px] text-color5">
											Debe de tener al menos 8 caracteres
										</p>
									</div>
									<div className="mt-4 flex justify-start">
										<Switch
											isSelected={isLocal}
											onValueChange={setIsLocal}
											size="sm"
											classNames={{
												wrapper: "bg-gray-400 group-data-[selected=true]:bg-color4",
											}}
										>
											Soy un local
										</Switch>
									</div>
									<PrimaryButton type="submit" loading={loading} className="mt-[20px] w-full">
										Empezar
									</PrimaryButton>
									<div className="mt-[28px] flex justify-center gap-1">
										<p className="font-normal text-[14px] text-color5">¿Ya tienes una cuenta?</p>
										<Link className="font-semibold text-[14px] text-color4" to="/login">
											Iniciar Sesión
										</Link>
									</div>
								</form>
							</div>

							{/*Second step for register user*/}
							<div className="flex justify-center xl:justify-end xl:pr-20">
								<form
									hidden={step == 1 ? false : true}
									onSubmit={submitSecondStep}
									className="max-w-[360px] h-max m-6 text-center items-center"
								>
									<div className="flex justify-center">
										<Logo />
									</div>
									<h1 className="mt-6 font-semibold text-[30px] ">Regístrate</h1>
									<h3 className="mt-2 font-normal text-color1 text-[16px]">
										Para empezar cuéntanos.
									</h3>
									<h3 className="font-bold text-[16px] text-color4">¿Vas solo o en pareja?</h3>
									<div className="mt-8 flex gap-6">
										<PrimaryButton
											onClick={() => {
												setParticipants(1);
												setStep(step + 1);
											}}
											className="w-[168px] h-[168px]"
										>
											<div>
												<div className="flex justify-center">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</div>
												<div className="mt-1">
													<p>Yo solo</p>
												</div>
											</div>
										</PrimaryButton>
										<PrimaryButton
											onClick={() => {
												setParticipants(2);
												setStep(step + 1);
											}}
											className="w-[168px] h-[168px]"
										>
											<div>
												<div className="flex justify-center">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</div>
												<div className="mt-1">
													<p>En pareja</p>
												</div>
											</div>
										</PrimaryButton>
									</div>
									<div className="md:hidden mt-[58px] flex justify-center gap-1">
										<p className="font-normal text-[14px] text-color5">¿Ya tienes una cuenta?</p>
										<Link className="font-semibold text-[14px] text-color4" to="/login">
											Iniciar Sesión
										</Link>
									</div>
								</form>
							</div>
							{/*Third step for register user "Detalles del perfil" */}
							<div className="w-full flex justify-center overflow-hidden overflow-y-auto hide-scroll">
								<ProfileThirdStepForm
									isLocal={isLocal}
									participants={participants}
									backStep={backStep}
									next={() => console.log("next")}
								/>
							</div>
							{/*Fourth step for register user "Como soy" */}
							<div className="w-full flex justify-center overflow-hidden overflow-y-auto hide-scroll">
								<ProfileFourthStepForm participants={participants} backStep={backStep} />
							</div>
							{/*Fifth step for register user "Otros datos" */}
							<div className="w-full flex justify-center overflow-hidden overflow-y-auto hide-scroll">
								<ProfileFiveStepForm
									isLocal={isLocal}
									participants={participants}
									backStep={backStep}
								/>
							</div>

							{/*Render when it is a local that you want to register*/}
							<div className="w-full flex justify-center">
								{/* 
                <form
                  hidden={isLocal && step == 2 ? false : true}
                  onSubmit={submitForLocal}
                  className="w-full max-w-[360px] h-max text-center"
                >
                  <div className="flex justify-center">
                    <Logo />
                  </div>
                  <h1 className="mt-6 font-semibold text-[30px] ">
                    Datos del Local
                  </h1>
                  <p className="mt-2 font-normal text-color1 text-[16px]">
                    Completa la información de tu local
                  </p>
                  <CustomInput
                    required
                    type="text"
                    name="owners_name"
                    label="Nombre del dueño"
                    placeholder="Introduce el nombre del dueño"
                    className="pt-[28px]"
                    errorMessage="Por favor introduzca el nombre"
                    value={localData.owners_name}
                    onChange={handleLocalChange}
                  />
                  <CustomInput
                    required
                    type="text"
                    name="locals_name"
                    label="Nombre del local"
                    placeholder="Introduce el nombre del local"
                    className="pt-[20px]"
                    errorMessage="Por favor introduzca el nombre"
                    value={localData.locals_name}
                    onChange={handleLocalChange}
                  />
                  <CustomInput
                    required
                    type="phone"
                    name="movil"
                    label="Teléfono"
                    placeholder="Introduce el teléfono"
                    className="pt-[20px]"
                    errorMessage="Por favor introduzca el teléfono"
                    value={localData.movil}
                    onChange={handleLocalChange}
                  />
                  <CustomInput
                    required
                    type="text"
                    name="CIF"
                    label="Cif nif"
                    placeholder="Introduce el cif nif"
                    className="pt-[20px]"
                    errorMessage="Por favor introduzca el cif nif"
                    value={localData.CIF}
                    onChange={handleLocalChange}
                  />
                  
                  <PrimaryButton
                    type="submit"
                    loading={loading}
                    className="mt-[20px] w-full"
                  >
                    Completar registro
                </PrimaryButton>
              </form> 
                */}

								{isLocal && step == 2 && (
									<LocalInfoForm
										localData={localData}
										loading={loading}
										handleSubmit={submitForLocal}
										setLocalData={setLocalData}
									/>
								)}
							</div>
						</div>
						{/* Render when it is a local that you want to register
            <div
              hidden={!isLocal && step != 1 ? !false : !true}
              className={
                isLocal
                  ? "w-full flex flex-col items-center h-[90vh] m-6 xl:overflow-hidden xl:overflow-y-auto xl:p-5"
                  : ""
              }
            >
              ...
            </div> */}
					</section>
					<section className="hidden xl:flex xl:col-5 justify-end items-center p-5 pl-0">
						<div className="relative py-3 w-[720px] h-[calc(100vh-20px)] max-h-[920px]  flex items-center">
							<LoginImage />
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}
