import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalContent,
  Image,
} from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { User } from "../../interfaces/Login";
// getUsers
export default function PartyMembersModal({
  isOpen = false,
  closeModal = () => {},
  title,
  emptyMessage,
  integrantes,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  title: string;
  emptyMessage: string;
  integrantes: User[];
}) {
  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full px-6">
              <h3 className="mt-6 font-semibold text-color3 text-xl">
                {title}
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>
              {integrantes.length == 0 ? (
                <div className="h-[300px] mt-4 py-2 flex items-center justify-center gap-4 ">
                  <p className="text-center">{emptyMessage}</p>
                </div>
              ) : (
                <div className="h-[350px] mt-4 pt-4 flex flex-wrap items-start gap-4 overflow-hidden overflow-y-auto w-full">
                  {integrantes.map((e) => (
                    <Card
                      shadow="sm"
                      key={e.id}
                      radius="md"
                      className="w-[180px]"
                    >
                      <CardBody className="overflow-visible p-0">
                        <Link to={`/profile/${e.id}`} key={e.id}>
                          <Image
                            shadow="none"
                            radius="md"
                            width="100%"
                            isZoomed
                            alt="alt"
                            className="w-full object-cover h-[140px]"
                            fallbackSrc="/assets/fantasyImage.jpeg"
                            src={
                              e?.profile_path
                                ? e?.profile_path?.startsWith("http")
                                  ? e?.profile_path
                                  : import.meta.env.VITE_BASE_URL +
                                    e?.profile_path
                                : undefined
                            }
                          />
                        </Link>
                      </CardBody>
                      <CardFooter className="text-small flex flex-col items-start">
                        <Link to={`/profile/${e.id}`} key={e.id}>
                          <b className="hover:text-primary text-lg">{e.name}</b>
                          <p className="text-color1">
                            Úlima vez{" "}
                            <Moment toNow locale="Es">
                              {e.last_conection}
                            </Moment>
                          </p>
                        </Link>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="p-6 md:flex block justify-end border-t-1 border-color1/50 ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
