import { Modal, ModalContent } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import PremiumImage from "../../assets/premium-image.png";
import { useAuthStore } from "../../stores/authStore";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../stores/loginDataStore";
import SecondaryButton from "../common/SecondaryButton";
import { getUser } from "../../api/user";
import { useState } from "react";
import { toast } from "react-toastify";

export default function VerificationModal({
  isOpen = false,
}: {
  isOpen: boolean;
}) {
  const saveUser = useAuthStore((state) => state.saveUser);
  const authData = useAuthStore((state) => state.user);
  const setUser = useLoginStore((state) => state.saveUser);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => {
    saveUser(null);
    setUser(null);
    navigate("/login");
  };
  const onRefresh = async () => {
    setIsLoading(true);
    const userRes = await getUser(authData!.id);
    const userData = userRes.info.data[0];
    if (userData?.verified) {
      saveUser({
        ...authData,
        ...userData,
      });
      setUser(null);
      toast.success("Tu cuenta ha sido verificada!");
    } else toast.info("Tu cuenta aún ha sido verificada");
    setIsLoading(false);
  };

  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpen}
      placement="center"
      hideCloseButton
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-center">
            <div className="text-center">
              <img src={PremiumImage} className="" />
              <h3 className="mt-6 font-semibold text-color3 text-[18px]">
                Cuenta en verificación
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5">
                Tu perfil será verificado a la mayor brevedad posible, recibirás
                un correo cuando se complete la verificación.
              </p>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <PrimaryButton
              loading={isLoading}
              className="w-full"
              onClick={onRefresh}
              disabled={isLoading}
            >
              Comprobar
            </PrimaryButton>
            <SecondaryButton
              disabled={isLoading}
              className="w-full"
              onClick={onCancel}
            >
              Salir
            </SecondaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
