import { Modal, ModalContent, Progress, Tooltip } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import FileInput from "../form/FileInput";
import CustomCheckBox from "../form/CustomCheckBox";
import { Link } from "react-router-dom";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { toast } from "react-toastify";
import { createPublication } from "../../api/publication";
import { useHomeStore } from "../../stores/homeData";
import { uploadFile } from "../../api/upload";
import { round } from "../../utils/useNumber";
import { AxiosProgressEvent } from "axios";
import { Fantasy } from "../../interfaces/Fantasy";
import { getFantasies } from "../../api/formSelectsData";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import CustomRadioGroup from "../form/CustomRadioGroup";
import { InfoCircleIcon } from "../../assets/icons/InfoIcon";

export default function PostUploadFile({
  isOpen = false,
  type = "image",
  isFantasy = 0,
  closeModal = () => {},
  fantasyId,
}: {
  isOpen: boolean;
  type?: "image" | "video";
  closeModal?: () => void;
  isFantasy?: 0 | 1;
  fantasyId?: string;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [visibility, setVisibility] = useState("public");
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [info, setInfo] = useState({
    fantasies: [] as Fantasy[],
  });

  const inputRef = useRef(null);

  const authData = useAuthStore((state) => state.user);

  const setNewPost = useHomeStore((state) => state.setNewPost);
  const setNewFantasyPost = useHomeStore((state) => state.setNewFantasyPost);

  const onCancel = () => {
    setFile(null);
    setFileUrl("");
    closeModal();
  };

  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      //console.log(selectedFiles);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFileUrl(fileUrl);
      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const maxSize = type == "image" ? 7.1 : 50.1;

    if (file && round(file.size / (1024 * 1024)) > maxSize) {
      toast.error(`El archivo no debe superar los ${maxSize} MB`);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();

      if (file) {
        formData.append("files[0]", file, file.name);
        formData.append("type", type);
        formData.append("folder[0]", "posts");
      }

      const uploadResponse = await uploadFile(formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) =>
          setPercentCompleted(
            Math.round((progressEvent.loaded * 100) / progressEvent.total!)
          ),
      });

      if (uploadResponse.error == "true") {
        toast.error(uploadResponse.info.message + " ");
        setLoading(false);
        return;
      }

      let data = {
        content: uploadResponse.info.data[0].posts.file.content,
        type: uploadResponse.info.data[0].posts.type,
        isFantasia: isFantasy,
        fantasia_id: "",
        visibility: visibility,
      };
      if (isFantasy && fantasyId) {
        data = {
          ...data,
          fantasia_id: fantasyId,
        };
      }

      const responseData = await createPublication(data);
      console.log(responseData);

      if (responseData.error == "true") {
        toast.error(responseData.info.message + " ");
      } else {
        toast.success(responseData.info.message);
        if (visibility == "public") {
          if (isFantasy) setNewFantasyPost(responseData.info.data[0]);
          else setNewPost(responseData.info.data[0]);
        }

        //if (!data.isFantasia)
        /*setHomeData({
          ...homeData,
          posts: [responseData.info.data[0], ...homeData.posts],
        });*/
        setFile(null);
        setFileUrl("");
        setLoading(false);
        setPercentCompleted(0);
        closeModal();
      }
    } catch (error) {
      toast.error("Error desconocido");
    }
    setPercentCompleted(0);
    setLoading(false);
    // setFantasy("");
  }

  const onCheck = (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    setCheck(value);
  };

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    const getData = async () => {
      const fantasies = await getFantasies();
      setInfo({
        ...info,
        fantasies: fantasies?.info?.data[0] ?? [],
      });
      //console.log(fantasies);
    };
    if (authData) getData();
  }, [authData]);

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setVisibility(data.value);
  };

  return (
    <Modal
      className="max-w-[420px]"
      isOpen={isOpen}
      placement="center"
      onClose={onCancel}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="flex justify-start">
            <div className="text-start">
              <h3 className="font-semibold text-color3 text-[18px]">
                Sube {type == "image" ? "una foto" : "un video"}
              </h3>
              <p className="mt-1 font-normal text-[14px] text-color5">
                Recuerda no faltar al respeto y divertirte de forma segura
              </p>
            </div>
          </div>
          <div className="mt-5 flex  justify-center">
            <div className="w-full flex flex-col items-center">
              <FileInput
                className="mt-5 w-full min-h-[200px] rounded-xl border-2 border-color4 cursor-pointer overflow-hidden"
                onClick={onFileUpload}
                file={fileUrl}
                type={type}
              />
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept={type == "image" ? "image/*" : "video/*"}
                onChange={handleChange}
              ></input>
              <div className="mt-4 gap-3 flex justify-start items-center w-full">
                <div>
                  <CustomRadioGroup
                    title=""
                    name="visbility"
                    value={visibility}
                    showVisbility={false}
                    onChange={handleChangeInput}
                    items={[
                      { label: "Privada", value: "private" },
                      { label: "Pública", value: "public" },
                    ]}
                  />
                </div>
                <Tooltip
                  content={
                    <div className="max-w-sm py-3 text-color2">
                      <p>
                        <b>Privada:</b> Solo tus amigos podrán verla y no
                        aparecerá en el feed.
                      </p>
                      <p>
                        <b>Pública:</b> Cualquiera podrá verla.
                      </p>
                    </div>
                  }
                  placement="top"
                >
                  <span className="">
                    <InfoCircleIcon className="text-color1" />
                  </span>
                </Tooltip>
              </div>
              <div className="mt-2 w-full">
                <CustomCheckBox checked={check} onChange={onCheck}>
                  <p>
                    He leído y acepto los{" "}
                    <Link
                      to="https://loouth.com/terminos-y-condiciones"
                      className="underline underline-offset-2 cursor-pointer"
                    >
                      Términos y Condiciones
                    </Link>
                  </p>
                </CustomCheckBox>
              </div>

              {loading && (
                <div className="flex items-center gap-3 mt-2 w-full">
                  <Progress
                    size="md"
                    aria-label="Cargando..."
                    value={percentCompleted}
                    className=" h-[8px]"
                    classNames={{
                      indicator: "bg-color4",
                    }}
                  />
                  <label className="font-medium text-[14px] mb-0.5 ">
                    {percentCompleted}%
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex gap-[12px]">
            <SecondaryButton
              className="w-full"
              onClick={onCancel}
              disabled={loading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="w-full"
              loading={loading}
              disabled={!file || !check}
              type="submit"
            >
              Publicar
            </PrimaryButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
