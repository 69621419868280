import NavBar from "../components/common/Navbar";
import PrimaryButton from "../components/common/PrimaryButton";
// import Footer from "../components/Footer";
import Post from "../components/post/Post";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
// import PremiumModal from "../components/PremiumModal";
import VerificationModal from "../components/auth/VerificationPendingModal";
import { useHomeStore } from "../stores/homeData";
import { Post as Publication } from "../interfaces/Post";
import { NewPostCard } from "../components/post/NewPostCard";
import { getPublications } from "../api/publication";
import InfiniteScroll from "react-infinite-scroll-component";
import LastVisits from "../components/home/LastVisits";
import LastPhotos from "../components/home/LastPhotos";
// import NewUsers from "../components/home/NewUsers";
import LastVideos from "../components/home/LastVideos";
import MyFriends from "../components/home/MyFriends";
import MyAbout from "../components/home/MyAbout";
import { Link } from "react-router-dom";
import { getHome } from "../api/mine";
import { toast } from "react-toastify";
import { getFantasies } from "../api/formSelectsData";
import { Fantasy } from "../interfaces/Fantasy";
import PostSkeleton from "../components/post/PostSkeleton";
import { getUsers } from "../api/user";
import { Avatar, Skeleton } from "@nextui-org/react";
import { User } from "../interfaces/Login";
import { withErrorHandler } from "../utils/withErrorFallback";
import { getReactionTypes } from "../api/reactions";
import { NewsIcon } from "../assets/icons/NewsIcon";
import NewsContent from "../components/home/News";

export default function Home() {
	// const [showSideBar, setShowSideBar] = useState(true);
	const authData = useAuthStore((state) => state.user);
	const homeData = useHomeStore((state) => state.home);
	const setHomeData = useHomeStore((state) => state.setHome);
	const setReactionType = useHomeStore((state) => state.setReactionType);

	const newPost = useHomeStore((state) => state.newPost);
	const setNewPost = useHomeStore((state) => state.setNewPost);

	const newComment = useHomeStore((state) => state.newComment);
	const setNewComment = useHomeStore((state) => state.setNewComment);

	const [posts, setPosts] = useState<Publication[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [loadingConnected, setLoadingConnected] = useState(false);
	const [loading, setLoading] = useState(false);
	const [totalOnlineUsers, setTotalOnlineUsers] = useState(0);
	const [onlineUsers, setOnlineUsers] = useState<User[]>([]);
	// const [isLoading, setIsLoading] = useState(false);
	const [fantasies, setFantasies] = useState<Fantasy[]>([]);

	// const handleClick = () => {
	//   setShowSideBar(!showSideBar);
	//   console.log(showSideBar);
	// };

	const fetchPosts = withErrorHandler(async () => {
		const exclude = posts.map((e) => e.id);
		const response = await getPublications(exclude.length ? exclude.join(",") : "0");
		// console.log(response.info.data[0]);
		if (!response.info.data[0].length) setHasMore(false);
		else setPosts([...posts, ...response.info.data[0]]);
	});

	useEffect(() => {
		if (newPost) {
			setPosts([newPost, ...posts]);
			return () => {
				setNewPost(undefined);
			};
		}
	}, [newPost]);

	useEffect(() => {
		if (newComment) {
			const upd = posts.map((p) => {
				if (p.id == newComment.post && p.info) {
					p.info.comentarios += newComment.count;
				}
				return p;
			});
			const lastvideos = homeData.posts_video.map((p) => {
				if (p.id == newComment.post && p.info) {
					p.info.comentarios += newComment.count;
				}
				return p;
			});
			const lastphotos = homeData.posts_photo.map((p) => {
				if (p.id == newComment.post && p.info) {
					p.info.comentarios += newComment.count;
				}
				return p;
			});
			setHomeData({
				...homeData,
				posts_photo: lastphotos,
				posts_video: lastvideos,
			});
			setPosts(upd);
			setNewComment(null);
		}
	}, [newComment]);

	useEffect(() => {
		const getData = async () => {
			try {
				setLoadingConnected(true);
				setLoading(true);
				const fantasies = await getFantasies();
				setFantasies(fantasies.info.data[0]);
				const reactionType = await getReactionTypes();
				setReactionType(reactionType?.id ?? 0);
				fetchPosts();
				const response = await getHome();
				setLoading(false);
				if (response.info?.data[0]?.home) setHomeData(response.info.data[0].home);

				const data = await getUsers(1, {
					status: "connected",
				});
				if (data.info?.data[0]) {
					setOnlineUsers(data.info?.data[0]?.data);
					setTotalOnlineUsers(data.info?.data[0].total);
				}
				// console.log("connected", data);
				setLoadingConnected(false);
			} catch (error) {
				toast.error("Ha ocurrido un error cargando los datos");
				setLoadingConnected(false);
			}
		};
		getData();
	}, []);

	const getFantasyName = (id?: number) => {
		if (fantasies.length) {
			return fantasies.find((e) => e.id == id)?.name;
		}
		return undefined;
	};

	return (
		<>
			<NavBar />
			<main className="home sm:p-4 pt-0 lg:pt-5 lg:px-10 flex w-full items-center flex-col fixed top-[65px] hide-scroll">
				<div className="max-w-8xl w-full">
					<div className="flex justify-between sm:gap-2 2xl:gap-6 overflow-hidden w-full">
						<div className="hidden lg:block w-4/12 lg:w-[243px] max-h-main hide-scroll pb-5">
							<MyAbout
								visits={homeData.global?.visitas}
								friends={homeData.global?.amigos}
								likes={homeData.global?.reacciones}
							/>
							<MyFriends friends={homeData.amigos} />
							<section className="mt-10">
								<Link
									to={"/profiles?online=true"}
									className="font-bold text-[16px] text-black hover:text-primary transition-colors-opacity"
								>
									Usuarios en linea {!loadingConnected ? `(${totalOnlineUsers})` : ""}
								</Link>
								<ul className="mt-6 flex flex-col gap-3">
									{loadingConnected ? (
										[1, 2].map((e) => (
											<div key={e} color="primary">
												<div className=" py-1 my-1 flex items-center gap-3">
													<div>
														<Skeleton className="flex rounded-full w-10 h-10" />
													</div>
													<div className="w-full flex flex-col gap-2">
														<Skeleton className="h-2.5 w-[40%] rounded-lg" />
														<Skeleton className="h-2.5 w-[80%] rounded-lg" />
													</div>
												</div>
											</div>
										))
									) : onlineUsers.length > 0 ? (
										onlineUsers.map((e) => (
											<li key={e.id} className="flex gap-3">
												<Link to={`/profile/${e?.id}`}>
													<Avatar
														showFallback
														classNames={{
															base: "bg-zinc-100",
															icon: "text-black/50",
															name: "text-black/50 capitalize font-bold text-[16px]",
														}}
														src={
															e?.profile_path
																? e?.profile_path.startsWith("http")
																	? e?.profile_path
																	: import.meta.env.VITE_BASE_URL + e?.profile_path
																: undefined
														}
													/>
													<div
														className={
															"relative -top-3 -right-8 w-3 h-3 rounded-full" +
															(e.status == "disconnected"
																? ""
																: " border-2 border-white bg-green-500")
														}
													></div>
												</Link>
												<div className="flex flex-col">
													<label className="font-medium text-[14px] text-color2">
														<Link className="hover:text-primary" to={`/profile/${e?.id}`}>
															{e.name}
														</Link>
													</label>
													<span className="font-mormal text-[14px] text-color5">
														{e.status == "disconnected" ? "Desconectado" : "En línea"}
													</span>
												</div>
											</li>
										))
									) : (
										<li>
											<div className="flex flex-col">
												<span className="font-mormal text-[14px] text-color1">
													No hay usuarios en línea..
												</span>
											</div>
										</li>
									)}
								</ul>
							</section>
							<section className="mt-6">
								<h3 className="font-bold text-[16px] text-black">Próximas Fiestas</h3>
								<ul className="mt-6 flex flex-col gap-8">
									{homeData.fiestas?.map((party) => (
										<li key={party.id}>
											<article className="max-w-[291px]">
												<div className="flex justify-between items-center">
													<h2 className="font-semibold text-[18px] text-color3">
														{party.nombre}
													</h2>
													<div className="min-w-[90px] font-medium text-[12px] text-color4 rounded-2xl border-1 border-gray-300 pl-2 pr-2 pb-0.5 pt-0.5 bg-color8">
														{party.integrantes?.length ?? 0}/
														{party.capacidad == 0 ? "∞" : party.capacidad} Cupos
													</div>
												</div>
												<div className="mt-0.5">
													<p className="font-normal text-[14px] text-color5">
														{party.descripcion.slice(0, 300)}...
													</p>
												</div>
												<div className="mt-4">
													<Link to={`/parties/${party.id}`}>
														<PrimaryButton>Más Información</PrimaryButton>
													</Link>
												</div>
												<hr className="mt-5 border-gray-300"></hr>
											</article>
										</li>
									))}
								</ul>
								{(homeData.fiestas?.length == 0 || !homeData.fiestas) && (
									<p>No hay fiestas próximamente</p>
								)}
							</section>
						</div>
						<div className=" flex-1 min-w-0 flex justify-center w-full hide-scroll">
							<div
								id="scrollableDiv"
								className=" Xbg-color9 flex-1 min-w-0 bg-zinc-100 border-1 border-zinc-100 shadow-inner  rounded-3xl  w-full p-4 md:p-6 lg:ml-5 max-h-main flex flex-col gap-6 hide-scroll"
							>
								<NewPostCard
									onlineUsers={totalOnlineUsers.toString()}
									globalData={{
										visits: homeData.global?.visitas,
										friends: homeData.global?.amigos,
										reactions: homeData.global?.reacciones,
									}}
								/>
								<div className="w-full flex-1 min-w-0 bg-[#F0E3FF] min-h-64 lg:min-h-80 py-5 rounded-2xl">
									<div className="flex items-center gap-2 px-5">
										<NewsIcon />
										<span className="font-medium">Novedades</span>
									</div>
									<div className="flex-1 min-w-0">
										<NewsContent isLoadingPosts={loading} users={homeData.nuevos_usuarios} />
									</div>
								</div>
								<InfiniteScroll
									dataLength={posts.length}
									next={fetchPosts}
									hasMore={hasMore}
									loader={
										<div className="w-full">
											<PostSkeleton />
										</div>
									}
									scrollableTarget="scrollableDiv"
									endMessage={
										<p style={{ textAlign: "center" }}>
											<b>Yay! Parece que has visto todas las publicaciones</b>
										</p>
									}
								>
									{posts.map((e, idx) => (
										<Post
											key={e.id + " - " + idx}
											post={e}
											className="p-5 md:p-10 bg-white rounded-2xl mb-10"
											fantasy={e.isFantasia ? getFantasyName(e.fantasia_id) : undefined}
										/>
									))}
								</InfiniteScroll>
							</div>
						</div>
						<div className="hidden xl:block ml-5  w-4/12 lg:w-[243px] max-h-main hide-scroll pb-5">
							{homeData.visitantes.length > 0 && <LastVisits lastVisits={homeData.visitantes} />}

							{onlineUsers.length > 0 && (
								<LastVisits
									title={"Usuarios en linea " + (!loadingConnected ? `(${totalOnlineUsers})` : "")}
									lastVisits={onlineUsers}
								/>
							)}
							{/* <NewUsers newUsers={homeData.nuevos_usuarios} /> */}

							{homeData.posts_photo.length > 0 && <LastPhotos lastPhotos={homeData.posts_photo} />}
							{homeData.posts_video.length > 0 && <LastVideos videos={homeData.posts_video} />}
						</div>
					</div>
				</div>
			</main>

			<VerificationModal
				isOpen={!authData?.verified && (!!authData?.info?.verification_path || authData?.role_id == "3")}
			/>
			{/* TODO:  uncoment this when payment integration its done */}
			{/* <PlanModal  
        isOpen={!!authData?.verified && !authData?.subscription_payed}
      /> */}
		</>
	);
}
