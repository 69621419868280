import { Input } from "@nextui-org/react";
import { FormEvent, useEffect, useState } from "react";
import VisibilityState from "./VisibilityState";

export default function CustomInput({
  type = "text",
  required = false,
  label = "Input",
  placeholder = "Input placeholder",
  name = "input",
  id,
  className,
  minLength = 0,
  errorMessage = "Este campo es requerido",
  startIcon = null,
  endContent = null,
  value = "",
  onChange = () => {},
  onVisibilityChange = () => {},
  onValidate,
  visibilityAll = false,
  showVisibility = false,
  visibility_name = "",
  labelPlacement = "outside",
  isInvalid = false,
  visibilityValue,
}: {
  type?: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  className?: string;
  minLength?: number;
  errorMessage?: string;
  startIcon?: React.ReactNode;
  endContent?: React.ReactNode;
  value?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  onVisibilityChange?: (value: string) => void;
  onValidate?: (value: string) => boolean;
  visibility_name?: string;
  visibilityAll?: boolean;
  showVisibility?: boolean;
  labelPlacement?: "outside" | "inside";
  isInvalid?: boolean;
  visibilityValue?: string;
}) {
  const [isValid, setIsValid] = useState(!isInvalid);
  const [error, setError] = useState(errorMessage);

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    if (!data.value && required) {
      setIsValid(false);
      setError("Este campo es requerido");
    } else if (
      type == "email" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.value)
    ) {
      setError("Introduce un email valido");
      setIsValid(false);
    } else if (onValidate && !onValidate(data.value)) {
      setError(errorMessage);
      setIsValid(false);
    } else {
      setIsValid(true);
      setError(errorMessage);
    }
    onChange(e);
  };

  useEffect(() => {
    // console.log(isInvalid);
    setIsValid(!isInvalid);
    setError(errorMessage);
  }, [isInvalid]);

  return (
    <div>
      <Input
        startContent={startIcon}
        minLength={minLength}
        errorMessage={error}
        className={className}
        isInvalid={!isValid}
        variant="bordered"
        isRequired={required}
        type={type}
        label={label}
        labelPlacement={labelPlacement}
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={handleChangeInput}
        endContent={endContent}
        classNames={{
          label:
            "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
          inputWrapper: "h-[44px] rounded-lg border-small ",
          input:
            "text-[16px] font-normal text-color1 placeholder:text-color1 border-0 focus:ring-1 focus:ring-transparent",
        }}
      />
      <div hidden={!showVisibility} className="-mt-2.5">
        <VisibilityState
          onVisibilityChange={onVisibilityChange}
          visibilityAll={visibilityAll}
          visibility_name={visibility_name}
          visibilityValue={visibilityValue}
        />
      </div>
    </div>
  );
}
