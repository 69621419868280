/* eslint-disable no-mixed-spaces-and-tabs */
import { Button, Image, Spinner, Tooltip } from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import Post from "../components/post/Post";
import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { useHomeStore } from "../stores/homeData";
// import { User } from "../interfaces/Login";
import { Post as Publication } from "../interfaces/Post";
import { NewPostCard } from "../components/post/NewPostCard";
import { useParams } from "react-router-dom";
import { getFantasiesPublications } from "../api/publication";
import { getFantasy } from "../api/formSelectsData";
import InfiniteScroll from "react-infinite-scroll-component";
import LastPhotos from "../components/home/LastPhotos";
import LastVideos from "../components/home/LastVideos";
// import NewUsers from "../components/home/NewUsers";
import { Fantasy } from "../interfaces/Fantasy";
import { StarIcon } from "../assets/icons/StarIcon";
import { getInfoPayload, useRegisterStore } from "../stores/registerData";
import { saveUserInfo } from "../api/info";
import { toast } from "react-toastify";
import { withErrorHandler } from "../utils/withErrorFallback";
import LightBoxImage from "../components/common/LightBoxImage";

export default function FantasyView() {
	const { fantasyId } = useParams();
	const authData = useAuthStore((state) => state.user);
	// const homeData = useHomeStore((state) => state.home);

	const [posts, setPosts] = useState<Publication[]>([]);
	const [lastPhotos, setLastPhotos] = useState<Publication[]>([]);
	const [lastVideos, setLastVideos] = useState<Publication[]>([]);
	const [fantasyData, setFantasyData] = useState<Fantasy | undefined>();
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [checked, setCheck] = useState(false);
	const [isOpenLightbox, setIsOpenLightbox] = useState(false);

	const newPost = useHomeStore((state) => state.newFantasyPost);
	const setNewPost = useHomeStore((state) => state.setNewFantasyPost);

	const setAuthData = useAuthStore((state) => state.saveUser);
	// all steps data
	const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
	const stepTwoData = useRegisterStore((state) => state.stepTwoData);
	const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
	const setStepFourData = useRegisterStore((state) => state.setStepFourData);

	const fetchPosts = withErrorHandler(async () => {
		// console.log(fantasyId);
		const exclude = posts.map((e) => e.id);

		const response = await getFantasiesPublications(exclude.length ? exclude.join(",") : "0", fantasyId);
		//console.log("Dataaaaaa", response.info.data.sidebar);
		if (!response.info.data[0].length) setHasMore(false);
		else setPosts([...posts, ...response.info.data[0]]);

		if (response?.info?.data?.sidebar) {
			setLastPhotos(response?.info?.data?.sidebar.posts_photos);
			setLastVideos(response?.info?.data?.sidebar.posts_videos);
		}
	});

	useEffect(() => {
		// console.log(authData?.info?.fantasias_id);
		const getData = async () => {
			setIsLoading(true);
			if (fantasyId) {
				setCheck(!!fantasyId && !!authData?.info?.fantasias_id?.split(",").includes(fantasyId));
				const fantasyRes = await getFantasy(parseInt(fantasyId));
				if (fantasyRes) {
					setFantasyData(fantasyRes.info.data[0]);
					// console.log(fantasyRes);
				}
			}
			if (authData?.info?.detalles) {
				const details = {
					...authData?.info?.detalles,
					is_couple: !!authData.info.is_couple,
					fantasias_id: authData.info.fantasias_id,
					provincia_id: authData.info.provincia_id,
				};
				setStepTwoData(details);
			}
			if (authData?.info?.como_soy) setStepThreeData(authData?.info?.como_soy);

			if (authData?.info?.otros) {
				const others = {
					...authData?.info?.otros,
					idiomas_id: authData?.info?.idiomas_id?.toString(),
					horoscopo_id: authData?.info?.horoscopo_id?.toString(),
					profesion_id: authData?.info?.profesion_id?.toString(),
					partner_idioma_id: authData?.info?.otros?.partner_idioma_id,
				};
				setStepFourData(others);
			}
			await fetchPosts();
			setIsLoading(false);
		};
		getData();
	}, []);

	useEffect(() => {
		if (newPost) {
			setPosts([newPost, ...posts]);
			return () => {
				setNewPost(undefined);
			};
		}
	}, [newPost]);

	const handleLike = withErrorHandler(async () => {
		if (!fantasyId) return;
		const arr = authData?.info?.fantasias_id?.split(",");

		if (arr?.includes(fantasyId)) {
			arr.filter((e) => e != fantasyId);
			const details = {
				...stepTwoData,
				fantasias_id: arr.join(","),
			};
			setStepTwoData(details);
			// console.log(arr);
		} else if (arr) {
			arr.push(fantasyId);
			const details = {
				...stepTwoData,
				fantasias_id: arr.join(","),
			};
			setStepTwoData(details);
		}
		const data = getInfoPayload(authData?.info?.is_couple ? 1 : 0, authData?.id);
		const response = await saveUserInfo(data);
		if (response.error == "true") {
			toast.error(response.info.message);
		} else {
			//toast.success("Las fantasias  correctamente");
			setAuthData(response.info.data[0]);
			// console.log(response.info.data[0]);
		}
	});
	const handleChack = () => {
		setCheck(!checked);
		handleLike();
	};
	const [showMore, setShowMore] = useState(false);
	return (
		<div className="contenedor h-screen">
			<NavBar />
			<main className=" sm:p-4 pt-0 lg:px-10 flex w-full items-center flex-col ">
				<div className="max-w-8xl w-full">
					{isLoading ? (
						<div className="w-full flex justify-center">
							<Spinner color="primary" />
						</div>
					) : (
						<>
							{fantasyData && (
								<div className="px-10 mb-8 flex gap-8">
									<div className="size-28">
										<div className="relative w-28 h-28">
											<Image
												alt={fantasyData.name}
												fallbackSrc="/assets/fantasyImage.jpeg"
												src={
													fantasyData.avatar
														? fantasyData.avatar?.startsWith("http")
															? fantasyData.avatar
															: import.meta.env.VITE_BASE_URL +
															  "storage/" +
															  fantasyData.avatar
														: undefined
												}
												className="w-28 h-28 object-cover rounded-none"
												onClick={() => setIsOpenLightbox(true)}
												isZoomed
											/>
										</div>
									</div>
									<div className="w-full">
										<div className="flex mb-3">
											<Tooltip content="Añadir a mis fantasías" placement="bottom">
												<Button
													className={`bg-color8/70 mr-3 like ${checked ? "checked" : ""}`}
													variant="flat"
													isIconOnly
													radius="sm"
													aria-label="like"
													onClick={handleChack}
												>
													<div className="checkmark flex items-center justify-center">
														<StarIcon height={24} width={24} />
													</div>
												</Button>
											</Tooltip>
											<h1 className="text-3xl font-semibold text-color2">{fantasyData?.name}</h1>
										</div>
										<p>
											{fantasyData?.description.length <= 400 || showMore
												? fantasyData?.description
												: fantasyData?.description.slice(0, 400) + "..."}{" "}
											{fantasyData?.description.length > 400 && (
												<span
													onClick={() => setShowMore(!showMore)}
													className="cursor-pointer text-primary ml-2 text-nowrap"
												>
													{showMore ? "Ver menos" : "Ver más"}
												</span>
											)}
										</p>
									</div>
								</div>
							)}
							<div className="flex w-full justify-between sm:gap-2 2xl:gap-6 overflow-hidden scrollbar-hide">
								<div className="flex-grow flex justify-center w-full hide-scroll">
									<div
										id="scrollableDiv"
										className=" Xbg-color9 bg-zinc-100 border-1 border-zinc-100 shadow-inner  rounded-3xl  w-full p-4 md:p-8 lg:ml-5 max-h-main flex flex-col gap-8 hide-scroll"
									>
										<NewPostCard isFantasy={1} />

										<InfiniteScroll
											dataLength={posts.length}
											next={fetchPosts}
											hasMore={hasMore}
											loader={
												<div className="w-full flex justify-center">
													<Spinner color="primary" />
												</div>
											}
											scrollableTarget="scrollableDiv"
											endMessage={
												<p style={{ textAlign: "center" }}>
													<b>Yay! Parece que has visto todas las publicaciones</b>
												</p>
											}
										>
											{posts.map((e, idx) => (
												<Post
													key={e.id + " - " + idx}
													post={e}
													className="p-10 bg-white rounded-3xl mb-10"
												/>
											))}
										</InfiniteScroll>
									</div>
								</div>

								<div className="hidden xl:block ml-6  w-4/12 max-h-main max-h-main-fantasy">
									{lastPhotos && <LastPhotos lastPhotos={lastPhotos} />}
									{lastVideos && <LastVideos videos={lastVideos} />}
								</div>
							</div>
						</>
					)}
				</div>
			</main>
			<LightBoxImage
				src={
					fantasyData?.avatar
						? fantasyData.avatar?.startsWith("http")
							? fantasyData.avatar
							: import.meta.env.VITE_BASE_URL + "storage/" + fantasyData.avatar
						: ""
				}
				isOpen={isOpenLightbox}
				type="image"
				poster={
					fantasyData?.avatar
						? fantasyData.avatar?.startsWith("http")
							? fantasyData.avatar
							: import.meta.env.VITE_BASE_URL + "storage/" + fantasyData.avatar
						: ""
				}
				closeModal={() => setIsOpenLightbox(false)}
			/>
		</div>
	);
}
