import { User } from "./Login";

export interface ChatsResponse {
  id: number;
  tipo: string;
  name: {
    [key: number]: string;
  };
  created_at: string;
  lastMessage: Message;
  integrantes?: ChatMember[];
  // extra stuff for new chats
  isNewChat?: boolean;
}

export interface Chat {
  id: number;
  tipo: string;
  created_at: string;
  messages: MessageData;
  integrantes: ChatMember[];
}
export interface MessageData {
  data: Message[];
  current_page?: number;
  per_page?: number;
  to?: number;
  total?: number;
}

export interface Message {
  id: number;
  user_id: number;
  chat_id: number;
  content: string;
  media: string;
  user: User;
  created_at: string;
  file?: File;
  toUser?: number;
  uuid?: string;
  seen?: boolean;
}

export interface ChatMember {
  id: number;
  user_id: number;
  accepted: boolean;
  user: User;
}

export const createFakeChat = (user: User, me: User): ChatsResponse => {
  const date = new Date().toISOString();
  const id = Math.floor(Math.random() * 100000);
  return {
    id: id,
    tipo: "chat",
    name: {
      [user.id]: user.name,
      [me.id]: me.name,
    },
    created_at: date,
    lastMessage: {
      id: id,
      user_id: me.id,
      chat_id: id,
      content: "",
      media: "text",
      user: me,
      created_at: date,
      uuid: id.toString(),
    },
    integrantes: [
      {
        id: me.id,
        user_id: me.id,
        accepted: true,
        user: me,
      },
      {
        id: user.id,
        user_id: user.id,
        accepted: true,
        user: user,
      },
    ],
    isNewChat: true,
  };
};
