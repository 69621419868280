import { FormEvent, useEffect, useReducer, useState } from "react";
import { LocalData } from "../../stores/initialStates";
import Logo from "../common/Logo";
import PrimaryButton from "../common/PrimaryButton";
import CustomInput from "../form/CustomInput";
import { CustomMapControl } from "../trip/map-control";
import MapHandler from "../trip/map-handler";
import { Map, ControlPosition, Marker } from "@vis.gl/react-google-maps";

const initialErrors = {
  owners_name: false,
  locals_name: false,
  movil: false,
  CIF: false,
  address: false,
};

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };
export default function LocalInfoForm({
  localData,
  isEditing = false,
  loading,
  handleSubmit,
  setLocalData,
}: {
  localData: LocalData;
  isEditing?: boolean;
  loading: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  setLocalData: (value: LocalData) => void;
}) {
  //Validations
  const [errors, setErrors] = useState(initialErrors);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setLocalData({
      ...localData,
      [target.name]: target.value,
    });
  };

  const [customSearch, setCustomSearch] = useState("");

  const [center, setCenter] = useState(INITIAL_CENTER);
  const [initialCenter, setInitialCenter] = useState(INITIAL_CENTER);
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const validate = () => {
    let isValid = true;

    // setMessagesErrors(initialMessages);
    let finalErrors = initialErrors;
    const arr = Object.keys(initialErrors);
    arr.forEach((name) => {
      switch (name) {
        case "owners_name":
        case "locals_name":
        case "movil":
        case "CIF":
          if (!localData[name]) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        case "address":
          if (!customSearch)
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          break;
        default:
          break;
      }
    });
    setErrors(finalErrors);
    forceUpdate();
    console.log(errors);
    return isValid;
  };

  const handleValidate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    // console.log(localData);
    handleSubmit(e);
  };
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setIsLoadingMap(true);
      // console.log(localData.ubicacion);
      if (localData.direccion) {
        setCustomSearch(localData.direccion);
        // console.log("localData.direccion initial", localData.direccion);
      }
      if (localData.ubicacion) {
        const aux = localData.ubicacion?.split("|");

        if (aux.length == 2) {
          console.warn("heeeeeeereee is", aux[0]?.toString());
          const lat = parseFloat(aux[0]?.toString());
          const lng = parseFloat(aux[1]?.toString());
          // console.log(lat, lng);
          setCenter({ lat: lat, lng: lng });
          setInitialCenter({ lat: lat, lng: lng });
          const auxPlace = await getPlaceFormated(lat, lng);
          if (auxPlace && !localData.direccion) setCustomSearch(auxPlace);
        }
        forceUpdate();
      }
      setIsLoadingMap(false);
    };
    getData();

    // console.log(initialCenter, center);
  }, []);

  useEffect(() => {
    if (
      selectedPlace?.geometry?.location?.lat() &&
      selectedPlace?.geometry?.location?.lng()
    ) {
      setCenter({
        lat: selectedPlace?.geometry?.location?.lat(),
        lng: selectedPlace?.geometry?.location?.lng(),
      });
    }
    if (selectedPlace?.formatted_address)
      setCustomSearch(selectedPlace.formatted_address);
  }, [selectedPlace]);

  useEffect(() => {
    setLocalData({
      ...localData,
      direccion: customSearch,
      ubicacion: `${center.lat}|${center.lng}`,
    });
    // console.log("localData.direccion change", localData.direccion);
  }, [customSearch, center]);

  const getPlaceByLatLng = async (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();
    const results = await geocoder.geocode({ location: { lat, lng } });
    if (results) return results;
  };

  const getPlaceFormated = async (lat: number, lng: number) => {
    const place = await getPlaceByLatLng(lat, lng);

    // console.log(place);
    return place?.results[0].formatted_address;
  };

  return (
    <div className="w-full flex max-h-[96vh] overflow-y-auto justify-center">
      <form
        onSubmit={handleValidate}
        className={`w-full h-max text-center ${
          isEditing ? "max-w-screen-md" : "max-w-[360px]"
        }`}
      >
        {!isEditing && (
          <div className="flex justify-center">
            <Logo />
          </div>
        )}
        <h1 className="mt-6 font-semibold text-[30px] ">Datos del Local</h1>
        <p className="mt-2 font-normal text-color1 text-[16px]">
          {isEditing ? "Edita" : "Completa"} la información de tu local
        </p>
        <div className="w-full flex-row gap-4 flex ">
          <div className="w-full min-h-[230px]  bg-zinc-300 flex items-center justify-center rounded-xl border-2 border-color4 text-primary cursor-pointer overflow-hidden">
            {isLoadingMap ? (
              <p>Cargando...</p>
            ) : (
              <Map
                className=""
                defaultCenter={initialCenter}
                defaultZoom={10}
                scrollwheel={false}
                zoomControl={true}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                onClick={async (e) => {
                  // console.log(e.detail.latLng);
                  if (e.detail.latLng) {
                    const pos = e.detail.latLng;
                    setCenter(pos);
                    const res = await getPlaceFormated(pos.lat, pos.lng);
                    setCustomSearch(res ?? "");
                  }
                }}
              >
                <Marker
                  position={center}
                  draggable
                  onDragEnd={async (e) => {
                    const res = await getPlaceFormated(
                      e.latLng?.lat() ?? 0,
                      e.latLng?.lng() ?? 0
                    );
                    setCustomSearch(res ?? "");
                  }}
                  onDrag={(e) =>
                    setCenter({
                      lat: e.latLng?.lat() ?? 0,
                      lng: e.latLng?.lng() ?? 0,
                    })
                  }
                />
                <CustomMapControl
                  controlPosition={ControlPosition.TOP}
                  onPlaceSelect={setSelectedPlace}
                  customValue={customSearch}
                />
                <MapHandler place={selectedPlace} />
              </Map>
            )}
          </div>
          {errors.address && (
            <p className="text-sm text-red-600">
              Por favor selecciona una ubicación
            </p>
          )}
        </div>
        <CustomInput
          required
          type="text"
          name="owners_name"
          label="Nombre del dueño"
          placeholder="Introduce el nombre del dueño"
          className="pt-[28px]"
          errorMessage="Por favor introduzca el nombre"
          value={localData.owners_name}
          onChange={handleChange}
          isInvalid={errors.owners_name}
        />
        <CustomInput
          required
          type="text"
          name="locals_name"
          label="Nombre del local"
          placeholder="Introduce el nombre del local"
          className="pt-[20px]"
          errorMessage="Por favor introduzca el nombre"
          value={localData.locals_name}
          onChange={handleChange}
          isInvalid={errors.locals_name}
        />
        <CustomInput
          required
          type="phone"
          name="movil"
          label="Teléfono"
          placeholder="Introduce el teléfono"
          className="pt-[20px]"
          errorMessage="Por favor introduzca el teléfono"
          value={localData.movil}
          onChange={handleChange}
          isInvalid={errors.movil}
        />
        <CustomInput
          required
          type="text"
          name="CIF"
          label="Cif nif"
          placeholder="Introduce el cif nif"
          className="pt-[20px]"
          errorMessage="Por favor introduzca el cif nif"
          value={localData.CIF}
          onChange={handleChange}
          isInvalid={errors.CIF}
        />

        <PrimaryButton
          type="submit"
          loading={loading}
          className="mt-[20px] w-full max-w-lg"
        >
          {isEditing ? "Guardar" : " Completar registro"}
        </PrimaryButton>
      </form>
    </div>
  );
}
