import { useState } from "react";

// Interfaz para las props del componente
export interface Fantasy {
	id: number;
	name: string;
	description?: string;
	status?: string;
	avatar?: string;
}

interface CustomSearchableSelectProps {
	info: Fantasy[]; // Lista genérica de opciones
	handleChangeSelect: (selected: number[]) => void; // Callback al seleccionar
	data: { fantasias: number[] }; // Valores seleccionados
}

const CustomSearchableSelect: React.FC<CustomSearchableSelectProps> = ({ info, handleChangeSelect, data }) => {
	const [isOpen, setIsOpen] = useState(false); // Estado para abrir/cerrar el menú
	const [search, setSearch] = useState<string>(""); // Estado para el término de búsqueda
	const [selectedValues, setSelectedValues] = useState<number[]>(data.fantasias || []);

	// Filtrar opciones basadas en el término de búsqueda

	const filteredOptions = info.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));

	// Manejar selección
	const handleSelect = (id: number) => {
		const updatedValues = selectedValues.includes(id)
			? selectedValues.filter((value) => value !== id)
			: [...selectedValues, id];

		setSelectedValues(updatedValues);
		handleChangeSelect(updatedValues); // Notifica al padre los cambios
	};

	return (
		<div className="w-full mt-1 relative">
			<div
				onClick={() => setIsOpen((prev) => !prev)}
				className="w-full h-[44px] border rounded-lg flex items-center px-3 cursor-pointer bg-white"
			>
				{selectedValues.length > 0
					? selectedValues.map((id) => info.find((item) => item.id === id)?.name).join(", ")
					: "Seleccionar..."}
				<span className="ml-auto">▼</span>
			</div>

			{isOpen && (
				<div
					className="absolute z-50 w-full bg-white border rounded-lg mt-1 shadow-lg"
					style={{ maxHeight: "200px", overflowY: "auto" }}
				>
					<div className="p-2">
						<input
							type="text"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Buscar..."
							className="w-full p-2 border rounded-lg"
						/>
					</div>
					<ul className="p-2">
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option) => (
								<li
									key={option.id}
									onClick={() => handleSelect(option.id)}
									className={`p-2 cursor-pointer rounded-lg ${
										selectedValues.includes(option.id) ? "bg-primary-200" : "hover:bg-primary-100"
									}`}
								>
									{option.name}
								</li>
							))
						) : (
							<li className="p-2 text-center text-gray-500">Sin resultados</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default CustomSearchableSelect;
