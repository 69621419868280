import { Chip } from "@nextui-org/react";
// import SecondaryButton from "../common/SecondaryButton";
import PostCard from "../post/Post";
import { ActivityPost, Post } from "../../interfaces/Post";
// import { useRef } from "react";
// import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/icons/ArrowIcon";
import PostSkeleton from "../post/PostSkeleton";

export default function ProfilePosts({
	isLoadingPosts,
	posts,
}: {
	isLoadingPosts: boolean;
	posts: ActivityPost[] | Post[];
}) {
	// const containerPostsRef = useRef(null);
	// const handleScrollLeftPosts = () => {
	//   if (containerPostsRef.current) {
	//     (containerPostsRef.current as HTMLDivElement).scrollLeft -= 360;
	//   }
	// };
	// const handleScrollRightPosts = () => {
	//   if (containerPostsRef.current) {
	//     (containerPostsRef.current as HTMLDivElement).scrollLeft += 360;
	//   }
	// };
	return (
		<div className="mt-2 flex flex-col items-center w-full pl-4">
			{/* ref={containerPostsRef} */}
			<div className="flex scroll-smooth justify-start w-full ">
				<div className="mt-4 py-3 flex flex-col gap-5 w-full  ">
					{isLoadingPosts ? (
						<div className="w-full">
							<PostSkeleton />
						</div>
					) : (
						posts.map((e, i) => (
							<PostCard
								key={i}
								post={e}
								className="p-4 md:p-6  border-1 rounded-2xl"
								slot={
									"tipo" in e ? (
										<>
											<Chip
												variant="flat"
												color="default"
												className={`hidden md:flex mt-2 font-semibold  items-center ${
													e.tipo == "reacciones"
														? "bg-pink-500/30 text-pink-500"
														: e.tipo == "publicaciones"
														? "bg-primary/30 text-primary"
														: "bg-blue-400/30 text-blue-500"
												}`}
											>
												<span className=" font-semibold capitalize ">{e.tipo}</span>
											</Chip>
										</>
									) : (
										<></>
									)
								}
								slotDescription={
									"tipo" in e ? (
										<p className="font-medium mt-3">
											<span className="w-full ">
												{e.tipo == "reacciones"
													? "Reaccionó a esta publicación ❤️"
													: e.tipo == "publicaciones"
													? "Nueva publicación ✨"
													: "Comentó esta publicación 💭"}
											</span>
										</p>
									) : (
										<></>
									)
								}
							/>
						))
					)}
				</div>
			</div>
			{/* <div className="w-full flex justify-center gap-8 mt-8 md:mt-14">
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollLeftPosts}
        >
          <ArrowLeftIcon />
        </SecondaryButton>
        <SecondaryButton
          className="font-semibold text-[14px] text-color4"
          onClick={handleScrollRightPosts}
        >
          <ArrowRightIcon />
        </SecondaryButton>
      </div> */}
		</div>
	);
}
