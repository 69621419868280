import React from "react";

const TatuajesIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 48 48">
		<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
			<path d="M6 44V15c0-1.5 1-5.2 5-8L8.5 4c1.667 0 5.6.6 8 3c4.167-.333 13.5 2 10 8c-.89 1.335-2.5 2-5.5 2" />
			<path d="M19 24c4 0 12 5 12 14c0 2 1 4.883 5.134 4.401C39 42.067 41.627 40.052 43 35.877" />
			<path d="M17 44c0-4 2-9 10-9h3" />
		</g>
	</svg>
);

export default TatuajesIcon;
