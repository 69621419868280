import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import Stepper from "../common/Stepper";
import { useStepperStore } from "../../stores/stepperStore";
import CustomSelect from "../form/CustomSelect";
import { FormEvent, useReducer, useState } from "react";

import { getInfoPayload, useRegisterStore } from "../../stores/registerData";
import Logo from "../common/Logo";
import {
  races,
  complexions,
  eyesColor,
  hairColors,
  pubicHairCut,
  noseTypes,
  skincolors,
  piercingCount,
  tattoosCount,
  drink,
  smoke,
  hairCuts,
  lipsOpt,
} from "../../utils/selectOptions";
import CustomInput from "../form/CustomInput";
import { toast } from "react-toastify";
import { saveUserInfo } from "../../api/info";
import { useAuthStore } from "../../stores/authStore";

type VisibilityNm =
  | "race"
  | "partner_race"
  | "weight"
  | "partner_weight"
  | "height"
  | "partner_height"
  | "complexion"
  | "partner_complexion"
  | "eye_color"
  | "partner_eye_color"
  | "hair_color"
  | "partner_hair_color"
  | "hair_length"
  | "partner_hair_length"
  | "type_of_nose"
  | "partner_type_of_nose"
  | "lips"
  | "partner_lips"
  | "skin_color"
  | "partner_skin_color"
  | "pubic_hair_cut"
  | "partner_pubic_hair_cut"
  | "piercings"
  | "partner_piercings"
  | "tattoos"
  | "partner_tattoos"
  | "you_drink"
  | "partner_you_drink"
  | "you_smoke"
  | "partner_you_smoke";
const regex = /^[1-9]\d*$/;
const initialErrors = {
  race: false,
  partner_race: false,
  height: false,
  partner_height: false,
  weight: false,
  partner_weight: false,
  complexion: false,
  partner_complexion: false,
  eye_color: false,
  partner_eye_color: false,
  hair_color: false,
  partner_hair_color: false,
  hair_length: false,
  partner_hair_length: false,
  type_of_nose: false,
  partner_type_of_nose: false,
  skin_color: false,
  partner_skin_color: false,
  pubic_hair_cut: false,
  partner_pubic_hair_cut: false,
  piercings: false,
  partner_piercings: false,
  tattoos: false,
  partner_tattoos: false,
  you_drink: false,
  partner_you_drink: false,
  you_smoke: false,
  partner_you_smoke: false,
  lips: false,
  partner_lips: false,
};

export default function ProfileFourthStepForm({
  participants = 1,
  backStep,
  isEditing = false,
}: {
  participants?: number;
  backStep: () => void;
  isEditing?: boolean;
}) {
  const step = useStepperStore((state) => state.step);
  const setStep = useStepperStore((state) => state.setStep);

  const stepTwoData = useRegisterStore((state) => state.stepTwoData);

  const stepThreeData = useRegisterStore((state) => state.stepThreeData);
  const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
  const saveUser = useAuthStore((state) => state.saveUser);
  const authData = useAuthStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const validate = () => {
    let isValid = true;
    let finalErrors = initialErrors;
    const arr = Object.keys(initialErrors);
    arr.forEach((name) => {
      switch (name) {
        case "height":
        case "weight":
          if (
            !stepThreeData[name]?.value ||
            !regex.test(stepThreeData[name]?.value)
          ) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        case "race":
        case "complexion":
        case "eye_color":
        case "hair_color":
        case "hair_length":
        case "type_of_nose":
        case "skin_color":
        case "pubic_hair_cut":
        case "piercings":
        case "tattoos":
        case "you_drink":
        case "you_smoke":
        case "lips":
          if (!stepThreeData[name]?.value) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        case "partner_height":
        case "partner_weight": {
          const value = stepThreeData[name]?.value;

          if (participants == 2 && (!value || (value && !regex.test(value)))) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        }
        case "partner_race":
        case "partner_complexion":
        case "partner_eye_color":
        case "partner_hair_color":
        case "partner_hair_length":
        case "partner_type_of_nose":
        case "partner_skin_color":
        case "partner_pubic_hair_cut":
        case "partner_piercings":
        case "partner_tattoos":
        case "partner_you_drink":
        case "partner_you_smoke":
        case "partner_lips":
          if (!stepThreeData[name]?.value && participants == 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: true,
            };
          }
          break;
        default:
          break;
      }
    });
    setErrors(finalErrors);
    forceUpdate();
    return isValid;
  };
  const isValidateInt = (value?: string) => {
    if (!value || !regex.test(value)) return false;
    return true;
  };

  const setFormData = (name: VisibilityNm, value: string) => {
    setStepThreeData({
      ...stepThreeData,
      [name]: {
        value: value,
        visibility: stepThreeData[name]!.visibility,
      },
    });
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name as VisibilityNm, data.value);
  };

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name as VisibilityNm, data.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("stepThreeData", stepThreeData);
    const isValid = validate();
    console.log(errors);
    if (!isValid) return;
    const submitter = (e.nativeEvent as SubmitEvent).submitter?.innerText;
    if (submitter == "Guardar") await onSaveEditData();
    else if (submitter == "Volver") setStep(step - 1);
    else setStep(step + 1);
  };
  const handleVisibiltyChange = (name: VisibilityNm, value: string) => {
    console.log(stepThreeData[name]);
    if (name)
      setStepThreeData({
        ...stepThreeData,
        [name]: {
          value: stepThreeData[name]!.value,
          visibility: value,
        },
      });
  };

  const onSaveEditData = async () => {
    setLoading(true);
    const data = getInfoPayload(participants - 1, authData?.id);
    const response = await saveUserInfo(data);
    if (response?.error == "true" || !response) {
      toast.error("Ha ocurrido un error");
    } else {
      toast.success("Los datos se guardaron correctamente");
      saveUser(response.info.data[0]);
    }
    setLoading(false);
  };

  return (
    <form
      hidden={step == 3 ? false : true}
      onSubmit={handleSubmit}
      className={` m-6 pb-10 text-center items-center  xl:overflow-hidden xl:overflow-y-auto xl:p-5 ${
        isEditing ? "w-full" : "max-w-[768px] max-h-[90vh]"
      }`}
    >
      <div
        hidden={isEditing}
        className="md:absolute mb-8 md:mb-0 justify-center top-[27px] left-[26px]"
      >
        <SecondaryButton onClick={backStep}>Volver atrás</SecondaryButton>
      </div>
      {!isEditing && (
        <div className="flex justify-center">
          <Logo />
        </div>
      )}
      <Stepper step={step - 2} />
      <h1 className="mt-14 font-semibold text-[30px] ">
        {participants == 1 ? "Yo solo" : "En pareja"}
      </h1>
      <h3 className="mt-2 font-normal text-color1 text-[16px]">
        {participants == 1
          ? "¡Muy bien! Ahora rellena tu información."
          : "¡Muy bien! Ahora rellenad la información de cada uno de vosotros."}
      </h3>
      <div className="mt-8 flex justify-center w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 w-full">
          <div className="text-start ">
            <CustomSelect
              name="race"
              onChange={handleChangeSelect}
              value={stepThreeData.race.value}
              title="Mi raza"
              required
              items={races
                .filter((it) => it.sex == stepTwoData.sex.value)
                .map((el) => ({
                  label: el.name,
                  value: el.id.toString(),
                }))}
              defaultSelectedKeys={isEditing ? [stepThreeData.race.value] : []}
              isInvalid={errors.race}
              errorMessage={"Este campo es requerido"}
              visibility_name="race_visibility"
              visibilityValue={`race_visibility_${
                stepThreeData.race?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("race", value);
              }}
            />
          </div>
          <div
            className="text-start w-full"
            hidden={participants == 2 ? false : true}
          >
            <CustomSelect
              title="Raza de mi pareja"
              name="partner_race"
              visibility_name="partner_race_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_race", value);
              }}
              visibilityValue={`partner_race_visibility_${
                stepThreeData.partner_race?.visibility ?? "all"
              }`}
              onChange={handleChangeSelect}
              value={stepThreeData.partner_race?.value}
              required={participants == 2}
              items={races
                .filter(
                  (it) => it.sex == (stepTwoData.partner_sex?.value ?? "f")
                )
                .map((el) => ({
                  label: el.name,
                  value: el.id.toString(),
                }))}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_race?.value
                  ? [stepThreeData.partner_race?.value]
                  : []
              }
              isInvalid={errors.partner_race}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start w-full">
            <CustomInput
              className="w-full"
              name="weight"
              placeholder="Introduce el peso"
              label="Mi peso (en Kg)"
              value={stepThreeData.weight.value}
              showVisibility
              visibility_name="weight_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("weight", value);
              }}
              visibilityValue={`weight_visibility_${
                stepThreeData.weight?.visibility ?? "all"
              }`}
              onChange={handleChangeInput}
              isInvalid={errors.weight}
              errorMessage={"Entre un peso válido en kg"}
              onValidate={isValidateInt}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomInput
              name="partner_weight"
              className="w-full"
              placeholder="Introduce el peso"
              label="Peso de mi pareja (en Kg)"
              value={stepThreeData.partner_weight?.value}
              showVisibility
              visibility_name="partner_weight_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_weight", value);
              }}
              visibilityValue={`partner_weight_visibility_${
                stepThreeData.partner_weight?.visibility ?? "all"
              }`}
              onChange={handleChangeInput}
              isInvalid={errors.partner_weight}
              errorMessage={"Entre un peso válido en kg"}
              onValidate={isValidateInt}
            />
          </div>
          <div className="text-start">
            <CustomInput
              name="height"
              required
              className="w-full"
              value={stepThreeData.height.value}
              onChange={handleChangeInput}
              placeholder="Introduce la altura"
              label="Mi altura (en cm)"
              type="number"
              showVisibility
              visibility_name="height_visibility"
              visibilityValue={`height_visibility_${
                stepThreeData.height?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("height", value);
              }}
              isInvalid={errors.height}
              errorMessage={"Entre una altura válida en cm"}
              onValidate={isValidateInt}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomInput
              name="partner_height"
              className="w-full"
              placeholder="Introduce la altura"
              label="Altura de mi pareja (en cm)"
              value={stepThreeData.partner_height?.value}
              onChange={handleChangeInput}
              showVisibility
              visibility_name="partner_height_visibility"
              visibilityValue={`partner_height_visibility_${
                stepThreeData.partner_height?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_height", value);
              }}
              required={participants == 2}
              isInvalid={errors.partner_height}
              errorMessage={"Entre una altura válida en cm"}
              onValidate={participants == 2 ? isValidateInt : undefined}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Mi complexión"
              name="complexion"
              onChange={handleChangeSelect}
              value={stepThreeData.complexion.value}
              required
              items={complexions
                .filter((it) => it.sex == stepTwoData.sex.value)
                .map((el) => ({
                  label: el.name,
                  value: el.id.toString(),
                }))}
              visibility_name="complexion_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("complexion", value);
              }}
              visibilityValue={`complexion_visibility_${
                stepThreeData.complexion?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.complexion.value
                  ? [stepThreeData.complexion.value]
                  : []
              }
              isInvalid={errors.complexion}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              name="partner_complexion"
              title="Complexión de mi pareja"
              required={participants == 2}
              items={complexions
                .filter(
                  (it) => it.sex == (stepTwoData.partner_sex?.value ?? "f")
                )
                .map((el) => ({
                  label: el.name,
                  value: el.id.toString(),
                }))}
              onChange={handleChangeSelect}
              value={stepThreeData.partner_complexion?.value}
              visibility_name="partner_complexion_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_complexion", value);
              }}
              visibilityValue={`partner_complexion_visibility_${
                stepThreeData.partner_complexion?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_complexion?.value
                  ? [stepThreeData.partner_complexion?.value]
                  : []
              }
              isInvalid={errors.partner_complexion}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              name="eye_color"
              onChange={handleChangeSelect}
              value={stepThreeData.eye_color.value}
              title="Color de mis ojos"
              required
              items={eyesColor.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibilityValue={`eye_color_visibility_${
                stepThreeData.eye_color?.visibility ?? "all"
              }`}
              visibility_name="eye_color_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("eye_color", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.eye_color.value
                  ? [stepThreeData.eye_color.value]
                  : []
              }
              isInvalid={errors.eye_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              name="partner_eye_color"
              title="Color de ojos de mi pareja"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_eye_color?.value}
              required={participants == 2}
              items={eyesColor.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_eye_color_visibility"
              visibilityValue={`partner_eye_color_visibility_${
                stepThreeData.partner_eye_color?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_eye_color", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_eye_color?.value
                  ? [stepThreeData.partner_eye_color?.value]
                  : []
              }
              isInvalid={errors.partner_eye_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              name="hair_color"
              onChange={handleChangeSelect}
              value={stepThreeData.hair_color.value}
              title="Color de mi pelo"
              required
              items={hairColors.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="hair_color_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("hair_color", value);
              }}
              visibilityValue={`hair_color_visibilty_${
                stepThreeData.hair_color?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.hair_color.value
                  ? [stepThreeData.hair_color.value]
                  : []
              }
              isInvalid={errors.hair_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              name="partner_hair_color"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_hair_color?.value}
              title="Color de pelo de mi pareja"
              required={participants == 2}
              items={hairColors.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_hair_color_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_hair_color", value);
              }}
              visibilityValue={`partner_hair_color_visibilty_${
                stepThreeData.partner_hair_color?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_hair_color?.value
                  ? [stepThreeData.partner_hair_color?.value]
                  : []
              }
              isInvalid={errors.partner_hair_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              onChange={handleChangeSelect}
              value={stepThreeData.hair_length.value}
              name="hair_length"
              title="Largo de mi pelo"
              required
              items={hairCuts.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="hair_length_visibilty"
              visibilityValue={`hair_length_visibilty_${
                stepThreeData.hair_length?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("hair_length", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.hair_length.value
                  ? [stepThreeData.hair_length.value]
                  : []
              }
              isInvalid={errors.hair_length}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              onChange={handleChangeSelect}
              value={stepThreeData.partner_hair_length?.value}
              name="partner_hair_length"
              title="Largo de pelo de mi pareja"
              required={participants == 2}
              items={hairCuts.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_hair_length_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_hair_length", value);
              }}
              visibilityValue={`partner_hair_length_visibilty_${
                stepThreeData.partner_hair_length?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_hair_length?.value
                  ? [stepThreeData.partner_hair_length?.value]
                  : []
              }
              isInvalid={errors.partner_hair_length}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              name="type_of_nose"
              onChange={handleChangeSelect}
              value={stepThreeData.type_of_nose.value}
              title="Mi tipo de nariz"
              required
              items={noseTypes.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="type_of_nose_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("type_of_nose", value);
              }}
              visibilityValue={`type_of_nose_visibilty_${
                stepThreeData.type_of_nose?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.type_of_nose.value
                  ? [stepThreeData.type_of_nose.value]
                  : []
              }
              isInvalid={errors.type_of_nose}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              name="partner_type_of_nose"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_type_of_nose?.value}
              title="Tipo de nariz de mi pareja"
              required={participants == 2}
              items={noseTypes.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_type_of_nose_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_type_of_nose", value);
              }}
              visibilityValue={`partner_type_of_nose_visibilty_${
                stepThreeData.partner_type_of_nose?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_type_of_nose?.value
                  ? [stepThreeData.partner_type_of_nose?.value]
                  : []
              }
              isInvalid={errors.partner_type_of_nose}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              name="lips"
              onChange={handleChangeSelect}
              value={stepThreeData.lips.value}
              title="Mis labios"
              required
              items={lipsOpt.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="lips_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("lips", value);
              }}
              visibilityValue={`lips_visibilty_${
                stepThreeData.lips?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.lips.value
                  ? [stepThreeData.lips.value]
                  : []
              }
              isInvalid={errors.lips}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Labios de mi pareja"
              name="partner_lips"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_lips?.value}
              required={participants == 2}
              items={lipsOpt.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_lips_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_lips", value);
              }}
              visibilityValue={`partner_lips_visibilty_${
                stepThreeData.partner_lips?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_lips?.value
                  ? [stepThreeData.partner_lips?.value]
                  : []
              }
              isInvalid={errors.partner_lips}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Color de mi piel"
              name="skin_color"
              onChange={handleChangeSelect}
              value={stepThreeData.skin_color.value}
              required
              items={skincolors.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="skin_color_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("skin_color", value);
              }}
              visibilityValue={`skin_color_visibilty_${
                stepThreeData.skin_color?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.skin_color.value
                  ? [stepThreeData.skin_color.value]
                  : []
              }
              isInvalid={errors.skin_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Color de piel de mi pareja"
              name="partner_skin_color"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_skin_color?.value}
              required={participants == 2}
              items={skincolors.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_skin_color_visibilty"
              visibilityValue={`partner_skin_color_visibilty_${
                stepThreeData.partner_skin_color?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_skin_color", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_skin_color?.value
                  ? [stepThreeData.partner_skin_color?.value]
                  : []
              }
              isInvalid={errors.partner_skin_color}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Corte de mi bello púbico"
              required
              name="pubic_hair_cut"
              onChange={handleChangeSelect}
              value={stepThreeData.pubic_hair_cut.value}
              items={pubicHairCut.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="pubic_hair_cut_Visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("pubic_hair_cut", value);
              }}
              visibilityValue={`pubic_hair_cut_Visibility_${
                stepThreeData.pubic_hair_cut?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.pubic_hair_cut.value
                  ? [stepThreeData.pubic_hair_cut.value]
                  : []
              }
              isInvalid={errors.pubic_hair_cut}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Corte de bello púbico de mi pareja"
              name="partner_pubic_hair_cut"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_pubic_hair_cut?.value}
              required={participants == 2}
              items={pubicHairCut.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_pubic_hair_cut_Visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_pubic_hair_cut", value);
              }}
              visibilityValue={`partner_pubic_hair_cut_Visibility_${
                stepThreeData.partner_pubic_hair_cut?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_pubic_hair_cut?.value
                  ? [stepThreeData.partner_pubic_hair_cut?.value]
                  : []
              }
              isInvalid={errors.partner_pubic_hair_cut}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Mis piercings"
              required
              onChange={handleChangeSelect}
              value={stepThreeData.piercings.value}
              name="piercings"
              items={piercingCount.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="piercings_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("piercings", value);
              }}
              visibilityValue={`piercings_visibilty_${
                stepThreeData.piercings?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.piercings.value
                  ? [stepThreeData.piercings.value]
                  : []
              }
              isInvalid={errors.piercings}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Piercings de mi pareja"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_piercings?.value}
              name="partner_piercings"
              required={participants == 2}
              items={piercingCount.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_piercings_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_piercings", value);
              }}
              visibilityValue={`partner_piercings_visibilty_${
                stepThreeData.partner_piercings?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_piercings?.value
                  ? [stepThreeData.partner_piercings?.value]
                  : []
              }
              isInvalid={errors.partner_piercings}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="Mis tatuajes"
              name="tattoos"
              onChange={handleChangeSelect}
              value={stepThreeData.tattoos.value}
              required
              items={tattoosCount.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="tattoos_visibilty"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("tattoos", value);
              }}
              visibilityValue={`tattoos_visibilty_${
                stepThreeData.tattoos?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.tattoos.value
                  ? [stepThreeData.tattoos.value]
                  : []
              }
              isInvalid={errors.tattoos}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="Tatuajes de mi pareja"
              name="partner_tattoos"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_tattoos?.value}
              required={participants == 2}
              items={tattoosCount.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_tattoos_visibilty"
              visibilityValue={`partner_tattoos_visibilty_${
                stepThreeData.partner_tattoos?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_tattoos", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_tattoos?.value
                  ? [stepThreeData.partner_tattoos?.value]
                  : []
              }
              isInvalid={errors.partner_tattoos}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="¿Bebes?"
              required
              name="you_drink"
              onChange={handleChangeSelect}
              value={stepThreeData.you_drink.value}
              items={drink.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="you_drink_visibilty"
              visibilityValue={`you_drink_visibilty_${
                stepThreeData.you_drink?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("you_drink", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.you_drink.value
                  ? [stepThreeData.you_drink.value]
                  : []
              }
              isInvalid={errors.you_drink}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="¿Bebe mi pareja?"
              name="partner_you_drink"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_you_drink?.value}
              required={participants == 2}
              items={drink.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_you_drink_visibilty"
              visibilityValue={`partner_you_drink_visibilty_${
                stepThreeData.partner_you_drink?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_you_drink", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_you_drink?.value
                  ? [stepThreeData.partner_you_drink?.value]
                  : []
              }
              isInvalid={errors.partner_you_drink}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start">
            <CustomSelect
              title="¿Fumas?"
              name="you_smoke"
              onChange={handleChangeSelect}
              value={stepThreeData.you_smoke.value}
              required
              items={smoke.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="you_smoke_visibilty"
              visibilityValue={`you_smoke_visibilty_${
                stepThreeData.you_smoke?.visibility ?? "all"
              }`}
              onVisibilityChange={(value) => {
                handleVisibiltyChange("you_smoke", value);
              }}
              defaultSelectedKeys={
                isEditing && stepThreeData.you_smoke.value
                  ? [stepThreeData.you_smoke.value]
                  : []
              }
              isInvalid={errors.you_smoke}
              errorMessage={"Este campo es requerido"}
            />
          </div>
          <div className="text-start" hidden={participants == 2 ? false : true}>
            <CustomSelect
              title="¿Fuma mi pareja?"
              name="partner_you_smoke"
              onChange={handleChangeSelect}
              value={stepThreeData.partner_you_smoke?.value}
              required={participants == 2}
              items={smoke.map((el) => ({
                label: el.name,
                value: el.id.toString(),
              }))}
              visibility_name="partner_you_smoke_visibility"
              onVisibilityChange={(value) => {
                handleVisibiltyChange("partner_you_smoke", value);
              }}
              visibilityValue={`partner_you_smoke_visibility_${
                stepThreeData.partner_you_smoke?.visibility ?? "all"
              }`}
              defaultSelectedKeys={
                isEditing && stepThreeData.partner_you_smoke?.value
                  ? [stepThreeData.partner_you_smoke?.value]
                  : []
              }
              isInvalid={errors.partner_you_smoke}
              errorMessage={"Este campo es requerido"}
            />
          </div>
        </div>
      </div>
      <div className="mt-12 flex justify-center gap-4">
        {isEditing ? (
          <>
            <PrimaryButton
              type="submit"
              disabled={loading}
              className="w-full bg-white border-primary border-1 text-primary"
            >
              Volver
            </PrimaryButton>
            <PrimaryButton loading={loading} type="submit" className="w-full ">
              Guardar
            </PrimaryButton>
            <PrimaryButton
              disabled={loading}
              type="submit"
              className="w-full bg-white border-primary border-1 text-primary"
            >
              Continuar
            </PrimaryButton>
          </>
        ) : (
          <PrimaryButton disabled={loading} type="submit" className="w-full">
            Continuar
          </PrimaryButton>
        )}
      </div>
    </form>
  );
}
