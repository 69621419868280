import { Modal, ModalContent, Textarea } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import PrimaryButton from "../common/PrimaryButton";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import CustomInput from "../form/CustomInput";
import { reportParty } from "../../api/party";

export default function ReportPartyModal({
  isOpen = false,
  closeModal = () => {},
  name = "",
  id = 0,
}: {
  isOpen: boolean;
  name: string;
  id: number;
  closeModal?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    fiesta_id: id,
    title: "",
    resumen: "",
  });

  const setFormData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const onReport = async () => {
    setIsLoading(true);
    const response = await reportParty(data);
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success("Fiesta reportada con exito");
      closeModal();
    }

    setIsLoading(false);
  };
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    //setFormData(data.name as VisibilityNm, data.value);
    setFormData(data.name, data.value);
  };
  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpen}
      onClose={closeModal}
      placement="center"
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="p-6">
          <div className="flex justify-center">
            <div className="text-start">
              <div className="text-red-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 5.99L19.53 19H4.47zM12 2L1 21h22zm1 14h-2v2h2zm0-6h-2v4h2z"
                  />
                </svg>
              </div>
              <h3 className="mt-4 font-semibold text-color3 text-[18px] mb-3">
                ¿Estas seguro de que quieres reportar esta fiesta {name}?
              </h3>
              <div className="w-full mt-3">
                <CustomInput
                  placeholder="Causa del reporte"
                  label="Causa"
                  name="title"
                  required
                  showVisibility={false}
                  onChange={handleChangeInput}
                  value={data.title}
                ></CustomInput>
              </div>

              <div className="w-full mt-1 lg:col-span-2 ">
                <Textarea
                  id="description"
                  name="resumen"
                  isRequired
                  variant="bordered"
                  placeholder="Descripción"
                  label="Describe la causa del reporte"
                  labelPlacement="outside"
                  required
                  minRows={4}
                  color="primary"
                  classNames={{
                    inputWrapper: "rounded-lg border-small",
                    label: "text-color2",
                    input: "border-0 focus:ring-1 focus:ring-transparent",
                  }}
                  onChange={handleChangeInput}
                  value={data.resumen}
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex justify-center gap-[12px]">
            <SecondaryButton
              className="text-color4 w-full"
              onClick={closeModal}
              disabled={isLoading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              onClick={onReport}
              className="bg-color10 w-full"
              loading={isLoading}
            >
              Reportar
            </PrimaryButton>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
