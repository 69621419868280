import { Modal, ModalContent } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Party, PartyRequest } from "../../interfaces/Party";

import PartyRequestCard from "./PartyRequestCard";

export default function PartyRequestsModal({
  isOpen = false,
  closeModal = () => {},
  onRequestAction,
  party,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onRequestAction: (type: string, req: PartyRequest) => void;
  party: Party;
}) {
  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full px-6">
              <h3 className="mt-6 font-semibold text-color3 text-[18px]">
                Solicitudes para unirse a {party.nombre}
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>

              {party.solicitudes.length == 0 ? (
                <div className="h-[300px] mt-4 py-2 flex items-center justify-center gap-4 ">
                  <p className="text-center">
                    Parece que no tienes solicitudes pendientes
                  </p>
                </div>
              ) : (
                <div className="h-[350px] mt-4 py-2  gap-4 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 overflow-auto w-full  px-6">
                  {party.solicitudes.map((e) => (
                    <PartyRequestCard
                      key={e.id}
                      request={e}
                      onRequestAction={onRequestAction}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="p-6 md:flex block justify-end border-t-1 border-color1/50 ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
