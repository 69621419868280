import {
  //   Card,
  //   CardBody,
  //   CardFooter,
  Modal,
  ModalContent,
  //   Image,
  Pagination,
  Skeleton,
  Input,
  Button,
  User as UserItem,
} from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
// import { Link } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { Pagination as PaginationData } from "../../interfaces/pagination";
import { getUsers } from "../../api/user";
import { User } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import { SearchIcon } from "../../assets/icons/SearchIcon";
// getUsers
export default function SearchUsersModal({
  isOpen = false,
  closeModal = () => {},
  onSelectUser,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onSelectUser: (user: User) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const authData = useAuthStore((state) => state.user);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };

  const getData = async (page: number) => {
    setIsLoading(true);

    const filters = {
      name: search ?? "",
      limit: 30,
      friends: true,
    };
    const res = await getUsers(page, filters);
    setUsers(res.info.data[0].data);
    setPagination({
      current_page: res?.info?.data[0].current_page,
      last_page: res?.info?.data[0].last_page,
      total: res?.info?.data[0].total,
      per_page: res?.info?.data[0].per_page,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (authData) getData(1);
  }, [authData]);

  useEffect(() => {
    setTimeout(() => {
      getData(1);
    }, 600);
  }, [search]);

  const onPageChange = async (page: number) => {
    getData(page);
  };

  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full">
              <h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
                Chatear con
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>
              <form className="px-4">
                <Input
                  className="min-w-sm text-input w-full "
                  id="search"
                  type="text"
                  variant="bordered"
                  label=""
                  startContent={<SearchIcon />}
                  placeholder="Buscar..."
                  required
                  value={search}
                  onChange={handleChange}
                  endContent={
                    <Button
                      color="primary"
                      variant="solid"
                      className="rounded-lg "
                      type="submit"
                      size="sm"
                    >
                      Buscar
                    </Button>
                  }
                  classNames={{ inputWrapper: " border-1 rounded-lg" }}
                />
              </form>
              <div className="h-[330px] mt-4 py-2 gap-1 flex flex-col    overflow-auto w-full  px-6">
                {isLoading
                  ? [0, 0, 0, 0].map((_, i) => (
                      <div
                        key={i}
                        className="max-w-[300px] w-full flex items-center gap-3 p-2"
                      >
                        <div>
                          <Skeleton className="flex rounded-full w-12 h-12" />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                          <Skeleton className="h-3 w-3/5 rounded-lg" />
                          <Skeleton className="h-3 w-4/5 rounded-lg" />
                        </div>
                      </div>
                    ))
                  : users
                      .filter((e) => e.id != authData?.id)
                      .map((e) => (
                        <div
                          onClick={() => {
                            onSelectUser(e);
                            closeModal();
                          }}
                          key={e.id}
                          className="cursor-pointer hover:bg-primary/10 rounded-md transition-colors ease-in-out p-2"
                        >
                          <UserItem
                            name={e.name}
                            description={e.email}
                            avatarProps={{
                              src: e?.profile_path
                                ? e?.profile_path?.startsWith("http")
                                  ? e?.profile_path
                                  : import.meta.env.VITE_BASE_URL +
                                    e?.profile_path
                                : undefined,
                            }}
                          />
                        </div>
                      ))}
              </div>
              {pagination && pagination?.current_page > 0 && (
                <div className="px-4 mt-3 mb-2 w-full">
                  <div className="flex justify-center">
                    <Pagination
                      showControls
                      total={pagination.last_page}
                      initialPage={1}
                      page={pagination.current_page}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="p-6 pt-0 md:flex block justify-end ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
