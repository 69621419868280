import { Link } from 'react-router-dom';
import LogoImage from '../../../logo.png';

export default function Logo() {
	return (
		<Link to='/'>
			<img
				src={LogoImage}
				className='w-[136px] relative z-50 transition-transform duration-300 transform hover:scale-90'
				alt='Loouth'
			/>
		</Link>
	);
}
