import {
  Avatar,
  Badge,
  Card,
  CardBody,
  CardFooter,
  Image,
  Skeleton,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import { GenderFemale, GenderMale } from "../../assets/icons/UserIcon";
import { User } from "../../interfaces/Login";
import { useMemo } from "react";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { Province } from "../../interfaces/Provice";
import BuildingIcon from "../../assets/icons/BuildingIcon";

export default function ProfileSmallCard({
  provinces,
  user,
  orientations,
}: {
  user: User;
  orientations: SexualOrientation[];
  provinces: Province[];
}) {
  const age = useMemo(() => {
    if (!user?.info?.detalles?.birthDate) return "?";
    const birthday = new Date(user?.info?.detalles?.birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    if (user.info.is_couple && user?.info?.detalles?.partner_birthDate) {
      const partnerBirthday = new Date(user?.info?.detalles?.partner_birthDate);
      const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
      const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
      const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
      if (uAge == pAge) return `ambos ${pAge}`;
      return `${uAge} y ${pAge}`;
    }
    //partner_birthDate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }, [user]);

  const orientation = useMemo(() => {
    const userSO = user?.info?.detalles?.sexualOrentation;
    const userPartnerSO = user?.info?.detalles?.partner_sexualOrentation;
    if (orientations.length) {
      const myO =
        userSO?.value &&
        orientations.find((e) => e.id.toString() == userSO?.value)?.name;

      if (!user?.info?.is_couple) return myO ?? "?";

      const partnerO =
        userPartnerSO?.value &&
        orientations.find((e) => e.id.toString() == userPartnerSO?.value)?.name;

      if (myO && partnerO) {
        if (myO == partnerO) return myO;
        return myO == "bisexual" ? partnerO : myO;
      }
      return "?";
    } else
      return (
        <Skeleton className="ml-2 rounded-lg">
          <p className="w-14 h-3"></p>
        </Skeleton>
      );
  }, [orientations]);

  const province = useMemo(() => {
    /**provinces.length && userData?.info?.provincia_id
                  ? provinces.find((e) => e.id == userData?.info?.provincia_id)
                      ?.name
                  : "..." */
    if (provinces.length && user?.info?.provincia_id) {
      const pr = provinces.find((e) => e.id == user?.info?.provincia_id)?.name;
      return pr ?? "";
    }
    return (
      <Skeleton className="ml-2 rounded-lg">
        <p className="w-14 h-3"></p>
      </Skeleton>
    );
  }, [provinces]);
  return (
    <Card
      shadow="sm"
      key={user.id}
      radius="md"
      className="w-full flexflex-grow items-start h-full"
    >
      <CardBody className="overflow-visible p-0">
        <div>
          <Image
            shadow="none"
            radius="md"
            width="100%"
            alt="alt"
            className="w-full object-cover h-[140px]"
            fallbackSrc="/assets/fantasyImage.jpeg"
            src={
              user?.portada_path
                ? user?.portada_path?.startsWith("http")
                  ? user?.portada_path
                  : import.meta.env.VITE_BASE_URL + user?.portada_path
                : undefined
            }
          />
          <div className="w-full flex justify-center">
            <Link
              to={`/profile/${user.id}`}
              key={user.id}
              className="-mt-[40px]"
            >
              <Badge
                isOneChar
                content={
                  user.role_id == "3" ? (
                    <BuildingIcon stroke="white" />
                  ) : user.info?.is_couple ? (
                    <UsersIcon stroke="white" />
                  ) : user.info?.detalles?.sex?.value == "f" ? (
                    <GenderFemale />
                  ) : (
                    <GenderMale />
                  )
                }
                color="primary"
                size="lg"
                shape="circle"
                placement="bottom-right"
                className={`z-20 bottom-4 ${
                  user.role_id == "3"
                    ? "bg-green-500"
                    : user.info?.is_couple
                    ? "bg-primary"
                    : user.info?.detalles?.sex?.value == "f"
                    ? "bg-pink-400"
                    : "bg-blue-400"
                }`}
              >
                <Avatar
                  src={
                    user?.profile_path
                      ? user?.profile_path?.startsWith("http")
                        ? user?.profile_path
                        : import.meta.env.VITE_BASE_URL + user?.profile_path
                      : undefined
                  }
                  showFallback
                  classNames={{
                    base: "bg-zinc-100",
                    icon: "text-black/50",
                  }}
                  className="z-20 w-20 h-20 border-3 border-white"
                />
              </Badge>
            </Link>
          </div>
        </div>

        <div className="text-small flex flex-col items-start justify-start px-3">
          <div>
            <b className="hover:text-primary text-xl">
              <Link to={`/profile/${user.id}`} key={user.id}>
                {user.name}
              </Link>
            </b>
            {user?.role_id == "2" && (
              <span className="text-[16px] ml-3 font-bold text-primary">
                {age} años
              </span>
            )}
          </div>

          <div className="font-semibold text-color1">
            {" "}
            <span className="font-semibold text-color1">
              {user.role_id == "3"
                ? "Local"
                : user.info?.is_couple
                ? "Pareja"
                : user.info?.detalles?.sex?.value == "f"
                ? "Mujer"
                : "Hombre"}{" "}
              {user.role_id == "2" && orientation}{" "}
              {user.role_id == "2" && (
                <>
                  {" "}
                  -
                  <span className="text-blue-500 font-bold ml-2">
                    {province}
                  </span>
                </>
              )}
            </span>
          </div>

          <div className="">
            {user.info?.detalles?.description.value.slice(0, 100)}
            {user.info?.detalles?.description.value
              ? user.info?.detalles?.description.value.length > 100
                ? "..."
                : ""
              : "..."}
          </div>
        </div>
      </CardBody>
      <CardFooter className="text-small flex flex-col items-start justify-start"></CardFooter>
    </Card>
  );
}
