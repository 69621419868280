import { Button, Skeleton } from "@nextui-org/react";
import { EditIcon } from "../../assets/icons/PencilIcon";
import Moment from "react-moment";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import CropperModal from "./CropperModal";
import { BlockIcon } from "../../assets/icons/LockIcon";
import { FlagIcon } from "../../assets/icons/FlagIcon";
import { Fragment, Key, useEffect, useMemo, useState } from "react";
import {
	deleteFriendRequest,
	getBlockedUsers,
	getFriendRequests,
	getFriends,
	respondFriendRequest,
	sendFriendRequest,
} from "../../api/friends";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/authStore";
import { infoDetals, useUserStore } from "../../stores/userStore";
import { sexs } from "../../utils/selectOptions";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { DeleteFriend, FriendIcon } from "../../assets/icons/UserIcon";
import { useHomeStore } from "../../stores/homeData";
import { BlockedData, FriendReq, RequestData, User } from "../../interfaces/Login";
import LightBoxImage from "../common/LightBoxImage";
import { MessageIcon } from "../../assets/icons/ChatIcon";
import FriendshipModal from "../friend/FriendshipModal";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import ReportUserModalpModal from "./ReportUserModal";
import { useDateFormatter } from "@react-aria/i18n";
import { useNavigate } from "react-router-dom";
import BlockUserModal from "./BlockUserModal";
import { likeUser } from "../../api/user";
import { Global } from "../../interfaces/SingleUser";
import ProfileGlobalData from "./ProfileGlobal";
import { age } from "./utils";
import ProfileAvatar from "./ProfileAvatar";
import ProfileActionsDropdown from "./ProfileActionsDropdown";
import { Province } from "../../interfaces/Provice";
import { useChatStore } from "../../stores/chatStore";
import { FireIcon } from "../../assets/icons/FireIcon";

export default function ProfileCard({
	orientations,
	child,
	mobileChild,
	provinces,
	globalData,
	isLoadingGlobal,
	onEditClick,
}: {
	orientations: SexualOrientation[];
	provinces: Province[];
	child?: React.ReactNode;
	mobileChild?: React.ReactNode;
	onEditClick: () => void;
	globalData: Global;
	isLoadingGlobal: boolean;
}) {
	const navigate = useNavigate();

	const [isSendingrequest, setIsSendingrequest] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenLightbox, setIsOpenLightbox] = useState(false);
	const [isOpenFriendModal, setIsOpenFriendModal] = useState(false);
	const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
	const [isOpenReportModal, setIsOpenReportModal] = useState(false);
	const [isLoadingBlocked, setIsLoadingBlocked] = useState(false);

	const [iamgeSrc, setImage] = useState<string | undefined>();
	const [editType, setEditType] = useState<"avatar" | "cover">("avatar");

	const [friendRequests, setFriendRequests] = useState<RequestData[]>([]);
	const [blocked, setBlocked] = useState<BlockedData[]>([]);
	const [friendRequestsSent, setFriendRequestsSent] = useState<RequestData[]>([]);
	const [isLoadingRequests, setIsLoadingRequests] = useState(false);
	// const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);

	const authData = useAuthStore((state) => state.user);
	const userData = useUserStore((state) => state.user);
	const setUserToChat = useChatStore((state) => state.setUserToChat);

	const homeData = useHomeStore((state) => state.home);
	const setHomeData = useHomeStore((state) => state.setHome);
	const [liked, seLiked] = useState(!!userData?.like);
	const handleLike = async () => {
		if (userData) {
			seLiked(!liked);
			await likeUser(userData.id);
		}
	};

	const sendRequest = async () => {
		setIsSendingrequest(true);
		if (userData) {
			const response = await sendFriendRequest({ to_id: userData?.id });
			if (response.error == "true") toast.error(response.info.message + " ");
			else {
				toast.success("Solicitud enviada con éxito");
				if (response.info?.data[0]?.to)
					setFriendRequestsSent([
						...friendRequestsSent,
						{
							...response.info.data[0].to,
							reqId: response.info.data[0].id,
						},
					]);
			}
		}
		setIsSendingrequest(false);
	};

	const onCancelRequest = async () => {
		// Validar si friendRequestsSent contiene la solicitud
		const friend = friendRequestsSent?.find((e) => e.id == userData?.id);

		if (!friend) {
			toast.error("No se encontró la solicitud para cancelar.");
			return;
		}

		const reqId = friend.reqId;

		// Verificar si userData?.id está definido
		if (userData?.id === undefined) {
			toast.error("ID de usuario no disponible.");
			return;
		}

		setIsSendingrequest(true);
		try {
			// Llamar a deleteFriendRequest enviando solo el userId
			const response = await deleteFriendRequest(userData.id); // Ahora estamos seguros de que es un número

			if (response.error == "true" || !response.info) {
				toast.error(response?.info?.message || "Ha ocurrido un error");
			} else {
				toast.success("Solicitud cancelada con éxito");
				setFriendRequestsSent(friendRequestsSent.filter((e) => e.reqId != reqId));
			}
		} catch (error) {
			console.error("Error al cancelar la solicitud:", error);
			toast.error("No se pudo cancelar la solicitud. Intenta nuevamente.");
		} finally {
			setIsSendingrequest(false);
		}
	};

	const acceptRequest = async () => {
		const friend = friendRequests?.find((e) => e.id == userData?.id);

		if (friend) {
			setIsSendingrequest(true);
			const response = await respondFriendRequest(friend?.id, {
				status: "accept",
			});
			if (response.error == "true" || !response.info)
				toast.error(response?.info?.message || "Ha ocurrido un error");
			else {
				toast.success("Solicitud aceptada con éxito");
				setHomeData({
					...homeData,
					amigos: [...homeData.amigos, response?.info.data[0]],
				});
				setFriendRequests(friendRequests.filter((e) => e.id != friend.id));
			}

			setIsSendingrequest(false);
		}
	};

	const handleEditClick = (type: "avatar" | "cover") => {
		if (userData?.id == authData?.id) {
			setEditType(type);
			setIsOpenModal(true);
		}
	};

	useEffect(() => {
		const getData = async () => {
			// --- Blocked users
			// setIsLoadingGlobal(true);
			if (userData?.id != authData?.id) {
				setIsLoadingBlocked(true);
				const blocked = await getBlockedUsers();
				if (blocked?.info?.data[0]?.data) {
					setBlocked(blocked?.info?.data[0]?.data);
				}
				setIsLoadingBlocked(false);
				// --- Friend Requests
				setIsLoadingRequests(true);
				// Requests receibed
				const reqresponse = await getFriendRequests(0);
				const data = (reqresponse?.info?.data[0]?.data as FriendReq[]).map((e) => {
					return {
						...(e.from as User),
						reqId: e.id,
					};
				});
				setFriendRequests(data ?? []);
				// Requests sent
				const reqSent = await getFriendRequests(1);

				const dataReq = (reqSent.info?.data[0]?.data as FriendReq[]).map((e) => {
					return {
						...(e.to as User),
						reqId: e.id,
					};
				});
				setFriendRequestsSent(dataReq ?? []);
				if (authData?.id) {
					const response = await getFriends(authData.id.toString());
					const friends = response?.info?.data[0].data as FriendReq[];
					setHomeData({
						...homeData,
						amigos: friends ?? [],
					});
				}
				setIsLoadingRequests(false);
			}

			// setIsLoadingGlobal(false);
		};

		if (userData) getData();
	}, []);

	const isYourFriend = useMemo(() => {
		if (userData?.id == authData?.id) return false;
		const friend = homeData.amigos.find((e) => e.from_id == userData?.id || e.to_id == userData?.id);
		if (friend?.id) return true;
		return false;
	}, [userData, homeData]);

	const isFriendRequest = useMemo(() => {
		if (userData?.id == authData?.id || !friendRequests.length) return false;
		const friend = friendRequests?.find((e) => e.id == userData?.id);

		if (friend?.id) return true;
		return false;
	}, [userData, friendRequests]);

	const isFriendRequestSent = useMemo(() => {
		if (userData?.id == authData?.id || !friendRequestsSent.length) return false;
		const friend = friendRequestsSent?.find((e) => e.id == userData?.id);

		if (friend?.id) return true;
		return false;
	}, [userData, friendRequestsSent]);

	const handleOpenLightBox = (type: "avatar" | "cover") => {
		let src = undefined;
		if (type == "cover")
			src = userData?.portada_path
				? userData?.portada_path.startsWith("http")
					? userData?.portada_path
					: import.meta.env.VITE_BASE_URL + userData?.portada_path
				: undefined;
		else
			src = userData?.profile_path
				? userData?.profile_path.startsWith("http")
					? userData?.profile_path
					: import.meta.env.VITE_BASE_URL + userData?.profile_path
				: undefined;
		setImage(src);
		setIsOpenLightbox(true);
	};
	const onSuccessDelete = () => {
		setHomeData({
			...homeData,
			amigos: [...homeData.amigos.filter((e) => e.from_id != userData?.id && e.to_id != userData?.id)],
		});
	};

	const orientation = useMemo(() => {
		if (orientations.length) {
			const myO =
				infoDetals()?.sexualOrentation?.value &&
				orientations.find((e) => e.id.toString() == infoDetals()?.sexualOrentation?.value)?.name;

			if (!userData?.info?.is_couple) return myO ?? "?";

			const partnerO =
				infoDetals()?.partner_sexualOrentation?.value &&
				orientations.find((e) => e.id.toString() == infoDetals()?.partner_sexualOrentation?.value)?.name;

			if (myO && partnerO) {
				if (myO == partnerO) return myO;
				return myO == "bisexual" ? partnerO : myO;
			}
			return "?";
		} else
			return (
				<div>
					<Skeleton className="ml-2 rounded-lg">
						<p className="w-14 h-3"></p>
					</Skeleton>
				</div>
			);
	}, [orientations, userData?.info?.is_couple]);
	const menuDangerItems = useMemo(() => {
		let data = [
			{
				key: "report",
				icon: <FlagIcon fill="red" />,
				label: "Denunciar perfil",
			},
		];

		if (isLoadingBlocked) return [...data];
		else if (blocked.findIndex((e) => e.to.id == userData?.id) == -1) {
			data = [
				{
					key: "lock",
					icon: <BlockIcon fill="red" />,
					label: "Bloquear perfil",
				},
				...data,
			];
		} else
			data = [
				{
					key: "unlock",
					icon: <BlockIcon fill="red" />,
					label: "Desbloquear perfil",
				},
				...data,
			];
		if (isYourFriend) {
			data = [
				...data,
				{
					key: "delete-friend",
					icon: (
						<div className="size-4 flex relative items-center overflow-visible justify-center">
							<DeleteFriend stroke="red" className="w-8 absolute" />
						</div>
					),
					label: "Eliminar amistad",
				},
			];
		}
		if (isFriendRequestSent) {
			data = [
				...data,
				{
					key: "cancel-request",
					icon: (
						<div className="size-4 flex relative items-center overflow-visible justify-center">
							<UsersIcon stroke="red" className="w-8 absolute" />
						</div>
					),
					label: "Cancelar solicitud",
				},
			];
		}
		return data;
	}, [blocked, homeData]);

	const formatter = useDateFormatter({ dateStyle: "medium" });

	const goToChat = () => {
		if (userData) setUserToChat(userData);
		navigate("/chat");
	};

	const roleIdString = userData?.role_id?.toString();

	return (
		<>
			<div
				className="h-96 bg-gradient-default w-full bg-center cursor-pointer bg-cover relative"
				style={{
					backgroundImage: ` url(${
						userData?.portada_path
							? userData?.portada_path.startsWith("http")
								? userData?.portada_path
								: import.meta.env.VITE_BASE_URL + userData?.portada_path
							: undefined
					})`,
				}}
				onClick={() => handleOpenLightBox("cover")}
			>
				{userData?.id == authData?.id ? (
					<Button
						onClick={() => handleEditClick("cover")}
						isIconOnly
						aria-label="edit-cover-photo"
						className="absolute right-2 bottom-2 bg-color8"
					>
						<EditIcon height={24} width={24} fill={"#344054"} />
					</Button>
				) : (
					<div className="absolute md:hidden right-2 bottom-2">
						<Button
							className={` bg-color8/70 like ${liked ? "checked" : ""}`}
							variant="flat"
							isIconOnly
							radius="sm"
							aria-label="like"
							onClick={handleLike}
						>
							<div className="checkmark flex items-center justify-center">
								<FireIcon height={24} width={24} stroke="#A24BFD" />
							</div>
						</Button>
					</div>
				)}
			</div>
			<div className="w-full sm:pl-6 px-4">
				<div className="-mt-14  rounded-full w-max relative">
					<ProfileAvatar
						userData={userData}
						onClick={() => handleOpenLightBox("avatar")}
						onEdit={() => handleEditClick("avatar")}
					/>
				</div>
				<div className="w-full -mt-4  xl:-mt-8 flex md:justify-between  sm:px-6">
					{userData?.id == authData?.id &&
						userData?.suscription?.vence != undefined &&
						new Date(userData?.suscription?.vence) >= new Date() && (
							<div className="hidden  md:flex   flex-col items-start justify-start md:flex-row ml-28  md:ml-24 w-10/12">
								<div className="flex items-center bg-color4 rounded-md p-1 pr-4 pl-4">
									<label className="font-medium text-[12px] text-white">
										Premium hasta el {formatter.format(new Date(userData?.suscription?.vence))}
									</label>
								</div>
							</div>
						)}
					<div className="hidden md:flex w-full items-center justify-end gap-4">
						{/*Vista PC */}
						{isLoadingBlocked ? (
							<div className="flex gap-2">
								<Skeleton className=" rounded-lg">
									<p className="w-28 h-10"></p>
								</Skeleton>
								<Skeleton className=" rounded-lg">
									<p className="w-28 h-10"></p>
								</Skeleton>
							</div>
						) : (
							blocked.findIndex((e) => e.to.id == userData?.id) == -1 && (
								<Fragment>
									{authData?.id != userData?.id && isYourFriend && (
										<PrimaryButton onClick={goToChat}>
											<div className="flex items-center gap-2">
												<MessageIcon />
												<span className="hidden lg:inline">Enviar un mensaje</span>
											</div>
										</PrimaryButton>
									)}
									{isLoadingRequests ? (
										<PrimaryButton className="bg-primary/5 text-primary border-2 border-primary/20">
											<div className="flex items-center gap-2">
												<UsersIcon stroke="#A24BFD"></UsersIcon>
												<span className="inline">Cargando...</span>
											</div>
										</PrimaryButton>
									) : isYourFriend ? (
										<PrimaryButton className="bg-red-600/5 text-primary border-2 border-primary/20">
											<div className="flex items-center gap-2">
												<UsersIcon stroke="#A24BFD"></UsersIcon>
												<span className="inline ">Son amigos</span>
											</div>
										</PrimaryButton>
									) : isFriendRequest ? (
										<PrimaryButton
											className="bg-primary/5 text-primary border-2 border-primary/20"
											onClick={acceptRequest}
											loading={isSendingrequest}
										>
											<div className="flex items-center gap-2">
												<FriendIcon stroke="#A24BFD"></FriendIcon>
												<span className="hidden lg:inline">Aceptar solicitud</span>
											</div>
										</PrimaryButton>
									) : isFriendRequestSent ? (
										<PrimaryButton
											className="bg-red-600/5 text-red-600 border-2 border-red-600/20"
											onClick={onCancelRequest}
											loading={isSendingrequest}
										>
											<div className="flex items-center gap-2">
												<UsersIcon stroke="#dc2626"></UsersIcon>
												<span className="hidden lg:inline">Cancelar solicitud</span>
											</div>
										</PrimaryButton>
									) : !isYourFriend && authData?.id != userData?.id ? (
										<PrimaryButton
											onClick={sendRequest}
											loading={isSendingrequest}
											className="bg-primary/5 text-primary border-2 border-primary/20"
										>
											<div className="flex items-center gap-2">
												<FriendIcon stroke="#A24BFD"></FriendIcon>
												<span className="hidden lg:inline">Añadir amigo</span>
											</div>
										</PrimaryButton>
									) : (
										<></>
									)}
								</Fragment>
							)
						)}

						<ProfileActionsDropdown
							userData={userData}
							onItemClick={async (key: Key) => {
								if (key == "report") {
									setIsOpenReportModal(true);
								} else if (key == "copy") {
									await navigator.clipboard.writeText(location.href);
								} else if (key == "lock" || key == "unlock") {
									setIsOpenBlockModal(true);
								} else if (key == "cancel-request") {
									onCancelRequest();
								} else {
									setIsOpenFriendModal(true);
								}
							}}
							menuDangerItems={menuDangerItems}
						/>

						{userData?.id == authData?.id && (
							<SecondaryButton onClick={onEditClick} className="font-semibold text-[14px] text-color4">
								Editar Perfil
							</SecondaryButton>
						)}
						<div className="lg:hidden">{mobileChild}</div>
					</div>
					<div className="flex flex-col w-full md:hidden justify-start items-end gap-3">
						<div className="flex  gap-3 justify-end items-start">
							<ProfileActionsDropdown
								userData={userData}
								onItemClick={async (key: Key) => {
									if (key == "report") {
										setIsOpenReportModal(true);
									} else if (key == "copy") {
										await navigator.clipboard.writeText(location.href);
									} else if (key == "lock" || key == "unlock") {
										setIsOpenBlockModal(true);
									} else if (key == "cancel-request") {
										onCancelRequest();
									} else setIsOpenFriendModal(true);
								}}
								menuDangerItems={menuDangerItems}
							/>

							{userData?.id == authData?.id && (
								<SecondaryButton
									onClick={onEditClick}
									className="font-semibold text-[14px] text-color4"
								>
									Editar Perfil
								</SecondaryButton>
							)}
						</div>
						<div className="lg:hidden">{mobileChild}</div>
					</div>
				</div>
			</div>
			<div className="flex justify-between lg:px-10 w-full  ">
				<div className="relative flex md:gap-7 w-full">
					<div className="-mt-14 md:-mt-3 w-full md:w-auto">
						<div className="flex gap-6 flex-wrap ml-6">
							<div className="">
								<div className="flex flex-col md:flex-row md:items-center">
									<label className="font-semibold text-[30px] text-color3 flex text-wrap">
										{userData?.name}
										{userData?.role_id == "2" && (
											<span className="font-medium text-lg ml-3 pb-1 self-end text-[16px] text-color4">
												{age(userData)} años
											</span>
										)}
									</label>
								</div>
								<ul className="flex flex-wrap items-center gap-3">
									{userData?.id == authData?.id &&
										userData?.suscription?.vence != undefined &&
										new Date(userData?.suscription?.vence) >= new Date() && (
											<li className=" flex md:hidden w-full  flex-col items-start justify-start ">
												<div className="flex items-center bg-color4 rounded-md p-1 pr-4 pl-4">
													<label className="font-medium text-[12px] text-white">
														Premium hasta el{" "}
														{formatter.format(new Date(userData?.suscription?.vence))}
													</label>
												</div>
											</li>
										)}
									<li className="w-full  flex md:w-auto">
										{userData?.last_conection && (
											<div className=" gap-2 border-1 rounded-md p-1 px-4 flex items-center  ">
												<span className="p text-[12px]">
													Activo{" "}
													<Moment utc fromNow className="">
														{userData?.last_conection}
													</Moment>
												</span>
											</div>
										)}
									</li>
									<li className="">
										<span className="font-normal inline-flex text-[16px]  text-color5 items-end">
											{userData?.role_id == "3"
												? "Local"
												: userData?.info?.is_couple
												? "Pareja"
												: sexs.find((x) => x.id == infoDetals()?.sex?.value)?.name}{" "}
											{userData?.role_id == "2" && orientation}
										</span>
									</li>
									<li className="flex">
										{roleIdString !== "3" && (
											<>
												-
												<div className="font-medium ml-2 text-[16px] text-blue-500 flex items-start">
													{!provinces.length && (
														<Skeleton className="ml-2 rounded-lg self-end">
															<p className="w-14 h-3"></p>
														</Skeleton>
													)}

													{provinces.length && userData?.info?.provincia_id
														? provinces.find((e) => e.id == userData?.info?.provincia_id)
																?.name
														: ""}
												</div>
											</>
										)}
									</li>
								</ul>
								{isLoadingGlobal ? (
									<div className="flex gap-2 mt-4 lg:hidden">
										<Skeleton className=" rounded-lg">
											<p className="w-16 h-10"></p>
										</Skeleton>
										<Skeleton className=" rounded-lg">
											<p className="w-16 h-10"></p>
										</Skeleton>
										<Skeleton className=" rounded-lg">
											<p className="w-16 h-10"></p>
										</Skeleton>
									</div>
								) : (
									// Vista Móvil
									<div className="flex justify-between w-full flex-wrap gap-2 lg:hidden">
										<div className="flex md:hidden mt-4 items-center flex-wrap gap-2">
											{isLoadingBlocked ? (
												<div className="flex gap-2">
													<Skeleton className="rounded-lg">
														<p className="w-28 h-10"></p>
													</Skeleton>
													<Skeleton className="rounded-lg">
														<p className="w-28 h-10"></p>
													</Skeleton>
												</div>
											) : (
												blocked.findIndex((e) => e.to.id == userData?.id) == -1 && (
													<Fragment>
														<div className="flex lg:hidden mt-4 items-center gap-2">
															<ProfileGlobalData globalData={globalData} />
														</div>
														{authData?.id != userData?.id && isYourFriend && (
															<PrimaryButton onClick={goToChat}>
																<div className="flex items-center gap-2">
																	<MessageIcon />
																</div>
															</PrimaryButton>
														)}
														{isLoadingRequests ? (
															<PrimaryButton className="bg-primary/5 text-primary border-2 border-primary/20">
																<div className="flex items-center gap-2">
																	<UsersIcon stroke="#A24BFD"></UsersIcon>
																	<span className="inline">Cargando...</span>
																</div>
															</PrimaryButton>
														) : isYourFriend ? (
															<PrimaryButton className="bg-red-600/5 text-primary border-2 border-primary/20">
																<div className="flex items-center gap-2">
																	<UsersIcon stroke="#A24BFD"></UsersIcon>
																	<span className="inline">Son amigos</span>
																</div>
															</PrimaryButton>
														) : isFriendRequest ? (
															<PrimaryButton
																className="bg-primary/5 text-primary border-2 border-primary/20"
																onClick={acceptRequest}
																loading={isSendingrequest}
															>
																<div className="flex items-center gap-2">
																	<FriendIcon stroke="#A24BFD"></FriendIcon>
																	<span className="inline sm:hidden">
																		Aceptar solicitud
																	</span>
																</div>
															</PrimaryButton>
														) : isFriendRequestSent ? (
															<PrimaryButton
																className="bg-red-600/5 text-red-600 border-2 border-red-600/20"
																onClick={onCancelRequest}
																loading={isSendingrequest}
															>
																<div className="flex items-center gap-2">
																	<UsersIcon stroke="#dc2626"></UsersIcon>
																	<span className="inline sm:hidden">
																		Cancelar solicitud
																	</span>
																</div>
															</PrimaryButton>
														) : !isYourFriend && authData?.id != userData?.id ? (
															<PrimaryButton
																onClick={sendRequest}
																loading={isSendingrequest}
																className="bg-primary/5 text-primary border-2 border-primary/20"
															>
																<div className="flex items-center gap-2">
																	<FriendIcon stroke="#A24BFD"></FriendIcon>
																	<span className="inline sm:hidden">
																		Añadir amigo
																	</span>
																</div>
															</PrimaryButton>
														) : (
															<></>
														)}
													</Fragment>
												)
											)}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex gap-6 flex-col mt-5">{child}</div>
			</div>
			<CropperModal type={editType} isOpen={isOpenModal} closeModal={() => setIsOpenModal(false)} />
			{iamgeSrc && (
				<LightBoxImage src={iamgeSrc} isOpen={isOpenLightbox} closeModal={() => setIsOpenLightbox(false)} />
			)}
			<FriendshipModal
				isOpen={isOpenFriendModal}
				closeModal={() => setIsOpenFriendModal(false)}
				name={userData?.name ?? ""}
				id={userData?.id ?? 0}
				onSuccessDelete={onSuccessDelete}
			/>
			<ReportUserModalpModal
				isOpen={isOpenReportModal}
				closeModal={() => setIsOpenReportModal(false)}
				name={userData?.name ?? ""}
				id={userData?.id ?? 0}
			/>
			<BlockUserModal
				isOpen={isOpenBlockModal}
				closeModal={() => setIsOpenBlockModal(false)}
				name={userData?.name ?? ""}
				type={blocked.findIndex((e) => e.to.id == userData?.id) == -1 ? "lock" : "unlock"}
				id={
					blocked.findIndex((e) => e.to.id == userData?.id) == -1 && userData?.id
						? userData?.id
						: blocked[blocked.findIndex((e) => e.to.id == userData?.id)]?.id
				}
				onSuccessDelete={(id: number) => {
					if (blocked.findIndex((e) => e.to.id == userData?.id) == -1) {
						setBlocked([
							...blocked,
							{
								id,
								to: userData!,
							},
						]);
					} else {
						setBlocked([...blocked.filter((e) => e.id != id)]);
					}
				}}
			/>
		</>
	);
}
