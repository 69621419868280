/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo, useState } from "react";
import { infoDetals, infoOthers, infoHowAmI } from "../../stores/userStore";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { getHoroscopes, getLangueages, getProfesions, getLookingFor } from "../../api/formSelectsData";
import { Lang } from "../../interfaces/Lang";
import { Profesion } from "../../interfaces/Profesion";
import { Horoscope } from "../../interfaces/Horoscope";
import {
	sexs,
	complexions,
	childs,
	races,
	eyesColor,
	hairColors,
	hairCuts,
	skincolors,
	lipsOpt,
	tattoosCount,
	piercingCount,
	possibilitiesOfHosting,
	possibilitiesOfMoving,
	nippleSizes,
	noseTypes,
	pubicHairCut,
} from "../../utils/selectOptions";
import { User } from "../../interfaces/Login";
import { Accordion, AccordionItem, Divider, Image } from "@nextui-org/react";
import { Province } from "../../interfaces/Provice";
import { Fantasy } from "../../interfaces/Fantasy";
import { getCountries } from "../../api/nationality";
import { Country } from "../../interfaces/Country";
import { useAuthStore } from "../../stores/authStore";
import SecondaryButton from "../common/SecondaryButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faScissors,
	faLanguage,
	faChildren,
	faBriefcase,
	faScaleBalanced,
	faVenusMars,
	faRuler,
	faRulerHorizontal,
	faWeightScale,
	faEarthAmerica,
	faRulerVertical,
	faEye,
	faCar,
	faPeopleRoof,
	faTransgender,
	faSearch,
	faGlobe,
	faUser,
	faFaceGrin,
	faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";
import { LookingFor } from "../../interfaces/LookingFor";
import { useHomeStore } from "../../stores/homeData";
import ProfileSection from "./ProfileSection";
import ComplexionIcon from "../../assets/icons/ComplexionIcon";
import ColorCabelloIcon from "../../assets/icons/ColorCabelloIcon";
import TatuajesIcon from "../../assets/icons/TatuajesIcon";
import PiercingsIcon from "../../assets/icons/PiercingsIcon";
import LabiosIcon from "../../assets/icons/LabiosIcon";

export function ProfileDetails({
	orientations,
	userData,
	fantasies,
	isLoading = false,
}: {
	orientations: SexualOrientation[];
	userData: User | null;
	provinces: Province[];
	fantasies: Fantasy[];
	isLoading?: boolean;
}) {
	const authData = useAuthStore((state) => state.user);
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownPareja, setShowDropdownPareja] = useState(false);
	const [countries, setCountries] = useState<Country[]>([]);
	const [loading, setLoading] = useState(false);
	const [lookingForOptions, setLookingForOptions] = useState<{ id: number; name: string }[]>([]);
	const [showMorePartnerF, setShowMorePartnerF] = useState(false);
	const [showMoreF, setShowMoreF] = useState(false);

	const [info, setInfo] = useState({
		languages: [] as Lang[],
		profesions: [] as Profesion[],
		horoscope: [] as Horoscope[],
		lookingFor: [] as LookingFor[],
	});
	useEffect(() => {
		const getData = async () => {
			if (userData) {
				const languages = await getLangueages();
				const profesions = await getProfesions();
				const horoscope = await getHoroscopes();
				const lookingFor = await getLookingFor();

				setInfo({
					...info,
					languages: languages.info.data[0],
					profesions: profesions.info.data[0],
					horoscope: horoscope.info.data[0],
					lookingFor: lookingFor.info.data[0],
				});
			}
		};
		getData();
	}, [userData]);

	const getTypeOfNose = (data: string | undefined) => {
		if (!data) return "??";
		const res = noseTypes.find((it) => it.id === parseInt(data));
		return res?.name ?? "??";
	};

	const getPubicHairCut = (data: string | undefined) => {
		if (!data) return "??";
		const res = pubicHairCut.find((it) => it.id === parseInt(data));
		return res?.name ?? "??";
	};

	const userNoseType = getTypeOfNose(userData?.info?.como_soy?.type_of_nose?.value);
	const userPubicHairCut = getPubicHairCut(userData?.info?.como_soy?.pubic_hair_cut?.value);

	let partnerNoseType = "??";
	let partnerPubicHairCut = "??";

	if (userData?.info?.is_couple) {
		partnerNoseType = getTypeOfNose(userData?.info?.como_soy?.partner_type_of_nose?.value);
		partnerPubicHairCut = getPubicHairCut(userData?.info?.como_soy?.partner_pubic_hair_cut?.value);
	}

	const orientation = (data: string | undefined) => {
		if (orientations.length && data) {
			const res = orientations.find((e) => e.id.toString() == data)?.name;
			if (res) return res;
		}

		return "?";
	};
	const complexion = (data: string | undefined) => {
		if (!data) return "?";
		const comp = complexions.find((it) => it.id == parseInt(data));
		return comp?.name ?? "?";
	};

	const race = (data: string | undefined) => {
		if (!data) return "?";
		const res = races.find((it) => it.id == parseInt(data));
		return res?.name ?? "?";
	};
	const skin = (data: string | undefined) => {
		if (!data) return "?";
		const res = skincolors.find((it) => it.id == parseInt(data));
		return res?.name ?? "?";
	};
	const eyeColor = (data: string | undefined) => {
		if (!data) return "?";
		const res = eyesColor.find((it) => it.id == parseInt(data));
		return res?.name ?? "?";
	};
	const hairColor = (data: string | undefined) => {
		if (!data) return "?";
		const res = hairColors.find((it) => it.id == parseInt(data));
		return res?.name ?? "?";
	};
	const hairCut = (data: string | undefined) => {
		if (!data) return "";
		const res = hairCuts.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const lips = (data: string | undefined) => {
		if (!data) return "";
		const res = lipsOpt.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const tattoos = (data: string | undefined) => {
		if (!data) return "";
		const res = tattoosCount.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const piercings = (data: string | undefined) => {
		if (!data) return "";
		const res = piercingCount.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const hosting = (data: string | undefined) => {
		if (!data) return "";
		const res = possibilitiesOfHosting.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const moving = (data: string | undefined) => {
		if (!data) return "";
		const res = possibilitiesOfMoving.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const child = (data: string | undefined) => {
		if (!data) return "";
		const res = childs.find((it) => it.id == parseInt(data));
		return res?.name ?? "";
	};
	const lang = (data: string | undefined) => {
		if (!info.languages.length) return "Cargando...";
		if (!data) return "Español";
		const arr = data.split(",");
		const res = info.languages.filter((it) => arr.includes(it.id.toString()));
		return res.length ? res.map((e) => e.name).join(", ") : "Español";
	};
	const horoscop = (data: number | undefined) => {
		if (!info.horoscope.length) return "Cargando...";
		if (!data) return "?";
		const res = info.horoscope.find((it) => it.id == data);
		return res?.name ?? "?";
	};
	const profession = (data: number | undefined) => {
		if (!info.profesions.length) return "Cargando...";
		if (!data) return "?";
		const res = info.profesions.find((it) => it.id == data);
		return res?.name ?? "";
	};

	const nippleSize = (data: string | undefined) => {
		if (!data) return "?";
		const res = nippleSizes.find((n) => n.value == data);
		return res?.label ?? "";
	};
	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const countriesRes = await getCountries();
			setCountries(countriesRes);
			setLoading(false);
		};
		getData();
	}, [authData]);

	const nationality = useMemo(() => {
		if (countries?.length && userData?.info?.detalles?.nacionalidad_id) {
			const aux = countries.find((e) => e.cca2 == userData?.info?.detalles?.nacionalidad_id);
			if (aux) return aux.name.common;
		}
		return "España";
	}, [countries]);

	const partnerNationality = useMemo(() => {
		if (countries?.length && userData?.info?.detalles?.partner_nacionalidad_id) {
			const aux = countries.find((e) => e.cca2 == userData?.info?.detalles?.partner_nacionalidad_id);
			if (aux) return aux.name.common;
		}
		return "España";
	}, [countries]);

	// visibility check
	const homeData = useHomeStore((state) => state.home);
	const isYourFriend = homeData.amigos.find((e) => e.from_id == userData?.id || e.to_id == userData?.id) != undefined;

	const youCanSee = (visibility?: string) => {
		if (userData?.id == authData?.id) return true;
		if (visibility == "friends" && isYourFriend) return true;
		if (visibility == "all") return true;
		return false;
	};

	useEffect(() => {
		const fetchLookingFor = async () => {
			const data = await getLookingFor();
			if (data && data.info?.data) {
				setLookingForOptions(data.info.data[0]);
			}
		};
		fetchLookingFor();
	}, []);

	const looking = (data: string | undefined) => {
		if (!data) return [];

		const ids = data.split(",").map((id) => parseInt(id.trim())); // Convierte los IDs a números
		const combinedOptions = [
			...(Array.isArray(userData?.info?.looking_id) ? userData.info.looking_id : []),
			...lookingForOptions,
		];

		// Encuentra los nombres correspondientes a los IDs y devuelve un arreglo
		return ids.map((id) => combinedOptions.find((option) => option.id === id)?.name).filter(Boolean); // Filtra valores undefined o null
	};

	const theyarelooking = looking(userData?.info?.looking_id?.toString());

	return (
		<div className="w-full flex flex-wrap mt-8">
			{/* Primer Miembro de la Pareja */}
			{Boolean(userData?.info?.is_couple) && (
				<h2 className="font-semibold text-center text-2xl mt-10 flex justify-center w-full flex-wrap">
					Primero Miembro de la Pareja
				</h2>
			)}

			<div className={`flex justify-center w-full flex-wrap ${!userData?.info?.is_couple ? "mt-0" : "mt-10"}`}>
				<div className="w-full flex flex-col lg:flex-row gap-8">
					{/* General Section */}
					<div
						className="w-full lg:w-6/12 bg-white rounded-lg p-6"
						style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
					>
						<h2 className="text-2xl font-semibold pb-4 mb-4">General</h2>
						<div className="grid grid-cols-2 gap-4">
							{/* Nacionalidad */}
							<ProfileSection icon={<FontAwesomeIcon icon={faGlobe} />} title="Nacionalidad">
								{loading ? (
									"Cargando..."
								) : (
									<div className="flex items-center gap-2">
										<Image
											radius="none"
											alt={`Bandera de ${nationality}`}
											src={`https://flagcdn.com/${infoDetals()?.nacionalidad_id?.toLocaleLowerCase()}.svg`}
											fallbackSrc="https://flagcdn.com/es.svg"
											className="w-6 rounded-none"
											loading="lazy"
											classNames={{
												wrapper: "bg-center bg-no-repeat bg-contain border border-gray-300",
											}}
										/>
										<label>{nationality}</label>
									</div>
								)}
							</ProfileSection>

							{/* Profesión */}
							<ProfileSection icon={<FontAwesomeIcon icon={faBriefcase} />} title="Profesión">
								<label>
									{isLoading
										? "Cargando..."
										: youCanSee(infoOthers()?.profesion_visibility)
										? profession(userData?.info?.profesion_id)
										: "??"}
								</label>
							</ProfileSection>

							{/* Raza */}
							<ProfileSection icon={<FontAwesomeIcon icon={faEarthAmerica} />} title="Raza">
								<label>
									{youCanSee(infoHowAmI()?.race?.visibility) ? race(infoHowAmI()?.race?.value) : "??"}
								</label>
							</ProfileSection>

							{/* Horóscopo */}
							<ProfileSection icon={<FontAwesomeIcon icon={faScaleBalanced} />} title="Horóscopo">
								<label>
									{isLoading ? "Cargando..." : horoscop(userData?.info?.horoscopo_id) ?? "..."}
								</label>
							</ProfileSection>

							{/* Idiomas */}
							<ProfileSection icon={<FontAwesomeIcon icon={faLanguage} />} title="Idiomas">
								<label>{lang(userData?.info?.idiomas_id)}</label>
							</ProfileSection>

							{/* Complexión */}
							<ProfileSection icon={<ComplexionIcon />} title="Complexión">
								<label>
									{youCanSee(infoHowAmI()?.complexion?.visibility)
										? complexion(infoHowAmI()?.complexion.value)
										: "??"}
								</label>
							</ProfileSection>

							{/* Sexo */}
							<ProfileSection icon={<FontAwesomeIcon icon={faVenusMars} />} title="Sexo">
								<label>{sexs.find((x) => x.id == infoDetals()?.sex?.value)?.name}</label>
							</ProfileSection>
						</div>
					</div>

					{/* Gustos Section */}
					<div
						className="w-full lg:w-6/12 bg-white rounded-lg p-6"
						style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
					>
						<h2 className="text-2xl font-semibold pb-4 mb-4">Gustos</h2>
						<div className="flex flex-col gap-6">
							{/* Orientación */}
							<ProfileSection icon={<FontAwesomeIcon icon={faTransgender} />} title="Orientación">
								<label>
									{isLoading ? "Cargando..." : orientation(infoDetals()?.sexualOrentation?.value)}
								</label>
							</ProfileSection>

							{/* Qué buscamos / Qué busco */}
							<ProfileSection
								icon={<FontAwesomeIcon icon={faSearch} />}
								title={userData?.info?.is_couple ? "Qué buscamos" : "Qué busco"}
							>
								{isLoading ? (
									"Cargando..."
								) : theyarelooking?.length ? (
									<div className="flex items-center gap-2">
										{/* Botón individual para el primer elemento */}
										<span className="inline-block text-[14px] font-medium py-2 px-4 border border-gray-300 rounded-lg">
											{theyarelooking[0]}
										</span>

										{/* Botón "+ cantidad" */}
										{theyarelooking.length > 1 && (
											<div className="relative">
												<button
													onClick={() => setShowDropdown(!showDropdown)}
													className="text-[14px] p-2 max-h-[36px] border border-gray-300 bg-white text-black rounded-lg"
												>
													+{theyarelooking.length - 1}
												</button>
												{/* Lista desplegable */}
												{showDropdown && (
													<div className="absolute top-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-2">
														{theyarelooking.slice(1).map((item, index) => (
															<div
																key={index}
																className="text-[14px] font-medium py-1 hover:bg-gray-100 rounded"
															>
																{item}
															</div>
														))}
													</div>
												)}
											</div>
										)}
									</div>
								) : (
									<div className="text-[14px] font-medium py-2 bg-white">
										Sin información disponible
									</div>
								)}
							</ProfileSection>

							{/* Fantasías */}
							<ProfileSection title="Fantasías Destacadas">
								{isLoading ? (
									"Cargando..."
								) : fantasies?.length ? (
									<div className="flex items-center gap-2">
										<Link to={`/fantasy/${fantasies[0].id}`}>
											<SecondaryButton className="text-[14px] p-2 max-h-[36px]">
												{fantasies[0].name}
											</SecondaryButton>
										</Link>
										{fantasies.length > 1 && (
											<div className="relative">
												<button
													onClick={() => setShowMoreF(!showMoreF)}
													className="text-[14px] p-2 max-h-[36px] border border-gray-300 bg-white text-black rounded-lg"
												>
													+{fantasies.length - 1}
												</button>
												{showMoreF && (
													<div className="absolute left-0 top-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-2">
														{fantasies.slice(1).map((x) => (
															<Link key={x.id} to={`/fantasy/${x.id}`}>
																<div className="py-2 px-4 hover:bg-gray-100 text-[14px] rounded">
																	{x.name}
																</div>
															</Link>
														))}
													</div>
												)}
											</div>
										)}
									</div>
								) : (
									<p>Sin Fantasías</p>
								)}
							</ProfileSection>
						</div>
					</div>
				</div>
			</div>

			<Divider className="my-6 w-full  bg-zinc-200" />

			<Accordion defaultSelectedKeys={["1"]} className="w-full" fullWidth showDivider={false}>
				<AccordionItem
					key="1"
					aria-label="Ver más"
					title="Ver más detalles"
					classNames={{
						trigger: "flex max-w-[200px] bg-color4/10 rounded-full text-primary p-1 px-4",
						base: "w-full mb-3",
						heading: "flex flex-row justify-center",
						titleWrapper: "flex flex-row justify-center",
						title: "text-center text-primary",
					}}
				>
					<div className="mt-10 flex flex-col justify-center w-full">
						<div className="w-full flex flex-col lg:flex-row gap-8">
							{/* Físico Section */}
							<div
								className="w-full lg:w-6/12 bg-white rounded-lg p-6"
								style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
							>
								<h2 className="text-2xl font-semibold pb-4 mb-4">Físico</h2>
								<div className="grid grid-cols-2 gap-4">
									<ProfileSection icon={<FontAwesomeIcon icon={faUser} />} title="Color de piel">
										<label>
											{youCanSee(infoHowAmI()?.skin_color?.visibility)
												? skin(infoHowAmI()?.skin_color?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection icon={<FontAwesomeIcon icon={faUser} />} title="Color de ojos">
										<label>
											{youCanSee(infoHowAmI()?.eye_color?.visibility)
												? eyeColor(infoHowAmI()?.eye_color?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection title="Color del cabello" icon={<ColorCabelloIcon />}>
										<label>
											{youCanSee(infoHowAmI()?.hair_color?.visibility)
												? hairColor(infoHowAmI()?.hair_color?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection
										icon={<FontAwesomeIcon icon={faScissors} />}
										title="Corte de cabello"
									>
										<label>
											{youCanSee(infoHowAmI()?.hair_length?.visibility)
												? hairCut(infoHowAmI()?.hair_length?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection icon={<FontAwesomeIcon icon={faRulerVertical} />} title="Altura">
										<label>
											{youCanSee(infoHowAmI()?.height?.visibility)
												? infoHowAmI()?.height?.value
												: "??"}{" "}
											cm
										</label>
									</ProfileSection>
									<ProfileSection icon={<FontAwesomeIcon icon={faWeightScale} />} title="Peso">
										<label>
											{youCanSee(infoHowAmI()?.weight?.visibility)
												? infoHowAmI()?.weight?.value
												: "??"}{" "}
											kg
										</label>
									</ProfileSection>
									{infoOthers()?.member_measurement?.value ? (
										<ProfileSection
											icon={<FontAwesomeIcon icon={faRuler} />}
											title="Tamaño del miembro"
										>
											<label>
												{" "}
												{youCanSee(infoOthers()?.member_measurement?.visibility)
													? infoOthers()?.member_measurement?.value
													: "??"}{" "}
												cm
											</label>
										</ProfileSection>
									) : (
										<></>
									)}
									{infoOthers()?.nipple_size?.value ? (
										<ProfileSection
											icon={<FontAwesomeIcon icon={faRulerHorizontal} />}
											title="Tamaño del pecho"
										>
											<label>
												{youCanSee(infoOthers()?.nipple_size?.visibility)
													? nippleSize(infoOthers()?.nipple_size?.value)
													: "??"}
											</label>
										</ProfileSection>
									) : (
										<></>
									)}
									<ProfileSection icon={<LabiosIcon />} title="Labios">
										<label>
											{youCanSee(infoHowAmI()?.lips?.visibility)
												? lips(infoHowAmI()?.lips?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection icon={<FontAwesomeIcon icon={faFaceSmile} />} title="Tipo de Nariz">
										<label>
											{youCanSee(userData?.info?.como_soy?.type_of_nose?.visibility)
												? userNoseType
												: "??"}
										</label>
									</ProfileSection>

									<ProfileSection
										icon={<FontAwesomeIcon icon={faScissors} />}
										title="Corte de Vello Púbico"
									>
										<label>
											{youCanSee(userData?.info?.como_soy?.pubic_hair_cut?.visibility)
												? userPubicHairCut
												: "??"}
										</label>
									</ProfileSection>
								</div>
							</div>

							{/* Otros Datos Section */}
							<div
								className="w-full lg:w-6/12 bg-white rounded-lg p-6"
								style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
							>
								<h2 className="text-2xl font-semibold pb-4 mb-4">Otros Datos</h2>
								<div className="flex flex-col gap-4">
									<ProfileSection title="Tatuajes" icon={<TatuajesIcon />}>
										<label>
											{youCanSee(infoHowAmI()?.tattoos?.visibility)
												? tattoos(infoHowAmI()?.tattoos?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection title="Piercings" icon={<PiercingsIcon />}>
										<label>
											{youCanSee(infoHowAmI()?.piercings?.visibility)
												? piercings(infoHowAmI()?.piercings?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection icon={<FontAwesomeIcon icon={faChildren} />} title="Hijos">
										<label>
											{youCanSee(infoOthers()?.children?.visibility)
												? child(infoOthers()?.children?.value)
												: "??"}
										</label>
									</ProfileSection>
									<ProfileSection
										icon={<FontAwesomeIcon icon={faPeopleRoof} />}
										title="Capacidad para alojar gente"
									>
										<label>{hosting(infoOthers()?.possibility_of_hosting?.value)}</label>
									</ProfileSection>
									<ProfileSection
										icon={<FontAwesomeIcon icon={faCar} />}
										title="Capacidad para desplazarme"
									>
										<label>{moving(infoOthers()?.possibility_of_moving?.value)}</label>
									</ProfileSection>
								</div>
							</div>
						</div>
					</div>
				</AccordionItem>
			</Accordion>

			{/*Segundo Miembro de la Pareja */}
			{Boolean(userData?.info?.is_couple) && (
				<h2 className="mb-5 font-semibold text-center text-2xl mt-10 flex justify-center w-full  flex-wrap">
					Segundo Miembro de la Pareja
				</h2>
			)}

			{Boolean(userData?.info?.is_couple) && (
				<div className="mt-10 flex justify-center w-full flex-wrap">
					<div className="w-full flex flex-col lg:flex-row gap-8">
						{/* General Section */}
						<div
							className="w-full lg:w-6/12 bg-white rounded-lg p-6"
							style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
						>
							<h2 className="text-2xl font-semibold pb-4 mb-4">General</h2>
							<div className="grid grid-cols-2 gap-4">
								{/* Nacionalidad */}
								<ProfileSection icon={<FontAwesomeIcon icon={faGlobe} />} title="Nacionalidad">
									{loading ? (
										"Cargando..."
									) : (
										<div className="flex items-center gap-2">
											<Image
												radius="none"
												src={`https://flagcdn.com/${infoDetals()?.partner_nacionalidad_id?.toLocaleLowerCase()}.svg`}
												fallbackSrc="https://flagcdn.com/es.svg"
												className="w-6 rounded-none"
												loading="lazy"
												classNames={{
													wrapper:
														"bg-center bg-no-repeat bg-contain border-1 border-color1/20",
												}}
											/>
											<label>{partnerNationality}</label>
										</div>
									)}
								</ProfileSection>

								{/* Profesión */}
								<ProfileSection icon={<FontAwesomeIcon icon={faBriefcase} />} title="Profesión">
									<label>
										{youCanSee(infoOthers()?.partner_profesion_id?.visibility) &&
										userData?.info?.otros?.partner_profesion_id?.value
											? profession(parseInt(userData?.info?.otros?.partner_profesion_id.value))
											: "??"}
									</label>
								</ProfileSection>

								{/* Raza */}
								<ProfileSection icon={<FontAwesomeIcon icon={faEarthAmerica} />} title="Raza">
									<label>{race(infoHowAmI()?.partner_race?.value)}</label>
								</ProfileSection>

								{/* Horóscopo */}
								<ProfileSection icon={<FontAwesomeIcon icon={faScaleBalanced} />} title="Horóscopo">
									<label>
										{isLoading
											? "Cargando..."
											: userData?.info?.otros?.partner_horoscopo_id?.value &&
											  youCanSee(infoOthers()?.partner_horoscopo_id?.visibility)
											? horoscop(parseInt(userData?.info?.otros?.partner_horoscopo_id.value))
											: "??"}
									</label>
								</ProfileSection>

								{/* Idiomas */}
								<ProfileSection icon={<FontAwesomeIcon icon={faLanguage} />} title="Idiomas">
									<label>{lang(userData?.info?.otros?.partner_idioma_id?.value ?? "")}</label>
								</ProfileSection>

								{/* Complexión */}
								<ProfileSection icon={<ComplexionIcon />} title="Complexión">
									<label>{complexion(infoHowAmI()?.partner_complexion?.value)}</label>
								</ProfileSection>

								{/* Sexo */}
								<ProfileSection icon={<FontAwesomeIcon icon={faVenusMars} />} title="Sexo">
									<label>{sexs.find((x) => x.id === infoDetals()?.partner_sex?.value)?.name}</label>
								</ProfileSection>
							</div>
						</div>

						{/* Gustos Section */}
						<div
							className="w-full lg:w-6/12 bg-white  rounded-lg p-6"
							style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
						>
							<h2 className="text-2xl font-semibold pb-4 mb-4">Gustos</h2>
							<div className="flex flex-col gap-6">
								{/* Orientación */}
								<ProfileSection icon={<FontAwesomeIcon icon={faTransgender} />} title="Orientación">
									<label>
										{isLoading
											? "Cargando..."
											: orientation(infoDetals()?.partner_sexualOrentation?.value)}
									</label>
								</ProfileSection>

								{/* Qué buscamos / Qué busco */}
								<ProfileSection
									icon={<FontAwesomeIcon icon={faSearch} />}
									title={userData?.info?.is_couple ? "Qué buscamos" : "Qué busco"}
								>
									{isLoading ? (
										"Cargando..."
									) : theyarelooking?.length ? (
										<div className="flex items-center gap-2">
											{/* Botón individual para el primer elemento */}
											<span className="inline-block text-[14px] font-medium py-2 px-4 border border-gray-300 rounded-lg">
												{theyarelooking[0]}
											</span>

											{/* Botón "+ cantidad" */}
											{theyarelooking.length > 1 && (
												<div className="relative">
													<button
														onClick={() => setShowMorePartnerF(!showMorePartnerF)}
														className="text-[14px] p-2 max-h-[36px] border border-gray-300 bg-white text-black rounded-lg"
													>
														+{theyarelooking.length - 1}
													</button>
													{/* Lista desplegable */}
													{showMorePartnerF && (
														<div className="absolute top-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-2">
															{theyarelooking.slice(1).map((item, index) => (
																<div
																	key={index}
																	className="text-[14px] font-medium py-1 hover:bg-gray-100 rounded"
																>
																	{item}
																</div>
															))}
														</div>
													)}
												</div>
											)}
										</div>
									) : (
										<div className="text-[14px] font-medium py-2 bg-white">
											Sin información disponible
										</div>
									)}
								</ProfileSection>

								{/* Fantasías */}
								<ProfileSection title="Fantasías Destacadas">
									{isLoading ? (
										"Cargando..."
									) : fantasies?.length ? (
										<div className="flex items-center gap-2">
											<Link to={`/fantasy/${fantasies[0].id}`}>
												<SecondaryButton className="text-[14px] p-2 max-h-[36px]">
													{fantasies[0].name}
												</SecondaryButton>
											</Link>
											{fantasies.length > 1 && (
												<div className="relative">
													<button
														onClick={() => setShowDropdownPareja(!showDropdownPareja)}
														className="text-[14px] p-2 max-h-[36px] border border-gray-300 bg-white text-black rounded-lg"
													>
														+{fantasies.length - 1}
													</button>
													{showDropdownPareja && (
														<div className="absolute left-0 top-full mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 p-2">
															{fantasies.slice(1).map((x) => (
																<Link key={x.id} to={`/fantasy/${x.id}`}>
																	<div className="py-2 px-4 hover:bg-gray-100 text-[14px] rounded">
																		{x.name}
																	</div>
																</Link>
															))}
														</div>
													)}
												</div>
											)}
										</div>
									) : (
										<p>Sin Fantasías</p>
									)}
								</ProfileSection>
							</div>
						</div>
					</div>
				</div>
			)}
			{Boolean(userData?.info?.is_couple) && <Divider className="my-6 w-full bg-zinc-200" />}

			{Boolean(userData?.info?.is_couple) && (
				<Accordion defaultSelectedKeys={["2"]} className="w-full" fullWidth showDivider={false}>
					<AccordionItem
						key="2"
						aria-label="Ver más"
						title="Ver más detalles"
						classNames={{
							trigger: "flex max-w-[200px] bg-color4/10 rounded-full text-primary p-1 px-4",
							base: "w-full mb-3",
							heading: "flex flex-row justify-center",
							titleWrapper: "flex flex-row justify-center",
							title: "text-center text-primary",
						}}
					>
						<div className="mt-10 flex flex-col justify-center w-full">
							<div className="w-full flex flex-col lg:flex-row gap-8">
								{/* Físico Section */}
								<div
									className="w-full lg:w-6/12 bg-white rounded-lg p-6"
									style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
								>
									<h2 className="text-2xl font-semibold pb-4 mb-4">Físico</h2>
									<div className="grid grid-cols-2 gap-4">
										<ProfileSection icon={<FontAwesomeIcon icon={faUser} />} title="Color de piel">
											<label>
												{youCanSee(infoHowAmI()?.partner_skin_color?.visibility)
													? skin(infoHowAmI()?.partner_skin_color?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection icon={<FontAwesomeIcon icon={faEye} />} title="Color de ojos">
											<label>
												{youCanSee(infoHowAmI()?.partner_eye_color?.visibility)
													? eyeColor(infoHowAmI()?.partner_eye_color?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection title="Color del cabello" icon={<ColorCabelloIcon />}>
											<label>
												{youCanSee(infoHowAmI()?.partner_hair_color?.visibility)
													? hairColor(infoHowAmI()?.partner_hair_color?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection
											icon={<FontAwesomeIcon icon={faScissors} />}
											title="Corte de cabello"
										>
											<label>
												{youCanSee(infoHowAmI()?.partner_hair_length?.visibility)
													? hairCut(infoHowAmI()?.partner_hair_length?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection
											icon={<FontAwesomeIcon icon={faRulerVertical} />}
											title="Altura"
										>
											<label>
												{youCanSee(infoHowAmI()?.height?.visibility)
													? infoHowAmI()?.partner_height?.value
													: "??"}{" "}
												cm
											</label>
										</ProfileSection>
										<ProfileSection icon={<FontAwesomeIcon icon={faWeightScale} />} title="Peso">
											<label>
												{youCanSee(infoHowAmI()?.weight?.visibility)
													? infoHowAmI()?.partner_weight?.value
													: "??"}{" "}
												kg
											</label>
										</ProfileSection>
										{infoOthers()?.partner_member_measurement?.value ? (
											<ProfileSection
												icon={<FontAwesomeIcon icon={faRuler} />}
												title="Tamaño del miembro"
											>
												<label>
													{youCanSee(infoOthers()?.partner_member_measurement?.visibility)
														? infoOthers()?.partner_member_measurement?.value
														: "??"}{" "}
													cm
												</label>
											</ProfileSection>
										) : null}
										{infoOthers()?.partner_nipple_size?.value ? (
											<ProfileSection
												icon={<FontAwesomeIcon icon={faRulerHorizontal} />}
												title="Tamaño del pecho"
											>
												<label>
													{youCanSee(infoOthers()?.partner_nipple_size?.visibility)
														? nippleSize(infoOthers()?.partner_nipple_size?.value)
														: "??"}
												</label>
											</ProfileSection>
										) : null}
										<ProfileSection icon={<LabiosIcon />} title="Labios">
											<label>
												{youCanSee(infoHowAmI()?.partner_lips?.visibility)
													? lips(infoHowAmI()?.partner_lips?.value)
													: "??"}
											</label>
										</ProfileSection>

										<ProfileSection
											icon={<FontAwesomeIcon icon={faFaceGrin} />}
											title="Tipo de Nariz"
										>
											<label>{partnerNoseType}</label>
										</ProfileSection>

										<ProfileSection
											icon={<FontAwesomeIcon icon={faScissors} />}
											title="Corte de Vello Púbico"
										>
											<label>{partnerPubicHairCut}</label>
										</ProfileSection>
									</div>
								</div>

								{/* Otros Datos Section */}
								<div
									className="w-full lg:w-6/12 bg-white  rounded-lg p-6"
									style={{ boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)" }}
								>
									<h2 className="text-2xl font-semibold pb-4 mb-4">Otros Datos</h2>
									<div className="flex flex-col gap-4">
										<ProfileSection title="Tatuajes" icon={<TatuajesIcon />}>
											<label>
												{youCanSee(infoHowAmI()?.partner_tattoos?.visibility)
													? tattoos(infoHowAmI()?.partner_tattoos?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection title="Piercings" icon={<PiercingsIcon />}>
											<label>
												{youCanSee(infoHowAmI()?.partner_piercings?.visibility)
													? piercings(infoHowAmI()?.partner_piercings?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection icon={<FontAwesomeIcon icon={faChildren} />} title="Hijos">
											<label>
												{youCanSee(infoOthers()?.partner_children?.visibility)
													? child(infoOthers()?.partner_children?.value)
													: "??"}
											</label>
										</ProfileSection>
										<ProfileSection
											icon={<FontAwesomeIcon icon={faPeopleRoof} />}
											title="Capacidad para alojar gente"
										>
											<label>{hosting(infoOthers()?.possibility_of_hosting?.value)}</label>
										</ProfileSection>
										<ProfileSection
											icon={<FontAwesomeIcon icon={faCar} />}
											title="Capacidad para desplazarme"
										>
											<label>{moving(infoOthers()?.possibility_of_moving?.value)}</label>
										</ProfileSection>
									</div>
								</div>
							</div>
						</div>
					</AccordionItem>
				</Accordion>
			)}
		</div>
	);
}
