import {
  Card,
  CardBody,
  Button,
  CardFooter,
  Image,
  Chip,
  User,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { HeartIcon } from "../../assets/icons/HeartIcon";
import { LocationIcon } from "../../assets/icons/LockationIcon";
import { Party } from "../../interfaces/Party";
import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";
import { useAuthStore } from "../../stores/authStore";
import { useState } from "react";
import { likeParty } from "../../api/party";
import { ClockIcon } from "../../assets/icons/ClockIcon";

export default function PartyCard({ party }: { party: Party }) {
  const formatter = useDateFormatter({
    dateStyle: "long",
  });
  const authData = useAuthStore((state) => state.user);
  const [ckeck, setCheck] = useState(
    party?.likes?.find((e) => e.user_id == authData?.id) ? true : false
  );
  const handleLike = async () => {
    if (party?.id) {
      // console.log(ckeck);
      setCheck(!ckeck);
      await likeParty(party?.id);
      // console.log(res);
    }
  };

  return (
    <Card shadow="none" radius="md" className=" border-1  border-gray-200">
      <CardBody className="overflow-visible p-1 flex-initial">
        <div className="relative object-fit">
          <Link to={`/parties/${party.id}`}>
            <Image
              shadow="md"
              radius="md"
              width="100%"
              isZoomed
              alt={party.nombre}
              className={`w-full object-cover h-[200px] md:h-[140px] ${
                new Date() > new Date(party.fecha_fin) && " brightness-50 "
              }`}
              src={
                party?.imagen
                  ? party?.imagen.startsWith("http")
                    ? party?.imagen
                    : import.meta.env.VITE_BASE_URL + party?.imagen
                  : undefined
              }
            />
          </Link>

          {/* <Button
            color="primary"
            radius="sm"
            size="sm"
            className=" absolute top-2 left-2 z-10 shadow-none"
          >
            <div className="flex items-center gap-2">Orgías</div>
          </Button> */}
          <div className="absolute top-2 right-2 z-10">
            <Button
              className={` bg-color8/70 like ${ckeck ? "checked" : ""}`}
              variant="flat"
              isIconOnly
              radius="sm"
              aria-label="like"
              onClick={handleLike}
            >
              <div className="checkmark flex items-center justify-center">
                <HeartIcon height={24} width={24} stroke="#A24BFD" />
              </div>
            </Button>
            {/* <div className="bg-color8/80 p-2 rounded-md mt-2 flex items-center justify-center">
              <MenuIcon />
            </div> */}
          </div>
          <div className="absolute bottom-2 left-2 z-10">
            <div className="bg-color8 text-primary p-1 px-2  rounded-md">
              {party.integrantes?.length ?? 0}/
              {party.capacidad == 0 ? "∞" : party.capacidad}
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter className="text-small items-start py-1">
        <div className="px- flex flex-col w-full justify-start">
          <div className="flex flex-col gap-3 items-start w-full">
            <div className="text-primary font-medium flex items-center justify-between w-full gap-4">
              <span className="inline">
                {party.publica ? "Pública" : "Privada"}
              </span>
              {new Date() > new Date(party.fecha_fin) && (
                <Chip variant="flat" color="primary" className="mt-2">
                  Terminada
                </Chip>
              )}
            </div>

            <div className="flex gap-2 w-full flex-wrap">
              {party.fantasias?.map((fantasy) => (
                <div key={fantasy.id}>
                  <Link to={`/fantasy/${fantasy.id}`}>
                    <Button
                      variant="bordered"
                      size="sm"
                      className="p-1.5  rounded-md border-1 border-color1/60 "
                    >
                      {fantasy.name}
                    </Button>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-3 text-start my-2">
            <div className="mt-1">
              <User
                name={
                  <span className="text-sm text-gray-600 mr-5">
                    Organizada por
                  </span>
                }
                description={
                  <span className="text-sm text-primary font-semibold">
                    {party?.user?.name}
                  </span>
                }
                avatarProps={{
                  src: party?.user?.profile_path
                    ? party?.user?.profile_path.startsWith("http")
                      ? party?.user?.profile_path
                      : import.meta.env.VITE_BASE_URL +
                        party?.user?.profile_path
                    : undefined,
                }}
              />
            </div>
            <p className="font-bold text-2xl flex">
              <Link
                className="hover:text-primary flex"
                to={`/parties/${party.id}`}
              >
                {party.nombre}
              </Link>
            </p>
            <p className="text-gray-500">{party.descripcion}</p>
          </div>
          <div className="flex gap-3 flex-col items-start">
            <span className="text-gray-500 font-medium flex gap-2 itesms-center">
              <LocationIcon height={20} width={20} stroke={"#A24BFD"} />{" "}
              {party.ciudad.name}
            </span>
            <span className="text-gray-500 text-start font-medium flex gap-2 ">
              <CalendarIcon height={20} width={20} />{" "}
              {party.fecha_fin && party.fecha_inicio
                ? formatter.formatRange(
                    new Date(party.fecha_inicio),
                    new Date(party.fecha_fin)
                  )
                : "--"}
            </span>
            <span className="inline-flex gap-2 text-gray-500 text-start font-medium">
              <ClockIcon
                height={20}
                width={20}
                className="text-primary"
              ></ClockIcon>
              {party.hora_inicio ?? "Sin definir"}
            </span>
            <span className="text-gray-500 text-sm">
              Publicado{" "}
              <Moment fromNow locale="Es">
                {party.created_at}
              </Moment>
            </span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
