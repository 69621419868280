import { useEffect, useState } from "react";
import PostImage from "../post/PostImage";
import { Post } from "../../interfaces/Post";
import { User } from "../../interfaces/Login";
import { getPublicationsByUser } from "../../api/publication";
import { toast } from "react-toastify";
import { Card, Chip, Pagination, Skeleton } from "@nextui-org/react";
import { Pagination as PaginationData } from "../../interfaces/pagination";

export default function Galery({ user }: { user: User }) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState("image");
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  useEffect(() => {
    getData(1);
  }, [user, media]);

  const getData = async (page?: number) => {
    setIsLoading(true);
    // get media publications
    const data = await getPublicationsByUser(
      { user_id: user.id, media_type: media, page },
      true
    );
    if (data?.error == "true" || !data) {
      toast.error(data.info.message + " ");
    }
    if (data?.info?.data[0]?.data) {
      setPosts(data.info.data[0].data);
      setPagination({
        current_page: data?.info?.data[0].current_page,
        last_page: data?.info?.data[0].last_page,
        total: data?.info?.data[0].total,
        per_page: data?.info?.data[0].per_page,
      });
    }
    setIsLoading(false);
  };
  const onPageChange = async (page: number) => {
    getData(page);
  };
  return (
    <>
      <div className="mt-10 flex justify-between px-6">
        <h3 className="font-bold text-[18px] text-color3 mb-10">Galería</h3>
        <div className="flex gap-3">
          <Chip
            onClick={() => setMedia("image")}
            color={media == "image" ? "primary" : "default"}
            variant="dot"
            className="cursor-pointer"
          >
            Fotos
          </Chip>
          <Chip
            onClick={() => setMedia("video")}
            color={media == "video" ? "primary" : "default"}
            variant="dot"
            className="cursor-pointer"
          >
            Videos
          </Chip>
          <Chip color="primary" variant="bordered" className="font-medium">
            Total:
            <span className="text-primary font-medium">
              {" "}
              {isLoading ? "..." : pagination.total}
            </span>
          </Chip>
        </div>
      </div>
      <div className=" flex justify-start px-6">
        {!isLoading && posts.length == 0 && (
          <p>
            No hay {media == "video" ? "videos" : "imágenes "} que mostrar 😕
          </p>
        )}
      </div>

      <div className="w-full gap-2 grid grid-cols-12 grid-rows-2 px-8">
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map((post) => (
              <Card
                key={post}
                className="col-span-12 sm:col-span-6 md:col-span-4  h-[300px]  p-2 rounded-xl shadow-none border-1 border-gray-200"
              >
                <Skeleton className="rounded-lg">
                  <div className="h-56 rounded-lg bg-default-300"></div>
                </Skeleton>
              </Card>
            ))
          : posts.map((post) => <PostImage key={post.id} post={post} />)}
      </div>
      {pagination && pagination?.current_page > 0 && (
        <div className="px-4 mt-3 mb-2 w-full">
          <div className="flex justify-center">
            <Pagination
              showControls
              total={pagination.last_page}
              initialPage={1}
              page={pagination.current_page}
              onChange={onPageChange}
            />
          </div>
        </div>
      )}
    </>
  );
}
