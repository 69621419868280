import { Avatar, Button, CircularProgress, Textarea } from "@nextui-org/react";
import { FormEvent, useMemo, useState, useEffect } from "react";
import { useAuthStore } from "../../stores/authStore";
import { IconamoonSendLight } from "../../assets/icons/SendIcon";
import { createPublication } from "../../api/publication";
import { toast } from "react-toastify";
import { useHomeStore } from "../../stores/homeData";
import { ImageCircleIcon } from "../../assets/icons/ImageIcon";
import PostUploadFile from "./PostUploadFile";
import { useParams } from "react-router-dom";
import CreateTripModal from "../trip/CreateTripModal";
import ProfileGlobalData from "../profile/ProfileGlobal";
import { Global } from "../../interfaces/SingleUser";
import { VideoIcon } from "../../assets/icons/VideoIcon";

export function NewPostCard({
	isFantasy = 0,
	onlineUsers = "...",
	globalData,
}: {
	isFantasy?: 0 | 1;
	onlineUsers?: string;
	globalData?: Global;
}) {
	const [displayedUsers, setDisplayedUsers] = useState(onlineUsers);
	const { fantasyId } = useParams();
	const [content, setContent] = useState("");
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openTripModal, setOpenTripModal] = useState(false);

	const user = useAuthStore((state) => state.user);
	const setNewPost = useHomeStore((state) => state.setNewPost);
	const setNewFantasyPost = useHomeStore((state) => state.setNewFantasyPost);

	const [type, setType] = useState<"image" | "video">("image");
	useEffect(() => {
		// Si el valor inicial es '...' o bajo, genera un número aleatorio
		if (onlineUsers === "..." || Number(onlineUsers) < 1) {
			const randomUsers = Math.floor(Math.random() * (121 - 8 + 1)) + 8; // Genera entre 8 y 121 usuarios
			setDisplayedUsers(randomUsers.toString());
		} else {
			const realUsers = Number(onlineUsers);
			const randomUsers = Math.floor(Math.random() * (121 - 8 + 1)) + 8; // Genera entre 8 y 121 usuarios
			setDisplayedUsers((realUsers + randomUsers).toString()); // Suma usuarios reales y aleatorios
		}
	}, [onlineUsers]);

	const handleChange = (e: FormEvent<HTMLInputElement>) => {
		const data = e.target as HTMLInputElement;
		setContent(data.value);
	};
	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		// console.log("asdasd");
		setLoading(true);

		//Data for request
		let data = {
			content,
			type: "text",
			isFantasia: isFantasy,
			fantasia_id: "",
		};

		if (isFantasy && fantasyId) {
			data = {
				...data,
				fantasia_id: fantasyId,
			};
		}

		const responseData = await createPublication(data);
		// console.log(responseData);

		if (responseData.error == "true") {
			toast.error(responseData.info.message + " ");
		} else {
			toast.success(responseData.info.message);

			if (isFantasy) setNewFantasyPost(responseData.info.data[0]);
			else setNewPost(responseData.info.data[0]);
			setContent("");
		}

		setLoading(false);
	}
	const endComponent = useMemo(() => {
		if (content.length > 3 && !loading)
			return (
				<Button
					isIconOnly
					aria-label="publish"
					size="sm"
					className="focus:outline-none absolute top-2 right-2 bg-zinc-300/20"
					type="submit"
				>
					<IconamoonSendLight height={20} width={20} />
				</Button>
			);
		if (loading) return <CircularProgress className="absolute top-2 right-2" size="sm" aria-label="Loading.." />;
		return <></>;
	}, [content, loading]);

	const onImageClick = () => {
		setType(() => "image");
		setOpenModal(true);
	};
	const onVideoClick = () => {
		setType(() => "video");
		setOpenModal(true);
	};

	return (
		<section>
			<article className="p-3 mb-3 w-full lg:hidden md:hidden bg-white rounded-2xl overflow-hidden">
				{globalData && (
					<div className="flex-1 flex items-center ">
						<ProfileGlobalData globalData={globalData} isMinimunVersion />{" "}
					</div>
				)}
			</article>
			<article className="px-3 pt-8 pb-3 w-full  bg-white rounded-2xl  overflow-hidden overflow-y-auto">
				<div className=" -mt-4 lg:-mt-6 ml-14  mb-1 text-sm text-color1 flex items-center font-semibold gap-3">
					<div className=" w-2 h-2 rounded-full  bg-green-500 animate-scale-pulse "></div>
					Actualmente hay {displayedUsers} usuarios en línea
				</div>
				<div className="flex items-start gap-3">
					<div>
						<Avatar
							showFallback
							classNames={{
								base: "bg-zinc-100",
								icon: "text-black/50",
							}}
							className="w-9 h-9 md:w-[38px] md:h-[38px]"
							src={
								user?.profile_path
									? user?.profile_path.startsWith("http")
										? user?.profile_path
										: import.meta.env.VITE_BASE_URL + user?.profile_path
									: undefined
							}
						/>
					</div>
					<div className="flex-1 relative">
						<form className="w-full" onSubmit={handleSubmit}>
							<Textarea
								placeholder="Cuéntanos algo..."
								value={content}
								onChange={handleChange}
								disableAnimation
								required
								endContent={endComponent}
								className="w-full"
								classNames={{
									inputWrapper:
										"borer-1 border-small focus:ring-1 focus:ring-transparent flex items-start min-h-[90px] rounded-2xl",
									base: "w-full",
									input: "resize-y col-span-1 border-0 focus:ring-1 focus:ring-transparent min-h-[50px]",
								}}
							/>
						</form>
						<div className=" pl-2 flex items-end justify-between w-full absolute bottom-1 gap-2">
							<div className="flex gap-2">
								<button
									className="flex bg-white size-[22px] justify-center rounded-full items-center "
									onClick={onImageClick}
								>
									<ImageCircleIcon className="size-5 text-color1" />
								</button>
								<button
									className="flex bg-white size-[22px] justify-center rounded-full items-center "
									onClick={onVideoClick}
								>
									<VideoIcon className=" size-[18px]  text-color1" />
								</button>
							</div>
							<div className=" mb-0.5 mr-2">
								{user?.role_id == "2" && (
									<div className="flex items-center gap-4">
										<Button
											onClick={() => setOpenTripModal(true)}
											size="sm"
											radius="full"
											className="max-h-7 text-color2"
											variant="faded"
											startContent={
												<svg
													width="13"
													height="13"
													viewBox="0 0 16 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M8 1V15M1 8H15"
														stroke="#A24BFD"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											}
										>
											{" "}
											Crear Viaje
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				<PostUploadFile
					isOpen={openModal}
					type={type}
					closeModal={() => setOpenModal(false)}
					isFantasy={isFantasy}
					fantasyId={fantasyId}
				/>
			</article>
			<CreateTripModal
				isOpen={openTripModal}
				closeModal={() => setOpenTripModal(false)}
				onSuccessCreateTrip={() => {}}
			/>
		</section>
	);
}
