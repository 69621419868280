import React from "react";

const ComplexionIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
		<g fill="none">
			<path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
			<path
				fill="currentColor"
				d="m17.068 2.001l.116.013a1 1 0 0 1 .822 1.15c-.43 2.58.088 5.416 1.428 8.43c1.174 2.643.82 5.424-.455 8.064a13.59 13.59 0 0 1-.632 1.165l-.232.364a7.37 7.37 0 0 1-.306.427a1 1 0 0 1-1.65-1.124l.072-.104c.361-.503.678-1.04.947-1.598c.79-1.636 1.143-3.297.879-4.883c-1.91.469-3.041 1.307-3.753 2.35c-.813 1.19-1.175 2.786-1.305 4.79a1.004 1.004 0 0 1-1 .955c-.53 0-.975-.425-.998-.956c-.13-2.003-.492-3.598-1.305-4.79c-.708-1.036-1.829-1.87-3.717-2.34c-.261 1.583.092 3.242.88 4.874c.215.445.458.882.734 1.294l.213.304a1 1 0 0 1-1.578 1.228a7.371 7.371 0 0 1-.306-.427l-.232-.364c-.2-.328-.417-.72-.632-1.165c-1.274-2.64-1.63-5.421-.455-8.064c1.34-3.014 1.858-5.85 1.428-8.43a1 1 0 1 1 1.972-.328c.482 2.89-.04 5.971-1.4 9.174c2.197.575 3.73 1.632 4.745 3.117c.252.37.468.76.652 1.167c.184-.407.4-.797.652-1.167c1.019-1.493 2.564-2.553 4.778-3.126c-1.356-3.2-1.878-6.278-1.396-9.165A1 1 0 0 1 17.068 2ZM12 8a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
			/>
		</g>
	</svg>
);

export default ComplexionIcon;
